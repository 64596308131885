import React from 'react';
import toast from '../Toast/Toast';

export const getHeight = () => {
  let result = [];
  for (let i = 1; i <= 11; i++) {
    let value = `5'${i}`;
    result.push(
      <option key={value} value={value}>
        5'{i}
      </option>
    );
  }
  return result;
};

export const getShoeSize = () => {
  let result = [];
  for (let i = 5; i <= 16; i = i + 0.5) {
    result.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return result;
};

export const pChange = () => {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  let p = document.querySelector('input[name="password"]').value;
  let p2 = document.querySelector('input[name="sPassword"]').value;
  let valid = re.test(p);
  let match = p === p2;
  if (!re.test(p)) toast('new password not valid');
  if (!match) toast("passwords don't match");
  return valid && match;
};

export const languages = [
  'AFRIKAANS',
  'ARABIC',
  'CHINESE - CANTONESE',
  'CHINESE - MANDARIN',
  'CROATIAN',
  'DUTCH',
  'FRENCH',
  'GERMAN',
  'GREEK',
  'HINDI',
  'ITALIAN',
  'KOREAN',
  'JAPANESE',
  'PERSIAN',
  'PORTUGUESE',
  'PUNJABI',
  'RUSSIAN',
  'SPANISH',
  'TAGALOG',
  'TAMIL',
  'THAI',
  'TURKISH',
  'UKRAINIAN',
  'URDU',
  'VIETNAMESE',
];
