import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import { Modal } from 'react-bootstrap';
import Shift from 'Components/Shift/Shift';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { SpinnerOverlay } from 'Components/Utils/Spinner';
import ListGridPlugin from '@fullcalendar/list';
import { getYearMonthDay } from '../../util/dates';
import './calendar.scss';

const makeEvents = (shifts, view, clients) => {
  const today = new Date().setHours(0, 0, 0, 0);
  let currentEvent = {};
  const events = shifts
    ?.sort((a, b) => {
      const title = `${clients[a.clientId]?.name} - ${a.role}`.localeCompare(
        `${clients[b.clientId]?.name} - ${b.role}`
      );
      if (view !== 'dayGridMonth') return title;
      const aDay = new Date(a?.start).getDate();
      const bDay = new Date(b?.start).getDate();
      if (aDay < bDay) return -1;
      if (aDay > bDay) return 1;
      return title;
    })
    .reduce((allEvents, shift, index) => {
      const venue = clients[shift.clientId]?.name;
      const event = {
        title: `${shift.role ?? ''} - ${venue ?? ''}`,
        start: new Date(shift.start),
        end: new Date(shift.end),
        shift: shift,
        allDay: view === 'dayGridMonth',
        full: shift.status === 'confirmed',
        isPrivate: !!shift.privateShift,
        backgroundColor: 'rgb(253, 182, 57)',
        classNames: ['cursor'],
      };
      if (today > new Date(shift.start)) event.backgroundColor = 'rgb(98, 121, 149)';
      else if (!!shift.privateShift) event.backgroundColor = 'rgb(255, 105, 180)';
      else if (shift.status === 'confirmed') event.backgroundColor = 'rgb(242, 98, 39)';
      if (currentEvent?.event && view === 'dayGridMonth') {
        const currentEventDay = new Date(currentEvent.event?.start).getDate();
        const eventDate = new Date(event?.start).getDate();
        if (
          eventDate === currentEventDay &&
          clients[currentEvent.event?.shift?.clientId]?.name !== venue
        ) {
          allEvents[index - 1]?.classNames?.push('event-separator');
        }
      }
      currentEvent.event = event;
      allEvents.push(event);
      return allEvents;
    }, []);
  return events;
};

class CalendarComp extends Component {
  state = {
    date: new Date(),
    event: {},
    eventAdmin: {},
    lat: 43.633222,
    lng: -79.418554,
    zoom: 14,
    shift: null,
    range: {
      end: new Date(),
      start: new Date(),
    },
    shifts: [],
    calendarView: 'listMonth',
  };

  componentWillUnmount() {
    this.props?.clearShifts();
  }

  calendarRef = React.createRef();

  onEventClick = ({ event }) => {
    let shift = event.extendedProps.shift;
    window.open(`/events/${shift.slotId}`);
  };

  onSelectSlot = (slot) => {
    let check = new Date(slot.date);
    check.setDate(check.getDate() + 1);
    this.setState({ shift: { start: slot.date.toISOString() } });
  };

  closeModal = () => this.setState({ shift: null });

  onRangeChange = async ({ view }) => {
    if (view.type === 'listMonth') setTimeout(this.scrollToDate, 250);
    if (view.type !== this.state.calendarView) {
      return this.onCalendarViewSwitch();
    }
    const start = new Date(view.currentStart).toISOString();
    const end = new Date(view.currentEnd).toISOString();
    this.setState({ range: { start, end }, loading: true });
    const shifts = await this.props.fetchShifts({ start, end });
    this.setState({ loading: false, shifts });
    if (view.type === 'listMonth') setTimeout(this.scrollToDate, 250);
    setTimeout(
      () => this.calendarRef.current.getApi().setOption('contentHeight', this.contentHeight()),
      100
    );
  };

  onCalendarViewSwitch = async () => {
    const api = this.calendarRef.current.getApi();
    this.setState({ calendarView: api.view.type });
  };

  getNextView = (currentView) => (currentView === 'listMonth' ? 'dayGridMonth' : 'listMonth');

  scrollToDate = () => {
    if (this.state.calendarView !== 'listMonth') return;
    const today = new Date();

    const currentMonth = today.getMonth();

    const checkDate = new Date();

    for (let i = 0; i < 30; i++) {
      const header = document.querySelector(`tr[data-date="${getYearMonthDay(checkDate)}"]`);
      if (header) {
        header.scrollIntoView();
        return;
      }
      checkDate.setDate(checkDate.getDate() + 1);
      if (currentMonth !== checkDate.getMonth()) return;
    }
  };

  contentHeight = () => {
    const main = document.querySelector('.sidebar_wrapper')?.clientHeight;
    return main - 310 + 'px';
  };

  render() {
    const { loading, calendarView } = this.state;
    const { shifts, clients } = this.props;
    return (
      <React.Fragment>
        {loading && <SpinnerOverlay />}
        <div>Legend:</div>
        <div className='legend'>
          <br />
          <div className='colors'>
            <span className='blue'></span>
            <span>Past Events</span>
          </div>
          <div className='colors'>
            <span className='yellow'></span>
            <span>Events that are not yet filled</span>
          </div>
          <div className='colors'>
            <span className='red'></span>
            <span>Events that are full</span>
          </div>
          <div className='colors'>
            <span className='pink_legend'></span>
            <span>Events that are private</span>
          </div>
        </div>
        <br />
        <FullCalendar
          plugins={[ListGridPlugin, dayGridPlugin, interactionPlugin]}
          events={makeEvents(shifts, calendarView, clients)}
          dateClick={this.onSelectSlot}
          selectable={true}
          eventClick={this.onEventClick}
          datesSet={this.onRangeChange}
          aspectRatio={2}
          showNonCurrentDates={false}
          fixedWeekCount={false}
          dayCellClassNames={'cursor'}
          initialView={calendarView}
          ref={this.calendarRef}
          headerToolbar={{
            left: 'title',
            center: '',
            right: `${this.getNextView(calendarView)} today prev,next`,
          }}
          contentHeight={this.contentHeight()}
        />

        <Modal show={!!this.state.shift} onHide={this.closeModal} dialogClassName='shift_wrapper'>
          <Modal.Header closeButton>
            Create New Shifts
            <br />
          </Modal.Header>
          <Modal.Body>
            <Shift shift={this.state.shift} shifts={[]} action={this.closeModal} mode='create' />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { shifts, clients } = state.app;

  const uniqueShifts = shifts.reduce((allShifts, shift) => {
    if (!allShifts[shift.slotId]) {
      allShifts[shift.slotId] = shift;
    }
    return allShifts;
  }, {});

  return {
    shifts: Object.values(uniqueShifts),
    clients: clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShifts: (range) => dispatch(appAction.fetchShifts(range)),
    clearShifts: () => dispatch(appAction.clearShifts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComp);
