import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import toast from '../Toast/Toast';
import { LogoWB } from '../Logo';

const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

class Register extends Component {
  state = {};

  onPasswordChnange = async () => {
    let psw = document.querySelector('input[name="password"]').value;
    let psw2 = document.querySelector('input[name="sPassword"]').value;
    let valid = re.test(psw);
    let match = psw === psw2;
    if (!valid) toast('new password not valid');
    if (!match) toast("passwords don't match");
    if (!valid || !match) return;
    let url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    await this.props.resetPassword(psw, token);
    this.props.history.push('/');
    window.location.reload(false);
  };

  render() {
    return (
      <div className='login__form'>
        <div className='login'>
          <LogoWB />

          <label htmlFor='reset_password' className='bold'>
            NEW PASSWORD:
          </label>
          <input
            id='reset_password'
            type='password'
            placeholder='6 characters with uppercase, lowercase and number'
            name='password'
          />

          <label htmlFor='reset_confirm_password' className='bold'>
            CONFIRM NEW PASSWORD:
          </label>
          <input
            id='reset_confirm_password'
            type='password'
            placeholder='6 characters with uppercase, lowercase and number'
            name='sPassword'
          />
          <div style={{ textAlign: 'center' }}>
            <span
              className='login__action'
              onClick={this.onPasswordChnange}
              style={{ minWidth: '130px' }}
            >
              reset password
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (psw, token) => dispatch(appAction.resetPassword(psw, token)),
  };
};

export default connect(null, mapDispatchToProps)(Register);
