import * as appAction from 'Actions/app.actions';
import { AgGridReact } from 'ag-grid-react';
import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { getTime, uxDate } from 'Utils/dates';
import { round, calcHrs } from '../../util/numbers';
import toast from '../Toast/Toast';
import { DoublingEditor } from '../agGrid/DoublingEditor';
import { EventInfo } from '../Event/EventCards';
import { Uploader } from '../Uploader';
import { v1 as uuid } from 'uuid';
import downloadIcon from '../../images/download.jpg';
import trashIcon from '../../images/trash.svg';
import { saveAs } from 'file-saver';
import { isManager } from '../../util/auth';

const SignOffsTable = (props) => {
  const { event, saveAttachment, updateEvent, user } = props;

  const [gridApi, setGridApi] = useState(null);
  const [usersInfo, setUsersInfo] = useState([]);

  const fetchSignOffs = useCallback(() => {
    if (props.match.params.event) {
      props.fetchSignOffShifts(props.match.params.event);
    } // eslint-disable-next-line
  }, []);

  useEffect(fetchSignOffs, [fetchSignOffs]);

  const onCellValueChanged = (params) => {
    const field = params.colDef.field;
    if (!field || params.newValue === params.oldValue || (!params.newValue && !params.oldValue))
      return;
    if (field === 'start' || field === 'end') {
      if (getTime(params.newValue) === params.oldValue) return;
    }
    const shift = { id: params.data.id, [field]: params.newValue ?? params.oldValue };
    props.updateShift(shift);
  };

  const defaultSignOffColDef = {
    editable: true,
    sortable: true,
    resizable: true,
    onCellValueChanged,
    width: 75,
    sortingOrder: ['asc', 'desc'],
    singleClickEdit: true,
  };

  const SignOffFormatter = ({ valueFormatted, data }) => {
    return (
      <div onClick={() => (data.signoff ? signOffFalse(data) : signOff(data))}>
        {valueFormatted}
      </div>
    );
  };

  const frameworkComponents = {
    SignOffFormatter,
  };

  const onCellValueChangedDates = (oldParams) => {
    let field;
    let params = { ...oldParams };
    let value = params.newValue;

    switch (params.colDef.headerName) {
      case 'In':
        field = 'start';
        break;
      case 'Out':
        field = 'end';
        break;
      default:
        break;
    }
    params.newValue =
      new Date(params.data.date.split('T')[0] + ':' + params.newValue) || params.newValue;
    params.colDef.field = field;
    onCellValueChanged(params);
    return value;
  };

  const columnLogs = [
    {
      headerName: 'Date',
      field: 'date',
      editable: false,
      valueGetter: ({ data }) => uxDate(data.start),
    },
    {
      field: 'venue',
      headerName: 'Venue',
      editable: false,
      width: 175,
    },
    {
      field: 'staffName',
      headerName: 'Staff name',
      width: 175,
    },
    {
      field: 'role',
      headerName: 'Role',
    },
    {
      field: 'start',
      headerName: 'In',
      valueGetter: ({ data }) => getTime(data.start),
      valueSetter: onCellValueChangedDates,
    },
    {
      field: 'end',
      headerName: 'Out',
      valueGetter: ({ data }) => getTime(data.end),
      valueSetter: onCellValueChangedDates,
    },
    {
      field: 'break',
      headerName: 'Break',
      valueGetter: ({ data }) => round(data?.break),
      cellEditor: DoublingEditor,
    },
    {
      field: 'hrs',
      headerName: 'Hrs',
      valueGetter: ({ data }) => calcHrs(data.start, data.end, data.break) || 0,
      editable: false,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      valueGetter: ({ data }) => round(data?.rate),
      cellEditor: DoublingEditor,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: ({ data }) => {
        const { rate = 0, tips, deduction } = data;
        const hrs = calcHrs(data.start, data.end, data.break) ?? 0;
        const amount = rate * hrs - (deduction ?? 0) + (tips ?? 0);
        return round(amount) ?? 0;
      },
      editable: false,
    },
    {
      field: 'tips',
      headerName: 'Tips',
      valueGetter: ({ data }) => round(data?.tips),
      cellEditor: DoublingEditor,
    },
    {
      field: 'deduction',
      headerName: 'Deduction',
      valueGetter: ({ data }) => round(data?.deduction),
      cellEditor: DoublingEditor,
    },
    {
      field: 'deNote',
      headerName: 'Deduction Note',
    },
    {
      field: 'signoffNote',
      headerName: 'Notes',
    },
    {
      headerName: 'Action',
      field: 'signoff',
      cellRenderer: 'SignOffFormatter',
      valueFormatter: ({ value }) => (value ? 'Undo' : 'Sign Off'),
      editable: false,
    },
  ];

  const onGridReady = ({ columnApi, api }) => {
    if (columnApi.getAllColumns().length > 5) {
      setTimeout(() => columnApi.autoSizeAllColumns(), 1000);
    } else {
      api.sizeColumnsToFit();
    }
    setGridApi(api);
  };

  const signOff = async (shift) => {
    await props.updateShift({ id: shift?.id, signoff: true });
    fetchSignOffs();
    toast('signed off successful');
  };

  const signOffFalse = async (shift) => {
    await props.updateShift({ id: shift?.id, signoff: false });
    fetchSignOffs();
    toast('signed off removed');
  };

  const usersIds = useMemo(() => {
    return props.shifts.reduce((ids, shift) => {
      if (shift?.userId) ids.add(shift.userId);
      return ids;
    }, new Set());
  }, [props.shifts]);

  useEffect(
    function onFetchUserInfo() {
      if (usersIds?.length === Object.keys(usersInfo).length) return;

      const fetchUsersTimeout = setTimeout(async () => {
        if (!usersIds?.size) return;
        const users = await props.fetchUsers([...usersIds]);
        const usersInfoResult = users?.reduce((allUsers, user) => {
          allUsers[user?.id] = user;
          return allUsers;
        }, {});

        setUsersInfo(usersInfoResult);
      }, 100);

      return () => {
        fetchUsersTimeout && clearTimeout(fetchUsersTimeout);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersIds, props.fetchUsers]
  );

  const addRowModal = () => {
    const {
      slotId,
      clientId,
      start,
      end,
      location,
      lat,
      lng,
      rate,
      role,
      note,
      images,
      privateShift,
      status,
    } = props.shifts[0];

    const shift = {
      slotId,
      clientId,
      start,
      end,
      location,
      lat,
      lng,
      rate,
      role,
      note,
      images,
      privateShift,
      status,
    };
    props.addShift(shift);
  };

  const onExport = useCallback(() => {
    gridApi.exportDataAsCsv({
      columnKeys: [
        'date',
        'venue',
        'staffName',
        'role',
        'start',
        'end',
        'break',
        'hrs',
        'rate',
        'amount',
        'tips',
        'deduction',
        'deNote',
        'signoffNote',
      ],
      processCellCallback: ({ column, value }) => {
        if (column?.colId === 'amount' || column?.colId === 'tips') {
          return `${value}$`;
        }
        return value;
      },
    });
  }, [gridApi]);

  const shifts = useMemo(() => {
    return props.shifts?.map((shift) => {
      const user = usersInfo[shift?.userId];
      const staffName = user ? `${user.fName ?? ''} ${user.lName ?? ''}` : '';
      return { ...shift, staffName };
    });
  }, [props.shifts, usersInfo]);

  const onTimesheetUploads = useCallback(
    async ({ currentTarget }) => {
      try {
        const name = currentTarget.files[0].name;
        const location = await saveAttachment(
          {
            file: currentTarget.files[0],
            name,
          },
          null,
          `events/${event.meetingId}`
        );

        const _timesheets = event?.timesheets ?? [];
        _timesheets.push({ url: location, name, id: uuid() });

        await updateEvent({ id: event?.id, timesheets: _timesheets });
      } catch (e) {
        console.error(e);
      }
    },
    [event, saveAttachment, updateEvent]
  );

  const isUserManager = useMemo(() => {
    return isManager(user?.role);
  }, [user]);

  const timesheetList = useMemo(() => {
    return event?.timesheets?.map((timesheet) => {
      return (
        <div className='event_timesheet_wrapper' key={timesheet.id}>
          <span>{timesheet?.name}</span>
          <img
            src={downloadIcon}
            alt='download'
            width={30}
            onClick={() => saveAs(timesheet?.url, timesheet.name)}
          />
          {isUserManager && (
            <img
              src={trashIcon}
              alt='delete'
              width={20}
              onClick={async () => {
                const confirmation = window.confirm('Are you sure you want to delete?');
                if (!confirmation) return;
                try {
                  await updateEvent({
                    id: event.id,
                    timesheets: event.timesheets?.filter(
                      (_timesheet) => _timesheet?.id !== timesheet?.id
                    ),
                  });
                } catch (e) {
                  console.error(e);
                }
              }}
            />
          )}
        </div>
      );
    });
  }, [event, updateEvent, isUserManager]);

  return (
    <>
      <div style={{ marginBottom: '2em', display: 'flex' }}>
        <EventInfo shifts={shifts} clients={props.clients} event={props.event} />
        <div>
          <div className='separator'>
            <span onClick={addRowModal} className='actionButton'>
              Add shift
            </span>
            <span onClick={onExport} className='actionButton'>
              Export CSV
            </span>
            <span>
              <Uploader onFileUpload={onTimesheetUploads} label={'Upload TimeSheet'} />
            </span>
          </div>
          <div style={{ marginLeft: 8 }}>{timesheetList}</div>
        </div>
      </div>

      <div className='ag-theme-balham'>
        <AgGridReact
          columnDefs={columnLogs}
          defaultColDef={defaultSignOffColDef}
          rowData={shifts}
          onGridReady={onGridReady}
          frameworkComponents={frameworkComponents}
          stopEditingWhenGridLosesFocus={true}
          domLayout='autoHeight'
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const clients = state.app.clients?.reduce((allClients, client) => {
    allClients[client?.id] = client;
    return allClients;
  }, {});

  const _shifts = state.app.shifts?.map((shift) => ({
    ...shift,
    venue: clients[shift.clientId]?.name,
  }));

  return {
    user: state.app.user,
    shifts: _shifts,
    event: state.app.event ?? {},
    clients: state.app.clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addShift: (shift) => dispatch(appAction.addShift(shift)),
    updateShift: (shift) => dispatch(appAction.updateShift(shift)),
    fetchSignOffShifts: (slotId) => dispatch(appAction.fetchSignOffShifts(slotId)),
    fetchUsers: (ids) => dispatch(appAction.fetchUsers(ids)),
    updateEvent: (event) => dispatch(appAction.updateEvent(event)),
    saveAttachment: (file, bucket, folder, asl) =>
      dispatch(appAction.saveAttachment(file, bucket, folder, asl)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(SignOffsTable));
