import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import toast from '../Toast/Toast';
import { LogoWB } from '../Logo';
import ReCAPTCHA from 'react-google-recaptcha';
import { formatPhone } from 'Utils/numbers';
import validator from 'validator';

class Register extends Component {
  componentDidMount = () => { };

  state = {
    isLoading: false,
    isCaptcha: false,
    registerUser: {},
    currentUser: null,
  };

  validateForm = () => {
    let check = true;
    const { fName, lName, email, hearUs } = this.state.registerUser;

    if (!fName || !lName || !email || !hearUs) {
      check = false;
      toast('Fill all required fileds');
    }

    if (!!email && !validator.isEmail(email)) {
      check = false;
      toast('email is not valid');
    }

    if (!this.state.isCaptcha) {
      check = false;
      toast('check CAPTCHA');
    }
    return check;
  };

  register = async () => {
    if (!this.validateForm()) return;
    const currentUser = await this.props.register(this.state.registerUser);
    if (!currentUser) toast('email already exist');
    else {
      this.setState({ currentUser });
    }
  };

  onCaptchaChange = (value) => this.setState({ isCaptcha: !!value });

  onKeyPress = (event) => {
    if (event.key === 'Enter') this.register();
  };

  onFieldChange = ({ currentTarget }) => {
    let name = currentTarget.getAttribute('name');
    this.setState({
      registerUser: {
        ...this.state.registerUser,
        [name]: currentTarget.value.toUpperCase(),
      },
    });
  };

  makeInput = (label, name, style = {}) => {
    const { registerUser } = this.state;
    let value = registerUser[name] || '';

    if (name === 'phone' && value) {
      value = formatPhone(value);
    }

    return (
      <input
        id={'input_' + name}
        placeholder={label}
        value={value || ''}
        name={name}
        type={name === 'email' ? 'email' : 'text'}
        onChange={this.onFieldChange}
        className='inputFormatted required'
        required
        aria-label={'input_' + name}
        style={style}
      ></input>
    );
  };

  render() {
    const { currentUser } = this.state;
    return (
      <div className='login__form'>
        <div className='login'>
          <LogoWB />
          {currentUser?.id ? (
            <>
              <div style={{ textAlign: 'center', margin: '16px 0' }}>
                Team EPM will email you with next step
              </div>
            </>
          ) : (
            <>
              <div className='sub_heading bold'>Hello!</div>
              <div>You are one step closer to joining our team</div>
              <div>Please fill out the following fields to get you started:</div>

              <div className='flexDisplay'>
                <p className='required' />
                {this.makeInput('First Name', 'fName')}
                <p className='required' />
                {this.makeInput('Last Name', 'lName')}
              </div>

              <div className='flexDisplay'>
                <p className='required' />
                {this.makeInput('Email Address', 'email')}
                {this.makeInput('Phone no:', 'phone', { marginLeft: '10px' })}
              </div>
              <br />

              {this.makeInput('How did you hear about us?', 'hearUs', {
                marginLeft: '10px',
                width: 'calc(100% - 10px)',
              })}

              <ReCAPTCHA
                sitekey='6Le65LkUAAAAAFAM7JS0AEDJeiLS4KAve11_EkO4'
                onChange={this.onCaptchaChange}
                style={{ height: '110px' }}
              />
              <div style={{ textAlign: 'right', margin: '0' }}>
                <span className='login__action' onClick={this.register}>
                  SUBMIT
                </span>
              </div>
              <div>
                Our team will review your application. Once approved, you will{' '}
                <span>recieve an email with your username and password</span>.
              </div>
              <div>
                Log back in as a member to have full access of our app where you can sign up for
                shifts & keep track of your hours.
              </div>
              <div>If you have any questions, email: app@eventspm.ca.</div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (email, password) => dispatch(appAction.register(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
