import * as appAction from 'Actions/app.actions';
import Memo from 'Components/Memo/Memo';
import React, { Component } from 'react';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isAdmin, isManager } from 'Utils/auth';
import announcement from '../../images/announcement.jpg';
import toast from '../Toast/Toast';
class Dashboard extends Component {
  state = {
    showModal: false,
  };

  componentDidMount = () => {
    this.props.fetchMemos();
  };

  handleShow = () => this.setState({ showModal: true });

  closeModal = () => this.setState({ showModal: false });

  saveMemo = () => {
    this.props.addMemo({
      title: this.refs.memoTitle.value,
      memo: this.refs.memoBody.value,
      by: `${this.props.user.fName || ''} ${this.props.user.lName || ''}`,
    });
    this.closeModal();
  };

  deleteMemo = (id) => {
    let check = window.confirm('Are you sure you want to delete this Announcement?');
    if (!check) return;
    this.props.deleteMemo(id);
    toast('Deleted!');
  };

  render() {
    const { user } = this.props;
    const isManagerRole = isManager(user.role);

    return (
      <div>
        <div className='heading'>
          Welcome, <span className='importantInfo'>{user.pName || user.fName || 'Team EPM'}</span>
        </div>
        <Row>
          <Col>
            <img alt='image_announcement' src={announcement} style={{ height: '200px' }} />
            <div className='label'>Announcements:</div>
            <div className='info'>
              Frequently check this corner to get your latest news and updates so you’re always
              prepared and in the loop.
            </div>
            {this.props.memos.map((m) => (
              <Memo
                key={m.id}
                m={m}
                user={user}
                deleteMemo={isAdmin(user.role) ? this.deleteMemo : null}
              />
            ))}
            {isManagerRole ? (
              <React.Fragment>
                <span className='actionButton' onClick={this.handleShow}>
                  Add Memo
                </span>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                  <form>
                    <Modal.Header closeButton>
                      <Modal.Title>Create New</Modal.Title>
                      <br />
                    </Modal.Header>
                    <Modal.Body>
                      <FormControl
                        ref='memoTitle'
                        placeholder='Title'
                        style={{ marginBottom: '1rem' }}
                        aria-label='Title'
                      />
                      <FormControl
                        aria-label='Memo'
                        ref='memoBody'
                        placeholder='Type here...'
                        as='textarea'
                        rows='3'
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={this.saveMemo} bsstyle='success'>
                        Save
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    memos: state.app.memos.sort((a, b) => (new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1)),
    pics: state.app.dashpics,
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMemos: () => dispatch(appAction.fetchMemos()),
    addMemo: (memo) => dispatch(appAction.addMemo(memo)),
    deleteMemo: (id) => dispatch(appAction.deleteMemo(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
