const exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

const regex = new RegExp(exp_match);

export const convertLinksToAnchor = (string, txt = 'CLICK HERE') =>
  string?.replace(exp_match, `<a class='memoLink' href='$1' target='_blank'>${txt}</a>`);

export const toUpperCase = (string) => {
  const lines = string?.split('\n');
  let result = '';

  for (let i = 0; i <= lines.length; i++) {
    if (!lines[i]) continue;
    const line = lines[i]?.split(' ');
    const ar = line?.map((el) => {
      if (regex.test(el)) return el;
      return el?.toUpperCase();
    });

    result += ar?.join(' ') + '\n';
  }
  return result;
};
