import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { Modal } from 'react-bootstrap';
import { getAge } from 'Utils/dates';
import toast from '../Toast/Toast';

const makeEvent = (title, date, id, isBirthday = false) => {
  const year = new Date().getFullYear();
  date = new Date(date);
  const m = date.getMonth();
  const d = date.getDate();
  const event = {
    title,
    date: new Date(year, m, d),
    allDay: true,
    userId: id,
    isBirthday,
    backgroundColor: 'rgb(98, 121, 149)',
    className: 'cursor',
  };
  if (isBirthday) event.className += ' fc_birthday';
  return event;
};

const makeEvents = (users = []) => {
  const events = [];
  const year = new Date().getFullYear();

  users.forEach((u) => {
    let name = `${u.fName || ''} ${u.lName || ''}`;
    if (u.dob) {
      events.push(makeEvent(`${name.substring(0, 20)} - ${getAge(u.dob)}`, u.dob, u.id, true));
    }
    if (u.registerAt) {
      let d = new Date(u.registerAt).getFullYear();
      if (year - d > 1) events.push(makeEvent(`${year - d} - ${name}`, u.registerAt, u.id));
    }
  });
  return events;
};

class CalendarComp extends Component {
  state = {};

  componentDidMount = () => {
    this.props.fetchBirthdays();
  };

  onEventClick = ({ event }) => {
    this.setState({ event });
  };

  sendWish = async () => {
    let { extendedProps } = this.state.event;
    await this.props.sendWishes(extendedProps.userId, extendedProps.isBirthday);
    toast('Wish has been sent');
    this.setState({ event: null });
  };

  contentHeight = () => {
    const main = document.querySelector('.sidebar_wrapper')?.clientHeight;
    return main - 150 + 'px';
  };

  closeModal = () => this.setState({ event: null });

  render() {
    let { event } = this.state;

    return (
      <React.Fragment>
        <FullCalendar
          plugins={[dayGridPlugin]}
          events={makeEvents(this.props.birthdays)}
          eventClick={this.onEventClick}
          aspectRatio={2}
          contentHeight={this.contentHeight()}
        />
        <Modal show={event} onHide={this.closeModal}>
          {event ? (
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  {event.extendedProps.isBirthday
                    ? 'Send Happy Birthday wish'
                    : 'Send Anniversary wish'}
                </Modal.Title>
                <br />
              </Modal.Header>
              <Modal.Body>
                <span className='actionButton' onClick={this.sendWish}>
                  Send Wish
                </span>
              </Modal.Body>
            </div>
          ) : null}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    birthdays: state.app.birthdays,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBirthdays: () => dispatch(appAction.fetchBirthdays()),
    sendWishes: (userId, isBirthday) => dispatch(appAction.sendWishes(userId, isBirthday)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComp);
