import PhoneNumber from 'awesome-phonenumber';

export const formatPhone = (phone = '') => {
  const p = new PhoneNumber(phone, 'CA');

  if (p.isValid()) return p.getNumber('national');
  return phone;
};

export const round = (value = 0) => {
  return new Intl.NumberFormat('en-CA', {
    style: 'decimal',
    currency: 'CAD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const calcHrs = (start, end, bTime = 0) => {
  if (!end || !start) return null;
  let inTime = new Date(start);
  let outTime = new Date(end);
  let dif = (outTime - inTime) / (1000 * 60 * 60) - bTime;
  if (dif < 0) return 0;
  return round(dif);
};
