import { v1 as uuid } from 'uuid';

function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) return resolve(blob);
        resolve(null);
      },
      'image/jpeg',
      0.7
    );
  });
}

interface OnSaveImage {
  cssSelector: string;
  rotate?: number;
  name: string;
  onFileSave(currentTarget: {
    currentTarget: { files: Array<Blob>; getAttribute(): string };
  }): void;
  srcUrl: string;
  crop?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface MyBlob extends Blob {
  lastModifiedDate?: Date;
  name?: string;
}

export async function onSaveImage(props: OnSaveImage) {
  const { rotate, cssSelector, srcUrl, onFileSave, crop } = props;
  const imageViewing = document.querySelector(cssSelector) as HTMLImageElement;
  const image = new Image(imageViewing.width, imageViewing.height);

  image.crossOrigin = 'Anonymous';

  image.onload = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = imageViewing.width;
    canvas.height = imageViewing.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.save();
    if (rotate) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.translate(imageViewing.width / 2, imageViewing.height / 2);
      ctx.rotate((Math.PI / 180) * rotate);
      ctx.translate(-imageViewing.width / 2, -imageViewing.height / 2);
    }

    if (crop) {
      const scaleX = imageViewing.naturalWidth / imageViewing.width;
      const scaleY = imageViewing.naturalHeight / imageViewing.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    } else {
      ctx.drawImage(image, 0, 0, imageViewing.width, imageViewing.height);
    }

    const blob: MyBlob | null = await toBlob(canvas);

    if (!blob) return;

    // imageViewing.src = canvas.toDataURL();

    blob.name = '-' + uuid() + '.' + srcUrl.split('.').pop();
    blob.lastModifiedDate = new Date();

    const currentTarget = {
      files: [blob],
      getAttribute: () => props.name,
    };
    onFileSave({ currentTarget });
    ctx.restore();
  };
  image.src = srcUrl + '?' + new Date().getTime();
}
