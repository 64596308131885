import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import { Modal, Col, Row, Form } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ListGridPlugin from '@fullcalendar/list';
import { uxDate, getTime } from 'Utils/dates';
import { toast } from 'react-toastify';
import { convertLinksToAnchor } from 'Utils/strings';
import { SpinnerOverlay } from 'Components/Utils/Spinner';
import { isSub } from '../../util/auth';
import '../Calendar/calendar.scss';

const AnyReactComponent = () => <div className='googleMarker'></div>;

const date = new Date();
date.setHours(0, 0, 0, 0);

const makeStaffProfileEvents = (shifts = [], workflows = [], clients = {}) => {
  const events = [];

  shifts.forEach((shift) => {
    const venue = clients[shift?.clientId]?.name;
    events.push({
      title: `${venue}-${shift.role}`,
      start: new Date(shift.start),
      backgroundColor: 'green',
      slotId: shift.slotId,
      classNames: ['cursor'],
    });
  });

  workflows.forEach((workflow) => {
    const venue = clients[workflow?.clientId]?.name;
    events.push({
      title: `${venue}-${uxDate(workflow.date)}`,
      date: new Date(workflow.date),
      backgroundColor: 'rgb(253, 182, 57)',
      slotId: workflow.slotId,
      classNames: ['cursor'],
    });
  });

  return events;
};

const makeEvents = (shifts = [], workflows = [], user, clients) => {
  const { email, internalRoles } = user;

  const roles = (internalRoles ?? []).map((r) => r.trim());

  const events = shifts?.flatMap((shift) => {
    const venue = clients[shift?.clientId]?.name;
    return roles.includes(shift?.role?.trim()) || shift.isOpenToAll
      ? [
        {
          title: `${venue}-${shift.role}`,
          start: new Date(shift.start),
          shift,
          allDay: true,
          backgroundColor: 'rgb(253, 182, 57)',
          className: 'cursor',
        },
      ]
      : [];
  });
  workflows.forEach((workflow) => {
    const venue = clients[workflow?.clientId]?.name;
    const workflowDate = new Date(workflow.date);
    if (workflow.declinedBy && workflow.declinedBy !== email) return;
    let key = `${venue}-${uxDate(workflow.date)}`;
    const event = {
      title: key,
      date: workflowDate,
      workflow,
      allDay: true,
      backgroundColor: 'rgb(253, 182, 57)',
      className: 'cursor',
    };
    //past
    if (date > workflowDate) event.backgroundColor = 'rgb(98, 121, 149)';
    //declined
    else if (workflow?.declinedAt || workflow?.rejectedAt)
      event.backgroundColor = 'rgb(242, 98, 39)';
    //confirmed
    else if (workflow?.confirmedAt) event.backgroundColor = 'green';
    events.push(event);
  });
  return events;
};

class CalendarComp extends Component {
  state = {
    zoom: 14,
    event: {},
    isManagerView: !!this.props.isManagerView,
    email: this.props.email || this.props.user?.email,
    calendarView: this.props.isManagerView ? 'listMonth' : 'dayGridMonth',
    userId: this.props.userId,
  };

  componentWillUnmount() {
    this.props?.clearShifts();
  }

  onEventClick = async ({ event }) => {
    const newEvent = { ...event.extendedProps };
    if (this.state.isManagerView) {
      if (newEvent.slotId) window.open(`/events/${newEvent.slotId}`, '_blank');
      return;
    }
    if (!newEvent.shift) {
      newEvent.shift = await this.props.fetchShift(null, newEvent.workflow.slotId);
      if (!newEvent.shift) return;
    } else {
      newEvent.shift = await this.props.fetchShift(newEvent.shift?.id);
      if (!newEvent.shift) return;
    }
    this.setState({
      event: newEvent,
    });
  };

  onHideShiftsModal = () => {
    this.setState({ event: null });
  };

  onRangeChange = async ({ view }) => {
    let start = new Date(view.currentStart).toISOString();
    let end = new Date(view.currentEnd).toISOString();
    this.setState({ loading: true });

    const { isManagerView, userId } = this.state;

    if (isManagerView) {
      await this.props.fetchStaffProfileShifts({ range: { start, end }, userId });
    } else {
      await this.props.fetchStaffShifts({ start, end });
    }
    this.setState({ loading: false });
  };

  apply = (shift, event) => {
    if (event.detail !== 1) return;
    this.props.apply(shift);
    this.setState({ event: null });
    toast.info('Applied to shift, wait for confirmation');
  };

  decline = () => {
    if (!(this.declineComment.value && this.declineComment.value.length > 0)) {
      toast.error('Please fill the form!');
      return;
    }

    let workflow = {
      ...this.state.decline,
      comment: this.declineComment.value,
    };
    this.props.decline(workflow);
    this.setState({ event: null, decline: null });
  };

  getStaffAction = (event) => {
    if (event.shift && new Date(event.shift.date || '') < date) return <span />;
    if (event.workflow && new Date(event.workflow.date || '') < date) return <span />;

    if (!event.workflow)
      return (
        <span className='actionButton' onClick={(e) => this.apply(event.shift, e)}>
          Apply
        </span>
      );
    else if (event.workflow.rejectedAt || event.workflow.declinedAt) {
      const time = new Date(event.workflow.rejectedAt || event.workflow.declinedAt || new Date());
      const rejectNoun = event.workflow.declinedBy === this.props.user.email ? 'You' : 'Was';
      return (
        <span>
          {rejectNoun} canceled at {time.toLocaleDateString()} with message
          <div dangerouslySetInnerHTML={{ __html: event.workflow.comment }}></div>
        </span>
      );
    } else if (event.workflow.confirmedAt)
      return (
        <span className='actionButton' onClick={() => this.setState({ decline: event.workflow })}>
          Decline
        </span>
      );
    else if (event.workflow.approvedAt)
      return <span>Waiting on your confirmation. Check your email!</span>;
    return <span>Waiting for approval. You will recieve email with futher actions!</span>;
  };

  contentHeight = () => {
    const main = document.querySelector('.sidebar_wrapper')?.clientHeight;
    const delta = this.state.isManagerView ? 450 : 310;
    return main - delta + 'px';
  };

  render() {
    const { user, clients, shifts, workflows } = this.props;

    const { event = {}, decline, loading, calendarView, isManagerView } = this.state;
    let isConfirmed = event?.shift?.status?.match('booked|confirmed');

    let images = event?.shift?.images ?? [];
    if (typeof images === 'string') images = JSON.parse(images);

    const venue = clients[event?.shift?.clientId];
    const lng = venue?.lng;
    const lat = venue?.lat;

    const calendarData = isManagerView
      ? makeStaffProfileEvents(shifts, workflows, clients)
      : makeEvents(shifts, workflows, user, clients);

    return (
      <React.Fragment>
        {loading && <SpinnerOverlay />}

        <div>Legend:</div>
        <div className='legend'>
          <br />
          {isManagerView ? (
            <>
              <div className='colors'>
                <span className='yellow'></span>
                <span>Shifts applied</span>
              </div>
              <div className='colors'>
                <span className='green'></span>
                <span>Shifts worked on</span>
              </div>
            </>
          ) : (
            <>
              <div className='colors'>
                <span className='blue'></span>
                <span>Past Shifts</span>
              </div>
              <div className='colors'>
                <span className='yellow'></span>
                <span>Shifts that are available</span>
              </div>
              <div className='colors'>
                <span className='red'></span>
                <span>Shifts that are not available</span>
              </div>
              <div className='colors'>
                <span className='green'></span>
                <span>Shifts that are confirmed</span>
              </div>
            </>
          )}
        </div>
        <br />

        <FullCalendar
          plugins={[dayGridPlugin, ListGridPlugin]}
          events={calendarData}
          eventClick={this.onEventClick}
          datesSet={this.onRangeChange}
          aspectRatio={2}
          showNonCurrentDates={false}
          fixedWeekCount={false}
          initialView={calendarView}
          contentHeight={this.contentHeight()}
        />

        {event?.shift ? (
          <Modal show={!!event?.shift} onHide={this.onHideShiftsModal} size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>SHIFT INFORMATION</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ marginBottom: '1em' }}>{this.getStaffAction(event)}</div>
              <Row style={{ marginBottom: '2em' }}>
                <Col style={{ minWidth: '70%' }}>
                  <div style={{ height: '400px' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                      defaultCenter={{
                        lat,
                        lng,
                      }}
                      defaultZoom={14}
                    >
                      <AnyReactComponent lat={lat} lng={lng} />
                    </GoogleMapReact>
                  </div>
                  <div className='apply'>
                    <div>
                      <strong>{venue.name}</strong>
                      <div>{event.shift.location ?? venue.location}</div>
                      <div>Date: {uxDate(event.shift.start)}</div>
                      <div>Role: {event.shift.role}</div>
                      {!isSub(user.role, user.hiredBy) && <div>Rate: ${event.shift.rate} / hr</div>}
                      <div>
                        From: {getTime(event.shift.start)} To {getTime(event.shift.end)}
                      </div>
                      Note:{' '}
                      <div
                        style={{ whiteSpace: 'break-spaces' }}
                        dangerouslySetInnerHTML={{
                          __html: convertLinksToAnchor(
                            (event.shift.note ?? venue.note)?.toUpperCase(),
                            '<<<< CLICK HERE >>>>'
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {isConfirmed ? (
                  <Col
                    className='clientImages'
                    style={{ display: images.length ? 'block' : 'none' }}
                  >
                    {images.map((image) => (
                      <div>
                        <a href={image} target='_blank' rel='noreferrer'>
                          <img alt={image} key={image} src={image} />
                        </a>
                      </div>
                    ))}
                  </Col>
                ) : null}
              </Row>
            </Modal.Body>
          </Modal>
        ) : null}
        <Modal size='sm' show={!!decline} onHide={() => this.setState({ decline: null })}>
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            Please state a reason why you are declining your shift
            <Form.Control as='textarea' ref={(e) => (this.declineComment = e)} rows='3' />
            <span className='actionButton deleteColor' onClick={this.decline}>
              DECLINE
            </span>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let { user, shifts, workflows, clients } = state.app;
  return {
    shifts,
    workflows,
    user,
    clients: clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShift: (id, slotId) => dispatch(appAction.fetchShift(id, slotId)),
    fetchStaffShifts: (range) => dispatch(appAction.fetchStaffShifts(range)),
    fetchStaffProfileShifts: (settings) => dispatch(appAction.fetchStaffProfileShifts(settings)),
    apply: (shift) => dispatch(appAction.apply(shift)),
    decline: (shift) => dispatch(appAction.decline(shift)),
    clearShifts: () => dispatch(appAction.clearShifts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComp);
