import React, { Component } from 'react';
import * as appAction from 'Actions/app.actions';
import { FormControl, Modal, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';

import Autocomplete from 'Components/Autocomplete/Autocomplete';
import DatePicker from 'react-date-picker';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css';

import { Uploader } from '../Uploader';
import Select from 'react-select';
import { getMonthYear, setShiftDateTime, assingDateToTime } from 'Utils/dates';
import Checkbox from 'Components/Checkbox/Checkbox';
import pdfplaceholder from '../../images/pdfplaceholder.jpeg';
import { ViewDoc } from 'Components/Modals/ProfileModals';
import toast from '../Toast/Toast';
import { isAdmin } from 'Utils/auth';

import { SpinnerOverlay } from 'Components/Utils/Spinner';

const SHIFT_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

class Shift extends Component {
  state = {
    shift: this.props.shift || {},
    search: '',
    stayStaff: [],
    location: '',
    images: (this.props.shift && this.props.shift.images) || [],
    quantity: this.props.shifts?.length || 1,
    loading: false,
    dateTimes: [
      {
        date: this.props.shift.start,
        indexDate: new Date(this.props.shift.start).toLocaleDateString('en-ca'),
        start: this.props.shift.end ? this.props.shift.start : null,
        end: this.props.shift.end,
        id: uuid(),
      },
    ],
  };

  isShiftValid = () => {
    const { clientsMap } = this.props;
    let { shift, quantity, dateTimes } = this.state;
    let { clientId, rate, role, location } = shift;
    if (!(quantity <= 250 && quantity >= 1)) return false;
    if (!clientId || !rate || !role || (!location && !clientsMap[clientId]?.location)) return false;
    return !dateTimes?.some(({ date, start, end }) => !date || !end || !start);
  };

  addShift = async () => {
    if (!this.isShiftValid()) return toast('shift is not valid');
    let { shift, quantity, dateTimes } = this.state;
    const { clientsMap } = this.props;
    const client = clientsMap[shift.clientId];

    let newShift = {
      clientId: shift.clientId,
      location: shift.location,
      lat: shift.lat,
      lng: shift.lng,
      rate: shift.rate,
      role: shift.role.toUpperCase(),
      images: this.state.images,
      status: 'open',
      privateShift: !!shift.privateShift,
      isOpenToAll: !!shift.isOpenToAll,
    };
    if (shift.note?.toLowerCase()?.trim() !== client.note?.toLowerCase()?.trim()) {
      newShift.note = shift.note;
    }

    this.setState({ loading: true });

    const createdShifts = [];

    for (let i = 0; i < dateTimes?.length; i++) {
      const _dateTime = dateTimes[i];
      const update = {
        monthYear: getMonthYear(_dateTime.date),
        start: assingDateToTime(_dateTime.date, _dateTime.start),
        end: assingDateToTime(_dateTime.date, _dateTime.end),
        slotId: uuid(),
        indexDate: _dateTime.indexDate,
      };

      const shiftToCreate = { ...newShift, ...update };

      await this.props.addShift(shiftToCreate, quantity);

      createdShifts.push(shiftToCreate);
    }

    this.setState({ loading: false });
    this.props.action({ createdShifts });
  };

  updateShift = async () => {
    if (!this.isShiftValid()) return toast('shift is not valid');
    let { shift, dateTimes } = this.state;
    let { shifts } = this.props;

    const _dateTime = dateTimes[0];

    const { clientsMap } = this.props;
    const client = clientsMap[shift.clientId];

    let newShift = {
      clientId: shift.clientId,
      slotId: shift.slotId,
      location: shift.location,
      lat: shift.lat,
      lng: shift.lng,
      images: this.state.images,
      rate: shift.rate,
      role: shift.role?.toUpperCase(),
      privateShift: !!shift.privateShift,
      isOpenToAll: !!shift.isOpenToAll,
      monthYear: getMonthYear(_dateTime.date),
      start: assingDateToTime(_dateTime.date, _dateTime.start),
      end: assingDateToTime(_dateTime.date, _dateTime.end),
      indexDate: _dateTime.indexDate,
    };

    if (shift.note?.toLowerCase()?.trim() !== client.note?.toLowerCase()?.trim()) {
      newShift.note = shift.note;
    } else {
      newShift.note = '';
    }

    this.setState({ loading: true });

    if (this.props.single) {
      await this.props.updateShift({ ...newShift, id: shift.id });
    } else {
      let diff = (this.state.quantity || 1) - shifts.length;
      let needToDelete = [];
      if (diff < 0) {
        needToDelete = shifts?.reduce((shiftIds, shift) => {
          if (shift?.status === 'open' && shiftIds.length + diff < 0)
            shiftIds.push({
              id: shift.id,
              workflowId: shift.workflowId,
              userId: shift.userId,
            });
          return shiftIds;
        }, []);
        if (needToDelete.length) await this.props.deleteShift(needToDelete);
      } else if (diff > 0) {
        const prepShift = { ...newShift, status: 'open' };
        await this.props.addShift(prepShift, diff);
      }

      const needToDeleteIds = needToDelete?.map(({ id }) => id);

      const updateShiftIds = shifts
        .map((shift) => shift.id)
        .filter((id) => !needToDeleteIds?.includes(id));
      await this.props.updateShift(newShift, updateShiftIds);

      if (needToDelete.length + diff < 0) {
        return this.setState({ declineChoose: true, loading: false });
      }
    }
    this.setState({ loading: false });
    this.props.action();
  };

  declineChoose = async () => {
    let { shifts } = this.props;
    let needToDel = [];
    this.setState({ loading: true });
    for (let i = 0, j = shifts.length; i < j; i++) {
      if (!shifts[i]) continue;
      if (!this.state.stayStaff.includes(shifts[i].id)) {
        needToDel.push({
          id: shifts[i].id,
          workflowId: shifts[i].workflowId,
          userId: shifts[i].userId,
        });
      }
    }
    await this.props.deleteShift(needToDel);
    this.setState({ loading: false });
    this.props.action();
  };

  takenShifts = () => this.props.shifts.filter((s) => s.status !== 'open');

  onImageDelete = (url) => this.setState({ images: this.state.images.filter((i) => i !== url) });

  onFileUpload = async ({ currentTarget }) => {
    try {
      this.setState({ loading: true });
      let location = await this.props.saveAttachment({
        file: currentTarget.files[0],
        name: currentTarget.files[0].name,
      });
      let name = currentTarget.getAttribute('name');
      let images = this.state.images && Array.isArray(this.state.images) ? this.state.images : [];
      images = [location, ...images];
      this.setState({ [name]: [...images] });
      currentTarget.value = '';
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onQuantityChanged = ({ currentTarget }) => {
    let { value } = currentTarget;
    if (!this.ifValidNumber(value)) return;
    this.setState({ quantity: value });
  };

  ifValidNumber = (v) => /^\d*$/.test(v) && v <= 250;

  addDateTime = () => {
    this.setState({
      dateTimes: [
        ...this.state.dateTimes,
        {
          date: this.props.shift.start,
          indexDate: new Date(this.props.shift.start)?.toLocaleDateString('en-ca'),
          start: null,
          end: null,
          id: uuid(),
        },
      ],
    });
  };

  removeDateTime = () => {
    const { dateTimes } = this.state;

    if (dateTimes.length <= 1) return;

    this.setState({
      dateTimes: this.state.dateTimes.slice(0, -1),
    });
  };

  render() {
    let { shift, quantity, images, loading, dateTimes } = this.state;
    const { clients, roles, single, user, mode, clientsMap, users = [] } = this.props;
    let userDoc = this.state.viewDoc;
    if (userDoc && userDoc.split('.').pop().match('docs|docx|doc'))
      userDoc = `https://view.officeapps.live.com/op/embed.aspx?src=${userDoc}`;

    const venue = clientsMap[shift.clientId] ?? null;

    return (
      <div style={{ display: 'grid', gridRowGap: '10px' }}>
        <Select
          onChange={({ value }) => {
            const client = clientsMap[value];
            if (!client) return;
            const location = document.querySelector('input[name="location"]');
            if (location) location.value = client.location;
            this.setState({
              images: [...(client.images || []), ...images],
              shift: {
                ...shift,
                clientId: client.id,
                note: client.note,
                location: client.location,
                lat: client.lat,
                lng: client.lng,
              },
            });
          }}
          value={venue ? { label: venue.name, value: venue.id } : null}
          placeholder='Client/Venue'
          options={clients.map(({ id: value, name: label }) => ({
            value,
            label,
          }))}
          aria-label='Client/Venue'
        />
        <Autocomplete
          value={clientsMap[shift.clientId]?.location ?? ''}
          setAddress={(location, { lat, lng }) =>
            this.setState({
              shift: {
                ...shift,
                location,
                lat,
                lng,
              },
            })
          }
          placeholder='Address'
          bootstrap={true}
        />
        <br />
        <Select
          onChange={({ value }) => {
            let role = roles.find((role) => role.id === value);
            if (!role) return;
            this.setState({
              shift: { ...shift, role: role.name, rate: role.rate },
            });
          }}
          value={shift.role ? { value: shift.role, label: shift.role } : null}
          placeholder='Role'
          options={roles.map(({ id: value, name: label }) => ({
            value,
            label,
          }))}
          aria-label='Role'
        />
        <div className='inputGroup'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id='rateId'>$</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type='number'
              onChange={({ currentTarget: { value: rate } }) =>
                this.ifValidNumber(rate) &&
                this.setState({
                  shift: { ...shift, rate },
                })
              }
              placeholder='Rate'
              value={shift.rate || ''}
              aria-label='Rate'
            />
          </InputGroup>
          {!single ? (
            <FormControl
              type='number'
              onChange={this.onQuantityChanged}
              placeholder='Quantity'
              value={quantity}
              aria-label='Quantity'
            />
          ) : null}
        </div>
        <br />
        {dateTimes.map((dateTime) => {
          return (
            <div className='inputGroup' key={dateTime.id}>
              <DatePicker
                onChange={(date) => {
                  const initialDate = new Date(date);
                  if (isNaN(initialDate.getTime())) return;
                  const update = {
                    date: initialDate.toISOString(),
                    indexDate: initialDate.toLocaleDateString('en-ca'),
                  };
                  this.setState({
                    dateTimes: dateTimes.map((_dateTime) => {
                      if (_dateTime.id !== dateTime.id) return _dateTime;
                      return { ..._dateTime, ...update };
                    }),
                  });
                }}
                value={new Date(dateTime?.date || '')}
                calendarIcon={null}
                view='month'
                clearIcon={null}
                yearPlaceholder='YYYY'
                dayPlaceholder='DD'
                monthPlaceholder='MM'
                className='width200'
                dayAriaLabel='Day'
                monthAriaLabel='Month'
                yearAriaLabel='Year'
                nativeInputAriaLabel='Date'
              />
              <TimeRangePicker
                rangeDivider=' to '
                onChange={(time = []) => {
                  const timeToupdate = {};
                  if (typeof time[0] === 'string' && time[0]) {
                    try {
                      timeToupdate.start = setShiftDateTime(dateTimes.date, time[0]);
                    } catch (error) {
                      console.error(error);
                    }
                  } else if (typeof time[1] === 'string' && time[1]) {
                    try {
                      timeToupdate.end = setShiftDateTime(dateTimes.date, time[1]);
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  this.setState({
                    dateTimes: dateTimes.map((_dateTime) => {
                      if (_dateTime.id !== dateTime.id) return _dateTime;
                      return { ..._dateTime, ...timeToupdate };
                    }),
                  });
                }}
                value={[
                  dateTime?.start ? new Date(dateTime.start) : '',
                  dateTime?.end ? new Date(dateTime.end) : '',
                ]}
                disableClock
                clearIcon={null}
                calendarIcon={null}
                amPmAriaLabel='Select AM/PM'
                hourAriaLabel='Hour'
                minuteAriaLabel='Minute'
                nativeInputAriaLabel='Time'
                secondAriaLabel='Second'
                format='h:mm a'
              />
              <br />
            </div>
          );
        })}

        {mode === SHIFT_MODE.CREATE && (
          <>
            <div>
              <span className='actionButton' style={{ margin: '16px' }} onClick={this.addDateTime}>
                Add Time
              </span>
              {dateTimes.length > 1 && (
                <span
                  className='actionButton'
                  style={{ margin: '16px' }}
                  onClick={this.removeDateTime}
                >
                  Remove Time
                </span>
              )}
            </div>
          </>
        )}

        <FormControl
          placeholder='Note'
          value={shift.note || venue?.note || ''}
          onChange={({ currentTarget: { value: note } }) =>
            this.setState({
              shift: { ...shift, note },
            })
          }
          aria-label='Note'
          as='textarea'
          rows='3'
        />
        <Checkbox
          label='Private Posting Only'
          checked={!!shift.privateShift}
          style={{ margin: '20px 0' }}
          onChange={({ currentTarget: { checked: privateShift } }) =>
            this.setState({
              shift: {
                ...shift,
                privateShift,
                isOpenToAll: false,
              },
            })
          }
        />
        <Checkbox
          label='Open To Everyone'
          checked={!!shift.isOpenToAll}
          style={{ margin: '20px 0' }}
          onChange={({ currentTarget: { checked: isOpenToAll } }) =>
            this.setState({
              shift: {
                ...shift,
                isOpenToAll,
                privateShift: false,
              },
            })
          }
        />
        <Uploader onFileUpload={this.onFileUpload} name='images' />
        <div className='clientImages'>
          {(images || []).map((img, i) => (
            <div key={`${img}-${i}`}>
              <img
                alt={img.split('profiles/')[1].replace(/%20/g, ' ')}
                src={img.split('.').pop().match('pdf|docs|docx|doc') ? pdfplaceholder : img}
                onClick={() => this.setState({ viewDoc: img })}
              />
              {isAdmin(user.role) && (
                <button
                  type='button'
                  className='close closeButton'
                  onClick={() => this.onImageDelete(img)}
                >
                  <span aria-hidden='true'>×</span>
                  <span className='sr-only'>Close</span>
                </button>
              )}
            </div>
          ))}
        </div>
        {mode === SHIFT_MODE.CREATE ? (
          <span style={{ width: '150px' }} onClick={this.addShift} className='actionButton'>
            submit
          </span>
        ) : null}
        {mode === SHIFT_MODE.EDIT ? (
          <span style={{ width: '150px' }} onClick={this.updateShift} className='actionButton'>
            update
          </span>
        ) : null}
        <Modal
          show={this.state.declineChoose && this.takenShifts().length > 0}
          onHide={() => this.setState({ declineChoose: false })}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div>Choose who you want to stay</div>
            <div className='users'>
              {this.takenShifts().map((shift) => {
                const user = users[shift?.userId];
                return (
                  <div
                    key={shift.id}
                    onClick={() => {
                      const shiftId = this.state?.stayStaff?.find((stayId) => stayId === shift.id);
                      if (shiftId)
                        this.setState({
                          stayStaff: this.state.stayStaff?.filter((stayId) => stayId !== shift.id),
                        });
                      else
                        this.setState({
                          stayStaff: [...(this.state.stayStaff || []), shift.id],
                        });
                    }}
                    style={{
                      border: `2px solid ${this.state.stayStaff.includes(shift.id) ? 'green' : ''}`,
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ fontSize: '11px', textAlign: 'center' }}>
                      <div>{`${user.fName ?? ''} ${user.lName ?? ''}`}</div>
                      <div>{user.phone}</div>
                      <div>{user.email}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <span className='actionButton' onClick={this.declineChoose}>
              Confirm
            </span>
            <span
              className='actionButton'
              onClick={() => this.setState({ declineChoose: false })}
              style={{ marginLeft: '16px' }}
            >
              Cancel
            </span>
          </Modal.Body>
        </Modal>
        <ViewDoc
          onHide={() => this.setState({ viewDoc: null })}
          viewDoc={this.state.viewDoc}
          userDoc={userDoc}
        />
        {loading && <SpinnerOverlay />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.app.clients,
    clientsMap: state.app.clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
    roles: state.app.roles,
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addShift: (shift, quantity) => dispatch(appAction.addShift(shift, quantity)),
    updateShift: (shift, shiftIds) => dispatch(appAction.updateShift(shift, shiftIds)),
    deleteShift: (id) => dispatch(appAction.deleteShift(id)),
    saveAttachment: (file) => dispatch(appAction.saveAttachment(file)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shift);
