import EventPlusApi from 'Apis/EventPlusApi';
import toast from '../components/Toast/Toast';

const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;

const checkFileSize = (size) => {
  if (bytesToMegaBytes(size) > 2) {
    toast('File size exceeds 2 MB');
    throw new Error('File size exceeds 2 MB');
  }
};

export function generatePayroll(props) {
  return () => EventPlusApi.generatePayroll(props);
}

export function fetchMemos() {
  return (dispatch) => {
    return EventPlusApi.fetchMemos()
      .then((res) => {
        dispatch({
          type: 'FETCH_MEMOS',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function addMemo(memo) {
  return (dispatch) => {
    return EventPlusApi.addMemo(memo)
      .then((res) => {
        dispatch({
          type: 'ADD_MEMO',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function deleteMemo(id) {
  return (dispatch) => {
    return EventPlusApi.deleteMemo(id)
      .then((res) => {
        dispatch({
          type: 'DELETE_MEMO',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function fetchDashPics() {
  return (dispatch) => {
    return EventPlusApi.fetchDashPics()
      .then((res) => {
        dispatch({
          type: 'FETCH_DASHPICS',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function updateDashPic(pic) {
  return (dispatch) => {
    return EventPlusApi.updateDashPic(pic)
      .then((res) => {
        dispatch({
          type: 'UPDATE_DASHPIC',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function deleteDashPic(id) {
  return (dispatch) => {
    return EventPlusApi.deleteDashPic(id)
      .then((res) => {
        dispatch({
          type: 'DELETE_DASHPIC',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function saveDashPic({ important, ...file }) {
  return async (dispatch) => {
    try {
      checkFileSize(file.file.size);
      const url = await EventPlusApi.saveAttachment(file, 'eventplusapp', 'dashboard');
      const image = { url };
      if (important) image.important = true;
      const payload = await EventPlusApi.addDashPic(image);
      dispatch({
        type: 'ADD_DASHPIC',
        payload,
      });
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };
}

export function fetchRoles(isManager) {
  return (dispatch) => {
    return EventPlusApi.fetchRoles()
      .then((res) => {
        dispatch({
          type: 'FETCH_ROLES',
          payload: isManager ? res : res.map(({ rate, ...rest }) => rest),
        });
      })
      .catch(console.error);
  };
}

export function addRole(role) {
  return (dispatch) => {
    return EventPlusApi.addRole(role)
      .then((res) => {
        dispatch({
          type: 'ADD_ROLE',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function updateRole(role) {
  return (dispatch) => {
    return EventPlusApi.updateRole(role)
      .then((res) => {
        dispatch({
          type: 'UPDATE_ROLE',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function deleteRole(id) {
  return (dispatch) => {
    return EventPlusApi.deleteRole(id)
      .then((res) => {
        dispatch({
          type: 'DELETE_ROLE',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function fetchClients() {
  return (dispatch) => {
    return EventPlusApi.fetchClients()
      .then((res) => {
        dispatch({
          type: 'FETCH_CLIENTS',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function addClient(client) {
  return (dispatch) => {
    return EventPlusApi.addClient(client)
      .then((res) => {
        dispatch({
          type: 'ADD_CLIENT',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function deleteClient(id) {
  return (dispatch) => {
    return EventPlusApi.deleteClient(id)
      .then((res) => {
        dispatch({
          type: 'DELETE_CLIENT',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function updateClient(client) {
  return (dispatch) => {
    return EventPlusApi.updateClient(client)
      .then((res) => {
        dispatch({
          type: 'UPDATE_CLIENT',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function fetchShift(id, slotId) {
  return (dispatch) => {
    return EventPlusApi.fetchShift(id, slotId).then((res) => {
      dispatch({
        type: 'Fetch single shift',
      });
      return Promise.resolve(res);
    });
  };
}

export function clearEvent() {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_EVENT_SHIFTS',
      payload: [],
    });
    dispatch({
      type: 'FETCH_EVENT_WORKFLOWS',
      payload: [],
    });
  };
}

export function fetchEvent(slotId) {
  return (dispatch) => {
    return EventPlusApi.fetchEvent(slotId).then((res) => {
      dispatch({
        type: 'FETCH_EVENT_SHIFTS',
        payload: res.shifts,
      });
      dispatch({
        type: 'FETCH_EVENT_WORKFLOWS',
        payload: res.workflows,
      });
      dispatch({
        type: 'FETCH_EVENT',
        payload: res.event,
      });
      return Promise.resolve();
    });
  };
}

export function updateEvent(event) {
  return async (dispatch) => {
    const res = await EventPlusApi.updateEvent(event);
    if (res) {
      dispatch({
        type: 'FETCH_EVENT',
        payload: res.event,
      });
    }
    return;
  };
}

export function fetchShifts(range) {
  return (dispatch) => {
    return EventPlusApi.fetchShifts(range)
      .then((res) => {
        dispatch({
          type: 'FETCH_SHIFTS',
          payload: res,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        console.error(err);
        return Promise.resolve();
      });
  };
}

export function fetchStaffProfileShifts(settings) {
  return (dispatch) => {
    return EventPlusApi.fetchStaffProfileShifts(settings)
      .then((res) => {
        dispatch({
          type: 'FETCH_SHIFTS',
          payload: res.shifts,
        });
        dispatch({
          type: 'FETCH_WORKFLOWS',
          payload: res.workflows,
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.error(err);
        return Promise.resolve();
      });
  };
}

export function fetchStaffShifts(range) {
  return (dispatch) => {
    return EventPlusApi.fetchStaffShifts(range)
      .then((res) => {
        dispatch({
          type: 'FETCH_SHIFTS',
          payload: res.shifts,
        });
        dispatch({
          type: 'FETCH_WORKFLOWS',
          payload: res.workflows,
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.error(err);
        return Promise.resolve();
      });
  };
}

export function fetchLogSheet(range) {
  return (dispatch) => {
    return EventPlusApi.fetchLogSheet(range)
      .then((res) => {
        dispatch({
          type: 'FETCH_SHIFTS',
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch(console.error);
  };
}

export function addShift(shift, quantity = 1) {
  return (dispatch) => {
    return EventPlusApi.addShift({ shift, quantity })
      .then((res) => {
        dispatch({
          type: 'ADD_SHIFT',
          payload: Array.isArray(res) ? res : [res],
        });
        return Promise.resolve();
      })
      .catch(console.error);
  };
}

export function deleteShift(id, slotId) {
  return (dispatch) => {
    return EventPlusApi.deleteShift(id, slotId)
      .then((res) => {
        dispatch({
          type: 'DELETE_SHIFT',
          payload: Array.isArray(id) ? id : [id],
        });
      })
      .catch(console.error);
  };
}

export function updateShift(shift, shiftIds) {
  return (dispatch) => {
    return EventPlusApi.updateShift(shift, shiftIds)
      .then((res) => {
        dispatch({
          type: 'UPDATE_SHIFT',
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function notifyPosting(slotId, changes) {
  return (dispatch) => {
    return EventPlusApi.notifyPosting(slotId, changes)
      .then((res) => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };
}

export function login(email, password) {
  return (dispatch) => {
    return EventPlusApi.login(email, password)
      .then((res) => {
        dispatch({
          type: 'ADD_USER',
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch(Promise.reject);
  };
}

export function register(user) {
  return (dispatch) => {
    return EventPlusApi.register(user)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch(console.error);
  };
}

export function me() {
  return (dispatch) => {
    return EventPlusApi.me()
      .then((res) => {
        dispatch({
          type: 'ADD_USER',
          payload: res,
        });
        return Promise.resolve(res);
      })
      .catch((error) => Promise.reject(error));
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: 'ADD_USER',
      payload: {},
    });
    return EventPlusApi.logout()
      .then((res) => {
        return Promise.resolve();
      })
      .catch(console.error);
  };
}

export function updateUser(user, staff) {
  return (dispatch) => {
    return EventPlusApi.updateUser(user)
      .then((res) => {
        if (staff) {
          dispatch({
            type: 'UPDATE_USER_STAFF',
            payload: res,
          });
          return Promise.resolve(res);
        } else {
          dispatch({
            type: 'ADD_USER',
            payload: res,
          });
        }

        return Promise.resolve();
      })
      .catch((err) => Promise.reject(err.response.data));
  };
}

export function createProfile(user) {
  return (dispatchEvent) => {
    return EventPlusApi.createProfile(user)
      .then((res) => {
        // dispatch({
        //   type: "ADD_USER",
        //   payload: res.data.user,
        // });
        return Promise.resolve(res.data.user);
      })
      .catch((err) => Promise.reject(err?.response?.data));
  };
}

export function deleteProfile(id) {
  return (dispatch) => {
    return EventPlusApi.deleteProfile(id)
      .then((res) => {
        dispatch({
          type: 'DELETE_USER',
          payload: res?.data?.user,
        });
      })
      .catch((err) => Promise.reject(err?.response?.data));
  };
}

export function apply(shift) {
  return (dispatch) => {
    return EventPlusApi.apply(shift)
      .then((res) => {
        dispatch({
          type: 'ADD_WORKFLOW',
          payload: res,
        });
        dispatch({
          type: 'DELETE_SHIFT',
          payload: [shift.id],
        });
      })
      .catch(console.error);
  };
}

export function decline(workflow, addWorkflow) {
  return (dispatch) => {
    return EventPlusApi.decline(workflow)
      .then((res) => {
        if (addWorkflow) {
          dispatch({
            type: 'ADD_WORKFLOW',
            payload: res.workflow,
          });
        } else {
          dispatch({
            type: 'UPDATE_WORKFLOW',
            payload: res.workflow,
          });
        }
        dispatch({
          type: 'UPDATE_SHIFT',
          payload: res.shift,
        });
      })
      .catch(console.error);
  };
}

export function approve(workflow, shift) {
  return (dispatch) => {
    return EventPlusApi.approve(workflow, shift)
      .then((res) => {
        dispatch({
          type: 'UPDATE_SHIFT',
          payload: res.shift,
        });
        dispatch({
          type: 'DELETE_WORKFLOW',
          payload: res.workflow.id,
        });
        return Promise.resolve();
      })
      .catch(console.error);
  };
}

export function confirmShift(workflowId) {
  return (dispatch) => {
    return EventPlusApi.confirmShift(workflowId)
      .then((res) => {
        dispatch({
          type: 'UPDATE_SHIFT',
          payload: res,
        });
        dispatch({
          type: 'UPDATE_WORKFLOW',
          payload: res.workflow,
        });
        return Promise.resolve();
      })
      .catch(console.error);
  };
}

export function fetchSignOffs(range) {
  return (dispatch) => {
    return EventPlusApi.fetchSignOffs(range)
      .then((res) => {
        dispatch({
          type: 'FETCH_SIGNOFFS',
          payload: res,
        });
      })
      .catch(console.error);
  };
}

export function fetchSignOffShifts(slotId) {
  return (dispatch) => {
    return EventPlusApi.fetchSignOffShifts(slotId).then((res) => {
      dispatch({
        type: 'FETCH_SHIFTS',
        payload: res.shifts,
      });
      dispatch({
        type: 'FETCH_EVENT',
        payload: res.event,
      });
    });
  };
}

export function clearShifts() {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_SHIFTS',
      payload: [],
    });
  };
}

export function fetchAllUsers(search, notSave) {
  return (dispatch) => {
    return EventPlusApi.fetchAllUsers(search)
      .then((res) => {
        if (notSave) {
          return Promise.resolve(res);
        } else {
          dispatch({
            type: 'FETCH_ALL_USERS',
            payload: res,
          });
        }
      })
      .catch(console.error);
  };
}

export function fetchUser(id) {
  return (dispatch) => {
    return EventPlusApi.fetchUser(id)
      .then((res) => {
        dispatch({
          type: 'FETCH_USER_PROFILE',
        });
        return Promise.resolve(res);
      })
      .catch(console.error);
  };
}

export function fetchUsers(ids) {
  return (dispatch) => {
    return EventPlusApi.fetchUsers(ids)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch(console.error);
  };
}

export function saveAttachment(file, bucket, folder, asl) {
  return async (dispatch) => {
    checkFileSize(file.file.size);
    return EventPlusApi.saveAttachment(file, bucket, folder, asl)
      .then((res) => {
        dispatch({
          type: 'ATTACHMENT_SAVED',
        });
        return Promise.resolve(res);
      })
      .catch((e) => Promise.reject(e));
  };
}

export function resetPassword(psw, token) {
  return (dispatch) => {
    return EventPlusApi.resetPassword(psw, token)
      .then((res) => {
        return Promise.resolve();
      })
      .catch(console.error);
  };
}

export function onPasswordForget(email) {
  return (dispatch) => {
    return EventPlusApi.onPasswordForget(email)
      .then()
      .catch(console.erorr)
      .finally(() => {
        return Promise.resolve();
      });
  };
}

export function sendMessage(msg) {
  return (dispatch) => {
    return EventPlusApi.sendMessage(msg)
      .then((res) => {
        return Promise.resolve();
      })
      .catch((error) => {
        console.error(error);
        return Promise.resolve();
      });
  };
}

export function fetchBirthdays() {
  return (dispatch) => {
    return EventPlusApi.fetchBirthdays()
      .then((res) => {
        dispatch({
          type: 'SET_BIRTHDAYS',
          payload: res.users,
        });
      })
      .catch(console.error);
  };
}

export function sendWishes(userId, isBirthday) {
  return (dispatch) => {
    return EventPlusApi.sendWishes(userId, isBirthday)
      .then((res) => {
        return Promise.resolve();
      })
      .catch(console.error);
  };
}

export function changeEmail(email, userId) {
  return (dispatch) => {
    return EventPlusApi.changeEmail(email, userId).then(Promise.resolve).catch(console.error);
  };
}

export function createWorkflow(workflow) {
  return (dispatch) => {
    return EventPlusApi.createWorkflow(workflow)
      .then((res) => {
        return Promise.resolve(res?.data?.workflow);
      })
      .catch(Promise.reject);
  };
}
