import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import './SignOffs.scss';
import FullCalendar from '@fullcalendar/react';
import ListGridPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { SpinnerOverlay } from 'Components/Utils/Spinner';

const makeEvents = (shifts = [], clients) => {
  const events = shifts?.reduce((allEvents, shift) => {
    const event = {
      title: `${clients[shift.clientId]?.name ?? 'Unknown'} - ${shift.role ?? ''}`,
      start: new Date(shift.start),
      end: new Date(shift.end),
      shift: shift,
      backgroundColor: shift.signoff ? 'green' : 'rgb(253, 182, 57)',
      className: 'cursor',
    };
    allEvents.push(event);
    return allEvents;
  }, []);
  return events;
};

class LogSheet extends Component {
  state = {};

  componentDidMount = () => {
    this.props.clearShifts();
  };

  componentWillUnmount() {
    this.props.clearShifts();
  }

  onEventClick = ({ event }) => {
    let shift = event.extendedProps.shift;
    window.open(`/signoffs/${shift.slotId}`);
  };

  onRangeChange = async ({ view }) => {
    const start = new Date(view.currentStart).toISOString();
    const end = new Date(view.currentEnd).toISOString();
    this.setState({ loading: true });
    await this.props.fetchSignOffs({ start, end });
    this.setState({ loading: false });
  };

  render() {
    const { signoffs, clients } = this.props;
    return (
      <>
        {this.state.loading && <SpinnerOverlay />}
        <div className='label'>Sign Offs</div>
        <FullCalendar
          plugins={[ListGridPlugin, interactionPlugin]}
          events={makeEvents(signoffs, clients)}
          eventClick={this.onEventClick}
          datesSet={this.onRangeChange}
          dayCellClassNames={'cursor'}
          initialView='listMonth'
          contentHeight={'auto'}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shifts: state.app.shifts,
    signoffs: state.app.signoffs,
    clients: state.app.clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addShift: (shift) => dispatch(appAction.addShift(shift)),
    updateShift: (shift) => dispatch(appAction.updateShift(shift)),
    fetchSignOffs: (range) => dispatch(appAction.fetchSignOffs(range)),
    clearShifts: () => dispatch(appAction.clearShifts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogSheet);
