import React, { Component } from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { FormControl } from 'react-bootstrap';

const onError = (error) => console.error(error);

const rendererInput = (prediction) => (
  <div className='customWrapper'>{prediction ? prediction.description : 'No Results'}</div>
);

const componentRestrictions = { country: 'ca' };

export default class Profile extends Component {
  state = {
    search: '',
    value: this.props.value,
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextState.search !== this.state.search || nextProps.placeholder !== this.props.placeholder)
      return true;
    return false;
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value) this.setState({ value: this.props.value });
  };

  handleInputChange = ({ currentTarget }) => {
    this.setState({ search: currentTarget.value, value: currentTarget.value });
    if (!currentTarget.value) {
      this.forceUpdate();
      this.props.setAddress('', { lat: null, lng: null });
    }
  };

  handleSelectSuggest = ({ geometry, formatted_address }) => {
    this.setState({ search: '', value: formatted_address });
    this.props.setAddress(formatted_address.toUpperCase(), {
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    });
    const location = document.querySelector(`input[name="${this.props.name || 'location'}"]`);
    if (location) location.value = formatted_address.toUpperCase();
  };

  render() {
    const {
      style = {},
      bootstrap,
      id = '',
      type = 'text',
      name = 'location',
      placeholder = 'Address',
      required,
      readOnly,
      disabled,
    } = this.props;
    const { search: input, value = '' } = this.state;
    return (
      <ReactGoogleMapLoader
        params={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries: 'places,geocode',
        }}
        render={(googleMaps) =>
          googleMaps && (
            <ReactGooglePlacesSuggest
              googleMaps={googleMaps}
              autocompletionRequest={{
                input,
                componentRestrictions,
              }}
              // Optional props
              onSelectSuggest={this.handleSelectSuggest}
              textNoResults='No Results'
              customRender={rendererInput}
              onFail={onError}
            >
              {bootstrap ? (
                <FormControl
                  id={id}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  onChange={this.handleInputChange}
                  value={value}
                  autoComplete='off'
                  className={required ? 'required' : ''}
                  style={style}
                  aria-label='autoComplete'
                  disabled={readOnly}
                  readOnly={disabled}
                />
              ) : (
                <input
                  id={id}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  onChange={this.handleInputChange}
                  value={value}
                  autoComplete='off'
                  style={style}
                  aria-label='autoComplete'
                  disabled={readOnly}
                  readOnly={disabled}
                />
              )}
            </ReactGooglePlacesSuggest>
          )
        }
      />
    );
  }
}
