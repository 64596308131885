export const requiredFields = ['fName', 'lName', 'email'];
export const fieldNames = {
  fName: 'First name',
  lName: 'Last name',
  phone: 'Phone',
  ssn: 'SSN',
  emConPer: 'Emergency contacts',
  emRel: 'Emergency relationship',
  emConNum: 'Emergency number',
  location: 'Location',
  image: 'Profile Image',
  email: 'Email',
};
