export const loadImage = (logo) => {
  return new Promise((resolve) => {
    fetch(logo)
      .then((r) => r.blob())
      .then((blob) => {
        let reader = new FileReader();
        reader.onload = function () {
          resolve(this.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch(console.error);
  });
};
