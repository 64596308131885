import React, { useCallback, useState } from 'react';
import { ExportToCsv } from 'export-to-csv';
import { User } from './settings.definitions';
import { connect } from 'react-redux';
import * as appAction from '../../actions/app.actions';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { uxDateTime, uxDate } from '../../util/dates';
import Checkbox from '../Checkbox/Checkbox';

const userDataKeys = [
  'fName',
  'lName',
  'status',
  'dob',
  'address',
  'email',
  'ssn',
  'ssc',
  'whsa',
  'whmis',
  'foodHandler',
  'foodSafety',
  'td1',
];

const nameKeyMap = {
  'FIRST NAME': 'fName',
  'LAST NAME': 'lName',
  STATUS: 'status',
  BIRTHDAY: 'dob',
  ADDRESS: 'address',
  'EMAIL ADDRESS': 'email',
  'SMARTSERVE #': 'ssn',
  'SMARTSERVE CARD UPLOAD': 'ssc',
  'WORK HEALTH & SAFETY': 'whsa',
  'WHMIS/GHS': 'whmis',
  'BASIC FOOD HANDLER': 'foodHandler',
  'FOOD SAFETY HANDLER': 'foodSafety',
  TD1: 'td1',
};

const UserSettings = (props: any) => {
  const { fetchAllUsers } = props;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const getLastLogins = async () => {
    let users: Array<User> = await fetchAllUsers(null, true).catch(console.error);

    users = users
      .filter((u) => u.lastLogOn)
      .sort(function (a, b) {
        return new Date(a.lastLogOn).getTime() - new Date(b.lastLogOn).getTime();
      });
    users.reverse();
    // @ts-ignore
    users.forEach((u) => (u.lastLogOn = uxDateTime(u.lastLogOn)));

    let doc = new jsPDF();
    // @ts-ignore
    doc.autoTable({
      body: users,
      columns: [
        { header: 'First Name', dataKey: 'fName' },
        { header: 'Last Name', dataKey: 'lName' },
        { header: 'Email', dataKey: 'email' },
        { header: 'Last Login', dataKey: 'lastLogOn' },
      ],
    });
    doc.save('lastLoggins.pdf');
  };

  const getUsersData = useCallback(async () => {
    const users: Array<User> = await fetchAllUsers(null, true).catch(console.error);

    const columnHeaders = {};

    selectedKeys.forEach((key) => {
      columnHeaders[nameKeyMap[key]] = key;
    });

    const usersInfo = users.map((user) => {
      const data: Record<string, string> = {};
      if (columnHeaders['fName']) data[columnHeaders['fName']] = user.fName;
      if (columnHeaders['lName']) data[columnHeaders['lName']] = user.lName;
      if (columnHeaders['status']) data[columnHeaders['status']] = user.status;
      if (columnHeaders['dob']) data[columnHeaders['dob']] = user.dob ? uxDate(user.dob) : '';
      if (columnHeaders['address']) data[columnHeaders['address']] = user.address ?? '';
      if (columnHeaders['email']) data[columnHeaders['email']] = user.email ?? '';
      if (columnHeaders['ssn']) data[columnHeaders['ssn']] = user.ssn ?? '';
      if (columnHeaders['ssc']) data[columnHeaders['ssc']] = user.ssc ? 'Y' : 'N';
      if (columnHeaders['whsa']) data[columnHeaders['whsa']] = user.whsa ? 'Y' : 'N';
      if (columnHeaders['whmis']) data[columnHeaders['whmis']] = user.whmis ? 'Y' : 'N';
      if (columnHeaders['foodHandler'])
        data[columnHeaders['foodHandler']] = user.foodHandler ? 'Y' : 'N';
      if (columnHeaders['foodSafety'])
        data[columnHeaders['foodSafety']] = user.foodSafety ? 'Y' : 'N';
      if (columnHeaders['td1']) data[columnHeaders['td1']] = user.td1 ? 'Y' : 'N';
      return data;
    });

    const options = {
      fieldSeparator: ',',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `UserData@${uxDate(new Date())}`,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(usersInfo);
  }, [fetchAllUsers, selectedKeys]);

  const onCheckboxClick = useCallback(
    ({ currentTarget }) => {
      let checked = currentTarget.checked;
      let name = currentTarget.getAttribute('name');
      if (checked) {
        setSelectedKeys([...selectedKeys, name]);
      } else {
        setSelectedKeys(selectedKeys.filter((key) => key !== name));
      }
    },
    [selectedKeys]
  );

  return (
    <div style={{ padding: 16, display: 'flex', gap: 8 }}>
      <span onClick={getLastLogins} className='actionButton'>
        Get Last Logins
      </span>
      <div>
        <span onClick={getUsersData} className='actionButton'>
          Get Users Data{' '}
        </span>
        <div>
          {Object.keys(nameKeyMap).map((name, i) => (
            <Checkbox
              key={i}
              value={nameKeyMap[name]}
              name={name}
              label={name}
              onClick={onCheckboxClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// @ts-ignore
const mapStateToProps = (state) => {
  return {
    users: state.app.users,
    user: state.app.user,
  };
};
const mapDispatchToProps = (dispatch: (dispatch: any) => void) => {
  return {
    fetchAllUsers: (search: string, notSave: boolean) =>
      dispatch(appAction.fetchAllUsers(search, notSave)),
    fetchUser: (id: string) => dispatch(appAction.fetchUser(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
