export const uxDate = (date) =>
  date
    ? new Date(date).toLocaleDateString('en-ca', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    : '';

export const shortDate = (date) =>
  date
    ? new Date(date).toLocaleDateString('en-ca', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    })
    : '';

export const shortDateWithYear = (date) => date
  ? new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })
  : '';

export const getTime = (date, hour12 = true) =>
  date ? new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12 }) : '';

export const uxDateTime = (date) => (date ? `${uxDate(date)} ${getTime(date)}` : '');

export const getAge = (dateString) => {
  if (!dateString) return;
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return `${age}`;
};

export const getMonthYear = (date = new Date()) => {
  const newDate = new Date(date);
  return `${newDate.getMonth()}-${newDate.getFullYear()}`;
};

export const getYearMonthDay = (date = new Date()) => {
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${('0' + (newDate.getMonth() + 1)).slice(
    -2
  )}-${newDate.getDate()}`;
};

export const setShiftDateTime = (date = new Date(), time = '10:00') => {
  const dateToUpdate = new Date(date);
  const [hours, mins] = time?.split(':');
  dateToUpdate.setHours(hours);
  dateToUpdate.setMinutes(mins);
  return new Date(dateToUpdate).toISOString();
};

export const assingDateToTime = (initialDate, assingTime) => {
  const _initialDate = new Date(initialDate);
  const endDate = new Date(assingTime);
  const date = _initialDate.getDate();
  const month = _initialDate.getMonth();
  const year = _initialDate.getFullYear();
  const hour = endDate.getHours();
  const mins = endDate.getMinutes();
  const sec = endDate.getSeconds();
  return new Date(year, month, date, hour, mins, sec);
};
