import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL =
  window.location.hostname === 'localhost' ? 'http://localhost' : 'https://api-app.eventspm.ca';

const MEMOS_URL = `${API_URL}/memos`;
const DASHPICS_URL = `${API_URL}/dashpics`;
const SHIFTS_URL = `${API_URL}/shifts`;
const ROLES_URL = `${API_URL}/roles`;
const CLIENTS_URL = `${API_URL}/clients`;
const USERS_URL = `${API_URL}/users`;
const MSG_URL = `${API_URL}/message`;
const WORKFLOW_URL = `${API_URL}/workflow`;
const UPLOADS_URL = `${API_URL}/uploads`;
const EVENTS_URL = `${API_URL}/events`;
const SIGNOFFS_URL = `${API_URL}/signoffs`;

axios.interceptors.request.use(function (config) {
  const token = Cookies.get('eventsAccessToken');
  if (token) config.headers.authorization = token;
  return config;
});

axios.interceptors.response.use(undefined, (err) => {
  if (err.response && err.response.status === 401) {
    if (!(window.location.href.includes('reset?token') || window.location.pathname === '/')) {
      window.location.href = '/';
    }
  }
  throw err;
});

class EventPlusApi {
  static async generatePayroll(props) {
    try {
      const result = await axios.get(`${SIGNOFFS_URL}/payroll/${JSON.stringify(props)}`);
      return result?.data;
    } catch (error) {
      console.error(error);
    }
  }

  static fetchMemos() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${MEMOS_URL}`)
        .then((res) => resolve(res.data.memos))
        .catch((e) => reject(e));
    });
  }

  static addMemo(memo) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${MEMOS_URL}`, memo)
        .then((res) => {
          resolve(res.data.mem);
        })
        .catch((e) => reject(e));
    });
  }

  static deleteMemo(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${MEMOS_URL}/${id}`)
        .then((res) => resolve(id))
        .catch((e) => reject(e));
    });
  }

  static fetchDashPics() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${DASHPICS_URL}`)
        .then((res) => resolve(res.data.pics))
        .catch((e) => reject(e));
    });
  }

  static updateDashPic(pic) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${DASHPICS_URL}`, pic)
        .then((res) => {
          resolve(res.data.pic);
        })
        .catch((e) => reject(e));
    });
  }

  static addDashPic(url) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${DASHPICS_URL}`, url)
        .then((res) => {
          resolve(res.data.pic);
        })
        .catch((e) => reject(e));
    });
  }

  static deleteDashPic(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DASHPICS_URL}/${id}`)
        .then((res) => resolve(id))
        .catch((e) => reject(e));
    });
  }

  static fetchShifts(range = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/admin/${JSON.stringify(range)}`)
        .then((res) => resolve(res.data.shifts))
        .catch((e) => reject(e));
    });
  }

  static fetchEvent(slotId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/event/${slotId}`)
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  }

  static async updateEvent(event) {
    try {
      const res = await axios.put(`${EVENTS_URL}`, { event });
      return res.data;
    } catch (e) {
      console.error(e);
    }
  }

  static fetchShift(id, slotId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/${JSON.stringify({ shiftId: id, slotId: slotId })}`)
        .then((res) => resolve(res.data.shift))
        .catch((e) => reject(e));
    });
  }

  static fetchStaffShifts(range) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/staff/${JSON.stringify(range)}`)
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  }

  static fetchStaffProfileShifts(settings) {
    console.log(settings);
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/staff/profile/${JSON.stringify(settings)}`)
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  }

  static fetchLogSheet(range) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/logsheet/${JSON.stringify(range)}`)
        .then((res) => resolve(res.data.shifts))
        .catch((e) => reject(e));
    });
  }

  static addShift(body) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${SHIFTS_URL}`, body)
        .then((res) => {
          resolve(res.data.shifts);
        })
        .catch((e) => reject(e));
    });
  }

  static updateShift(shift, shiftIds) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${SHIFTS_URL}`, { shift, shiftIds })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => reject(e));
    });
  }

  static notifyPosting(slotId, changes) {
    return axios.get(`${SHIFTS_URL}/notify/${slotId}/${changes}`);
  }

  static deleteShift(id, slotId) {
    let query = {};
    if (id) {
      if (!Array.isArray(id)) id = [id];
      query.ids = id;
    }
    if (slotId) query.slotId = slotId;
    return new Promise((resolve, reject) => {
      axios
        .delete(`${SHIFTS_URL}/${JSON.stringify(query)}`)
        .then((res) => resolve(id))
        .catch((e) => reject(e));
    });
  }

  static fetchRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ROLES_URL}`)
        .then((res) => {
          resolve(res.data.roles);
        })
        .catch((e) => reject(e));
    });
  }

  static addRole(role) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ROLES_URL}`, role)
        .then((res) => {
          resolve(res.data.role);
        })
        .catch((e) => reject(e));
    });
  }

  static updateRole(role) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${ROLES_URL}`, role)
        .then((res) => {
          resolve(res.data.role);
        })
        .catch((e) => reject(e));
    });
  }

  static deleteRole(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${ROLES_URL}/${id}`)
        .then((res) => resolve(id))
        .catch((e) => reject(e));
    });
  }

  static fetchClients() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${CLIENTS_URL}`)
        .then((res) => {
          resolve(res.data.clients);
        })
        .catch((e) => reject(e));
    });
  }

  static addClient(client) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${CLIENTS_URL}`, client)
        .then((res) => {
          resolve(res.data.client);
        })
        .catch((e) => reject(e));
    });
  }

  static deleteClient(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${CLIENTS_URL}/${id}`)
        .then((res) => resolve(id))
        .catch((e) => reject(e));
    });
  }

  static updateClient(client) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${CLIENTS_URL}`, client)
        .then((res) => {
          resolve(res.data.client);
        })
        .catch((e) => reject(e));
    });
  }

  static login(email, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USERS_URL}/login`, { email, password }, { withCredentials: true })
        .then((res) => {
          const { accessToken, user } = res.data;
          Cookies.set('eventsAccessToken', accessToken, { secure: true });
          resolve(user);
        })
        .catch((e) => reject(e));
    });
  }

  static register(user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USERS_URL}`, { user })
        .then((res) => {
          const { user } = res.data;
          resolve(user);
        })
        .catch((e) => reject(e));
    });
  }

  static me() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${USERS_URL}/me`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => reject(e));
    });
  }

  static logout() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${USERS_URL}/me/logout`)
        .then((res) => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  }

  static updateUser(user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${USERS_URL}/me`, { user })
        .then((res) => {
          resolve(res.data.user);
        })
        .catch((e) => reject(e));
    });
  }

  static createProfile(user) {
    return axios.post(`${USERS_URL}/createProfile`, user);
  }

  static deleteProfile(id) {
    return axios.delete(`${USERS_URL}/${id}`);
  }

  static fetchUser(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${USERS_URL}/${id}`)
        .then((res) => resolve(res.data.user))
        .catch((e) => reject(e));
    });
  }

  static fetchUsers(ids = []) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USERS_URL}/userInfo`, { ids })
        .then((res) => resolve(res.data.users))
        .catch((e) => reject(e));
    });
  }

  static apply(shift) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${SHIFTS_URL}/apply`, shift)
        .then((res) => {
          resolve(res.data.workflow);
        })
        .catch((e) => reject(e));
    });
  }

  static decline(workflow) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${SHIFTS_URL}/decline`, workflow)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => reject(e));
    });
  }

  static approve(workflow, shift) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${SHIFTS_URL}/approve`, { workflow, shift })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => console.error(e));
    });
  }

  static confirmShift(workflowId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${SHIFTS_URL}/confirmShift`, { workflowId })
        .then((res) => resolve(res.data))
        .catch((e) => console.error(e));
    });
  }

  static fetchSignOffs(range) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SHIFTS_URL}/signoffs/${JSON.stringify(range)}`)
        .then((res) => {
          resolve(res.data.signoffs);
        })
        .catch((e) => console.error(e));
    });
  }

  static fetchSignOffShifts(slotId) {
    return new Promise((resolve) => {
      axios
        .get(`${SHIFTS_URL}/signoffs/list/${slotId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => console.error(e));
    });
  }

  static fetchAllUsers(search) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${USERS_URL}/getall/${search ? JSON.stringify(search) : ''}`)
        .then((res) => {
          resolve(res.data.users);
        })
        .catch(console.error);
    });
  }

  static saveAttachment(file, path, folder = 'profiles', ACL = 'public-read') {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file.file);

      const bucket = path === 'epmdocuments' ? 'epmdocuments' : 'eventplusapp';
      axios
        .post(`${UPLOADS_URL}/${bucket}/${folder}`, formData)
        .then((res) => resolve(res.data.location))
        .catch(console.error);
    });
  }

  static resetPassword(password, token) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${USERS_URL}/resetPassword/${token}`, { password })
        .then()
        .catch(console.error)
        .finally(resolve);
    });
  }

  static changeEmail(email, userId) {
    return new Promise((resolve, reject) => {
      axios.post(`${USERS_URL}/changeEmail`, { email, userId }).then(resolve).catch(console.error);
    });
  }

  static onPasswordForget(email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${USERS_URL}/forgetPassword/${email}`)
        .then()
        .catch(console.error)
        .finally(resolve);
    });
  }

  static sendMessage(msg) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      msg?.message && formData.append('message', msg.message);
      msg?.to && formData.append('to[]', msg.to);
      msg?.subject && formData.append('subject', msg.subject);
      if (msg?.files?.length) {
        msg?.files?.forEach((file) => formData.append('attachments', file));
      }

      console.log(msg);

      axios
        .post(`${MSG_URL}`, formData)
        .then((res) => {
          resolve(res.data);
        })
        .catch(console.error);
    });
  }

  static fetchBirthdays() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${USERS_URL}/birthdays`)
        .then((res) => {
          resolve(res.data);
        })
        .catch(console.error);
    });
  }

  static sendWishes(userId, isBirthday) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${USERS_URL}/birthdays`, { userId, isBirthday })
        .then((res) => {
          resolve(res.data);
        })
        .catch(console.error);
    });
  }

  static createWorkflow(workflow) {
    return axios.post(WORKFLOW_URL, workflow);
  }
}

export default EventPlusApi;
