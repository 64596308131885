import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import { AgGridReact } from 'ag-grid-react';
import { FormControl, Modal } from 'react-bootstrap';
import { isAdmin } from 'Utils/auth';

const defaultColumns = [
  {
    field: 'refId',
    headerName: 'Id',
    sort: 'asc',
  },
  {
    field: 'name',
    headerName: 'Role',
  },
  {
    field: 'rate',
    headerName: 'Rate',
  },
];

// @TODO Make table longer

class Roles extends Component {
  state = {
    role: null,
    isNew: true,
  };

  DeleteFormatter = ({ data }) => {
    return isAdmin(this.props.user.role) ? (
      <div
        onClick={() =>
          window.confirm('Are you sure you want to delete this role?') &&
          this.props.deleteRole(data.id)
        }
      >
        Delete
      </div>
    ) : null;
  };

  defaultColDef = {
    sortable: true,
    resizable: true,
    sortingOrder: ['asc', 'desc'],
  };

  columns = isAdmin(this.props.user.role)
    ? [
        ...defaultColumns,
        {
          headerName: 'Action',
          cellRenderer: 'DeleteFormatter',
        },
      ]
    : defaultColumns;

  frameworkComponents = {
    DeleteFormatter: this.DeleteFormatter,
  };

  handleShow = () => this.setState({ isNew: true, role: {} });

  closeModal = () => this.setState({ role: null });

  saveRole = () => {
    this.props.addRole(this.state.role);
    this.closeModal();
  };

  updateRole = () => {
    this.props.updateRole(this.state.role);
    this.closeModal();
  };

  deleteRole = (id) => {
    this.props.deleteRole(id);
  };

  onGridReady = ({ columnApi, api }) => {
    api.sizeColumnsToFit();
  };

  onCellClicked = (params) => {
    const { colDef, data: role } = params;
    if (colDef.field && colDef.field !== 'action') {
      this.setState({ role, isNew: false });
    }
  };

  onFormChange = ({ currentTarget }) => {
    const name = currentTarget.getAttribute('name');
    const value = name === 'name' ? currentTarget.value?.toUpperCase() : currentTarget.value;

    this.setState({ role: { ...this.state.role, [name]: value } });
  };

  render() {
    const { role, isNew } = this.state;
    return (
      <React.Fragment>
        <span className='actionButton' onClick={this.handleShow} style={{ margin: '1em' }}>
          Add Role
        </span>
        <div className='w-100 h-100 ag-theme-balham'>
          <AgGridReact
            columnDefs={this.columns}
            defaultColDef={this.defaultColDef}
            rowData={this.props.roles}
            onGridReady={this.onGridReady}
            frameworkComponents={this.frameworkComponents}
            onCellClicked={this.onCellClicked}
            deltaRowDataMode
            getRowNodeId={(data) => data.id}
          />
        </div>
        <Modal show={!!this.state.role} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Role</Modal.Title>
            <br />
          </Modal.Header>
          <Modal.Body>
            <FormControl
              aria-label='Role Id'
              name='refId'
              placeholder='Id'
              style={{ marginBottom: '1rem' }}
              value={role?.refId || ''}
              onChange={this.onFormChange}
            />
            <FormControl
              aria-label='Role Name'
              name='name'
              placeholder='Name'
              style={{ marginBottom: '1rem' }}
              value={role?.name || ''}
              onChange={this.onFormChange}
            />
            <FormControl
              aria-label='Role Rate'
              type='number'
              name='rate'
              placeholder='Rate'
              style={{ marginBottom: '1rem' }}
              value={role?.rate || ''}
              onChange={this.onFormChange}
              min={0}
            />
          </Modal.Body>
          <Modal.Footer>
            <span className='actionButton' onClick={isNew ? this.saveRole : this.updateRole}>
              {isNew ? 'Save' : 'Update'}
            </span>
            <span className='actionButton-nopad' onClick={this.closeModal}>
              Close
            </span>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.app.roles,
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRole: (role) => dispatch(appAction.addRole(role)),
    deleteRole: (id) => dispatch(appAction.deleteRole(id)),
    updateRole: (role) => dispatch(appAction.updateRole(role)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
