import React, { Component } from 'react';
import { connect } from 'react-redux';
import Clients from 'Components/Settings/Clients';
import Roles from 'Components/Settings/Roles';
import UserSettings from './UserSettings';
import './Settings.scss';

class Settings extends Component {
  state = {
    view: 'clients',
  };

  getView = () => {
    switch (this.state.view) {
      case 'clients':
        return <Clients />;
      case 'roles':
        return <Roles />;
      case 'users':
        return <UserSettings />;
      default:
        return null;
    }
  };

  getStyle = (view) => {
    let style = {};
    if (view === this.state.view) style.color = 'rgb(98, 121, 149)';
    return style;
  };

  render() {
    return (
      <div className='settings'>
        <div className='settings__label'>
          <span onClick={() => this.setState({ view: 'clients' })} style={this.getStyle('clients')}>
            Clients
          </span>
          <span onClick={() => this.setState({ view: 'roles' })} style={this.getStyle('roles')}>
            Roles
          </span>
          <span onClick={() => this.setState({ view: 'users' })} style={this.getStyle('users')}>
            Reports
          </span>
        </div>
        <div className='body'>{this.getView()}</div>
      </div>
    );
  }
}

export default connect(null, null)(Settings);
