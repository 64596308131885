import React, { useCallback, useState } from 'react';
import DatePicker from 'react-date-picker';
import * as appAction from '../../actions/app.actions';
import { connect } from 'react-redux';
import toast from '../Toast/Toast';
import { saveAs } from 'file-saver';
import './style.scss';
import 'react-date-picker/dist/DatePicker.css';
import { User } from '../Settings/settings.definitions';
import { uxDate } from '../../util/dates';
import { calcHrs, round } from '../../util/numbers';

const getAmount = (data) => {
  const { rate = 0, tips, deduction, start, end } = data;
  const hrs = calcHrs(start, end, data.break) ?? 0;
  const amount = rate * Number(hrs) - (deduction ?? 0) + (tips ?? 0);
  return Number(round(amount) ?? 0);
};

const Payroll = (props) => {
  const { generatePayroll, fetchUsers, clients } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [users, setUsers] = useState<Array<User>>([]);

  const generatePayrollClick = useCallback(async () => {
    if (endDate < startDate) {
      return toast('End date can not be less then start date');
    }

    const shifts = await generatePayroll({ startDate, endDate });
    if (!shifts?.length) return toast('No shifts found');
    const usersSet = shifts.reduce((_allShifts, shift) => {
      if (shift?.userId) {
        _allShifts.add(shift?.userId);
      }
      return _allShifts;
    }, new Set());
    const userIds = Object.keys(users);
    const fetchUserIds = [...usersSet]?.filter((userId) => !userIds.includes(userId));
    const newUsers = await fetchUsers(fetchUserIds);
    const allUsers = newUsers?.reduce((_allUsers, user) => {
      _allUsers[user.id] = user;
      return _allUsers;
    }, users);

    const shiftsByStaff: Record<string, Array<never>> = shifts?.reduce((allShifts, shift) => {
      if (!shift.userId) return allShifts;
      if (allShifts[shift.userId]) {
        allShifts[shift.userId].push(shift);
      } else {
        allShifts[shift.userId] = [shift];
      }
      return allShifts;
    }, {} as Record<string, Array<never>>);

    let csvContent = 'Name, Hours, Total\r\n';

    let grandTotal = 0;

    for (const [key, value] of Object.entries(shiftsByStaff)) {
      const user = allUsers[key];
      if (!user) continue;

      const sortedShifts = value?.sort((a: any, b: any) => {
        const title = clients[a.clientId]?.name?.localeCompare(clients[b.clientId]?.name);
        const aDay = new Date(a?.start).getDate();
        const bDay = new Date(b?.start).getDate();
        if (aDay < bDay) return -1;
        if (aDay > bDay) return 1;
        return title;
      });

      csvContent += `${user.fName ?? ''} ${user.lName ?? ''}\r\n`;

      let total = 0;

      for (let i = 0; i < sortedShifts.length; i++) {
        const shift: any = sortedShifts[i];
        const amount = getAmount({
          rate: shift.rate,
          tips: shift.tips,
          deduction: shift.deduction,
          start: shift.start,
          end: shift.end,
        });
        total += amount;
        csvContent += `${clients[shift.clientId]?.name},${
          calcHrs(shift.start, shift.end, shift.break) || 0
        },$${amount}\r\n`;
      }

      csvContent += `SubTotal for ${user.fName},,$${total}\r\n\r\n`;
      grandTotal += total;
    }

    csvContent += `Total,,$${grandTotal}`;

    console.log(csvContent);

    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `Payrol@${uxDate(startDate)}-${uxDate(endDate)}`);

    setUsers(allUsers);
  }, [startDate, endDate, generatePayroll, users, fetchUsers, clients]);

  return (
    <div className='payroll_wrapper'>
      <div className='page_heading' style={{ marginBottom: '24px' }}>
        PAYROLL
      </div>
      <span>From: </span>
      <DatePicker
        onChange={(date: any) => {
          if (!date) return;
          const _date = new Date(date?.[0] ?? date);
          setStartDate(_date ?? new Date());
        }}
        value={startDate}
        view='month'
        calendarIcon={null}
        clearIcon={null}
      />
      <span>To: </span>
      <DatePicker
        onChange={(date) => {
          if (!date) return;
          const _date = new Date(date?.[0] ?? date);
          setEndDate(_date ?? new Date());
        }}
        value={endDate}
        view='month'
        calendarIcon={null}
        clearIcon={null}
      />
      <div>
        <span
          className='actionButton'
          style={{ margin: '24px 0 0 1px' }}
          onClick={generatePayrollClick}
        >
          GENERATE
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.app.clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generatePayroll: (props) => dispatch(appAction.generatePayroll(props)),
    fetchUsers: (ids) => dispatch(appAction.fetchUsers(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);
