const defaultState = {
  memos: [],
  dashpics: [],
  clients: [],
  shifts: [],
  user: {},
  users: [],
  roles: [],
  workflows: [],
  signoffs: [],
  birthdays: [],
  eventShifts: [],
  eventWorkflows: [],
  event: {},
};

const sortAlphabeticlly = (array, key) => {
  return array.sort((a, b) => a[key].localeCompare(b[key]));
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_MEMOS':
      return { ...state, memos: action.payload || [] };
    case 'ADD_MEMO': {
      return { ...state, memos: [...state.memos, action.payload] };
    }
    case 'DELETE_MEMO':
      return {
        ...state,
        memos: state.memos.filter((memo) => memo.id !== action.payload),
      };
    case 'FETCH_DASHPICS':
      return { ...state, dashpics: action.payload || [] };
    case 'ADD_DASHPIC': {
      return { ...state, dashpics: [...state.dashpics, action.payload] };
    }
    case 'UPDATE_DASHPIC': {
      return {
        ...state,
        dashpics: state.dashpics.map((p) => {
          if (p.id !== action.payload.id) return p;
          return action.payload;
        }),
      };
    }
    case 'DELETE_DASHPIC':
      return {
        ...state,
        dashpics: state.dashpics.filter((pic) => pic.id !== action.payload),
      };
    case 'FETCH_EVENT':
      return { ...state, event: action.payload };
    case 'FETCH_SHIFTS':
      return { ...state, shifts: action.payload || [] };
    case 'FETCH_WORKFLOWS':
      return { ...state, workflows: action.payload || [] };
    case 'FETCH_EVENT_SHIFTS':
      return { ...state, eventShifts: action.payload || [] };
    case 'FETCH_EVENT_WORKFLOWS':
      return { ...state, eventWorkflows: action.payload || [] };
    case 'ADD_WORKFLOW':
      return { ...state, workflows: [...state.workflows, action.payload] };
    case 'UPDATE_WORKFLOW':
      return {
        ...state,
        workflows: state.workflows.map((w) => {
          if (w.id !== action.payload.id) return w;
          return { ...w, ...action.payload };
        }),
      };
    case 'DELETE_WORKFLOW':
      return {
        ...state,
        workflows: state.workflows.filter((w) => w.id !== action.payload),
      };
    case 'ADD_SHIFT':
      const uniqueShifts = {};

      const updatedShifts = [...action.payload, ...state.shifts].flatMap((shift) => {
        if (uniqueShifts[shift?.id]) return [];
        uniqueShifts[shift?.id] = true;
        return [shift];
      });

      return { ...state, shifts: updatedShifts };
    case 'FETCH_SIGNOFFS':
      return { ...state, signoffs: action.payload };
    case 'DELETE_SHIFT':
      return {
        ...state,
        shifts: state.shifts.filter((s) => !action.payload.includes(s.id)),
      };
    case 'UPDATE_SHIFT':
      const { shift: shiftUpdated, shifts } = action.payload;
      if (shifts?.length) {
        const uniqueShifts = {};
        const updatedShifts = [...shifts, ...state.shifts, ...state.eventShifts].flatMap(
          (shift) => {
            if (uniqueShifts[shift?.id]) return [];
            uniqueShifts[shift?.id] = true;
            return [shift];
          }
        );

        return { ...state, shifts: updatedShifts, eventShifts: updatedShifts };
      }
      const updatedShiftsFunction = (shift) => {
        if (shift?.id !== shiftUpdated?.id) return shift;
        return { ...shift, ...shiftUpdated };
      };
      return {
        ...state,
        shifts: state.shifts.map(updatedShiftsFunction),
        eventShifts: state.eventShifts.map(updatedShiftsFunction),
      };
    case 'FETCH_USER':
      return { ...state, user: action.payload };
    case 'FETCH_ROLES':
      return { ...state, roles: sortAlphabeticlly(action.payload, 'name') };
    case 'ADD_ROLE':
      return { ...state, roles: sortAlphabeticlly([...state.roles, action.payload], 'name') };
    case 'UPDATE_ROLE':
      return {
        ...state,
        roles: sortAlphabeticlly(
          state.roles.map((role) => {
            if (role.id !== action.payload.id) return role;
            return { ...role, ...action.payload };
          }),
          'name'
        ),
      };
    case 'DELETE_ROLE':
      return {
        ...state,
        roles: sortAlphabeticlly(
          state.roles.filter((role) => role.id !== action.payload),
          'name'
        ),
      };
    case 'FETCH_CLIENTS':
      return { ...state, clients: sortAlphabeticlly(action.payload, 'name') };
    case 'ADD_CLIENT':
      return { ...state, clients: sortAlphabeticlly([...state.clients, action.payload], 'name') };
    case 'DELETE_CLIENT':
      return {
        ...state,
        clients: sortAlphabeticlly(
          state.clients.filter((client) => client.id !== action.payload),
          'name'
        ),
      };
    case 'UPDATE_CLIENT':
      return {
        ...state,
        clients: sortAlphabeticlly(
          state.clients.map((client) => {
            if (client.id !== action.payload.id) return client;
            return { ...client, ...action.payload };
          }),
          'name'
        ),
      };
    case 'ADD_USER':
      return { ...state, user: action.payload };
    case 'SET_EVENT':
      return { ...state, event: action.payload };
    case 'FETCH_ALL_USERS':
      return { ...state, users: action.payload };
    case 'UPDATE_USER_STAFF':
      return {
        ...state,
        users: state.users.map((u) => {
          if (u.id !== action.payload.id) return u;
          else return action.payload;
        }),
      };
    case 'DELETE_USER':
      return {
        ...state,
        users: state.users.filter((u) => u.id !== action.payload?.id),
      };
    case 'SET_BIRTHDAYS':
      return { ...state, birthdays: action.payload };
    default:
      return state;
  }
};

export default appReducer;
