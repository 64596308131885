import React, { useState, useEffect } from 'react';
import { LogoWB } from '../Logo';
import { Modal } from 'react-bootstrap';
import './Modals.scss';
import Checkbox from 'Components/Checkbox/Checkbox';
import policy from './pPolicy';
import { onSaveImage } from './modals.utils.ts';

export const PrivacyPolicyInfo = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} size='lg'>
      <div className='login popupYellow pPolicy'>
        <LogoWB />

        <div className='msgBox'>{policy}</div>

        <div style={{ textAlign: 'center' }}>
          <span className='login__action' onClick={props.onHide}>
            ok
          </span>
        </div>
      </div>
    </Modal>
  );
};

export const PrivacyPolicy = (props) => {
  return (
    <Modal show={props.show} onHide={() => {}} size='lg'>
      <div className='login popupYellow pPolicy'>
        <LogoWB />

        <div className='bold'>Welcome to the team!</div>
        <div>Before we get started, please read the following:</div>

        <div className='msgBox'>{policy}</div>

        <Checkbox required label='I have read all the Terms & Conditions and Privacy Policy.' />
        <Checkbox required label='I agree and accept the Terms & Conditions and Privacy Policy.' />

        <div style={{ textAlign: 'center' }}>
          <span className='login__action' onClick={props.onAgree}>
            enter
          </span>
        </div>
      </div>
    </Modal>
  );
};

export const originUrl = 'https://eventplusapp.s3.ca-central-1.amazonaws.com';
export const distributionUrl = 'https://d2xw3ms7rwn27s.cloudfront.net';

export const ViewDoc = (props) => {
  const { name, onFileSave, userDoc, viewDoc, onHide } = props;
  const docUrl = userDoc?.replace(originUrl, distributionUrl) || '';

  const [isImage, setIsImage] = useState(!!docUrl.match(/.(jpg|jpeg|png|gif)$/i));

  const [rotate, setRotate] = useState(0);

  function rotateRight() {
    if (rotate === 270) return setRotate(0);
    setRotate(rotate + 90);
  }

  useEffect(() => {
    const imageViewing = document.querySelector('#image_viewing');
    if (!imageViewing) return;
    const width = imageViewing.width + 'px';
    const height = imageViewing.height + 'px';
    imageViewing.style.width = height;
    imageViewing.style.height = width;
  }, [rotate]);

  function rotateLeft() {
    if (rotate === -270) return setRotate(0);
    setRotate(rotate - 90);
  }

  function onImageRotate() {
    onSaveImage({
      rotate,
      name,
      onFileSave,
      cssSelector: '#image_viewing',
      srcUrl: docUrl,
    });
  }

  useEffect(() => {
    (async () => {
      if (docUrl) {
        try {
          const res = await fetch(docUrl, { cors: 'no-cors' });
          setIsImage(!!res.headers.get('Content-Type').match(/image/i));
        } catch {
          setIsImage(!!docUrl.match(/.(jpg|jpeg|png|gif)$/i));
        }
      }
    })();
  }, [docUrl]);

  return (
    <Modal show={viewDoc} onHide={onHide} size='lg' dialogClassName='file_wrapper'>
      <button type='button' className='close closeButton closeButton_bg' onClick={onHide}>
        <span aria-hidden='true'>×</span>
        <span className='sr-only'>Close</span>
      </button>
      {isImage ? (
        <>
          <img
            alt='Viewing'
            id='image_viewing'
            src={docUrl}
            style={{
              minHeight: '50vh',
              maxHeight: '50vh',
              padding: '15px',
              transform: `rotate(
            ${rotate}deg)`,
            }}
          />
          <div className='rotation'>
            <span onClick={rotateLeft}>Rotate Left</span>
            <span onClick={rotateRight}>Rotate Right</span>
          </div>
        </>
      ) : (
        <iframe
          title='Viewing'
          type='application/pdf'
          src={docUrl}
          height='520'
          style={{ width: '50vw', padding: '15' }}
        />
      )}
      {rotate ? (
        <span onClick={onImageRotate} className='actionButton'>
          SAVE
        </span>
      ) : null}
    </Modal>
  );
};
