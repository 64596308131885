import * as appAction from 'Actions/app.actions';
import Autocomplete from 'Components/Autocomplete/Autocomplete';
import Checkbox from 'Components/Checkbox/Checkbox';
import Memo from 'Components/Memo/Memo';
import { distributionUrl, originUrl, ViewDoc } from 'Components/Modals/ProfileModals';
import React, { Component } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isAdmin, isManager } from 'Utils/auth';
import { getAge, uxDate } from 'Utils/dates';
import { formatPhone } from 'Utils/numbers';
import { v1 as uuid } from 'uuid';
import Msg from '../../components/Msg/Msg';
import { LogoWB } from '../Logo';
import { onSaveImage } from '../Modals/modals.utils';
import toast from '../Toast/Toast';
import { Uploader } from '../Uploader';
import { fieldNames, requiredFields } from './profile.definition';
import './Profile.scss';
import { getHeight, getShoeSize, languages, pChange } from './Utils';
import { SpinnerOverlay } from 'Components/Utils/Spinner';
import CalendarStaff from '../CalendarStaff/Calendar';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
const todayDate = new Date();

const DEFAULT_VIEW = 'decade';

const DEFAULT_ACTIVE_START_DAY = new Date(todayDate.getFullYear() - 35, 0, 1);

class Profile extends Component {
  state = {
    user: {},
    profile: this.props.staff || this.props.me,
    staff: !!this.props.staff,
    crop: {
      unit: '%',
      aspect: 1,
      width: '100',
      height: '100',
    },
    search: '',
    locationType: !!(this.props.staff || this.props.me).apt ? 'apartment' : 'house',
    range: {
      end: new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 7),
      start: todayDate,
    },
    workflows: [],
    rotate: 0,
    validate: false,
    isPasswordHidden: true,
    loading: false,
  };

  componentDidMount = async () => {
    this.setState({
      user: {
        ...this.state.user,
        interestRoles: this.state.profile.interestRoles || [],
        internalRoles: this.state.profile.internalRoles || [],
        internalNotes: this.state.profile.internalNotes || [],
        forbiddenVenues: this.state.profile.forbiddenVenues ?? [],
      },
    });
    if (this.state.staff) {
      window.addEventListener('popstate', this.props.action);
    }
  };

  onSave = async (e) => {
    if (!this.validate()) return;
    let user = { ...this.state.user, id: this.state.profile.id };
    if (user.location !== this.state.profile.location) {
      user.locationChanged = new Date();
    }
    await this.props.updateUser(user, this.state.staff).catch(console.error);
    toast('Profile is saved');
    this.setState({
      profile: {
        ...this.state.profile,
        ...user,
      },
      profileCompleted: true,
    });
  };

  validate = () => {
    this.setState({ validate: true });
    const fullUser = { ...this.state.profile, ...this.state.user };
    const errorFields = [];
    for (let i = 0, j = requiredFields.length; i < j; i++) {
      const field = requiredFields[i];
      if (!fullUser[field] && fieldNames[field]) {
        errorFields.push(fieldNames[requiredFields[i]]);
      }
    }
    if (errorFields.length) toast(`Please fill required fields: ${errorFields.join(', ')}`);
    return !errorFields.length;
  };

  onFieldChange = ({ currentTarget }) => {
    let name = currentTarget.getAttribute('name');
    this.setState({
      user: {
        ...this.state.user,
        [name]: currentTarget.value.toUpperCase(),
      },
    });
  };

  onFileUpload = async ({ currentTarget }) => {
    try {
      this.setState({ loading: true });
      const { email } = { ...this.state.profile, ...this.state.user };
      let location = await this.props.saveAttachment({
        file: currentTarget.files[0],
        name: email + currentTarget.files[0].name,
      });

      let name = currentTarget.getAttribute('name');
      this.setState({
        user: {
          ...this.state.user,
          [name]: location,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  removeFile = ({ currentTarget }) => {
    //Map names to actula ones
    let name = currentTarget.getAttribute('name');

    let check = window.confirm(`Are you sure you want to delete ${name}?`);
    if (!check) return;
    this.setState({
      user: {
        ...this.state.user,
        [name]: null,
      },
    });
  };

  removeInputFile = ({ currentTarget }) => {
    let name = currentTarget.getAttribute('name');
    let uploader = document.querySelector(`input[name="${name}"]`);
    uploader.value = '';
    if (!/safari/i.test(navigator.userAgent)) {
      uploader.type = '';
      uploader.type = 'file';
    }
  };

  makeFileUploader = (name) => {
    let user = { ...this.state.profile, ...this.state.user };
    const isManager = this.isManagerView();
    if (user[name]) {
      return (
        <div>
          <span
            onClick={() => this.setState({ viewDoc: user[name], viewDocName: name })}
            className='docLink'
          >
            View
          </span>
          {isManager && (
            <button
              type='button'
              className='close profile_picture_close'
              onClick={this.removeFile}
              name={name}
            >
              <span aria-hidden='true'>×</span>
              <span className='sr-only'>Close</span>
            </button>
          )}
        </div>
      );
    }
    return isManager && <Uploader onFileUpload={this.onFileUpload} name={name} />;
  };

  makeInput = (label, name, style = {}, disabled) => {
    const user = { ...this.state.profile, ...this.state.user };
    const shouldValidate = this.state.validate;
    const isManager = this.isManagerView();
    let value = user[name] || '';
    if (name === 'secondLanguage' && languages.includes(user[name])) {
      value = '';
    }
    if ((name === 'phone' || name === 'emConNum') && value) {
      value = formatPhone(value);
    }
    if (shouldValidate && requiredFields.includes(name) && !value) {
      style.borderColor = 'red';
    }

    return (
      <input
        id={'input_' + name}
        placeholder={label}
        value={value || ''}
        name={name}
        onChange={this.onFieldChange}
        className='inputFormatted'
        aria-label={'input_' + name}
        style={style}
        disabled={!isManager || disabled}
        readOnly={!isManager || disabled}
      />
    );
  };

  onStatusChange = async (status) => {
    let check = window.confirm('Do you want change status of this profile?');
    if (!check) return;
    const fullUser = { ...this.state.profile, ...this.state.user };
    let user = { status, id: this.state.profile.id, hiredBy: fullUser.hiredBy };
    const updatedUser = await this.props.updateUser(user, this.state.staff).catch(console.error);
    this.setState({
      profile: {
        ...this.state.profile,
        ...updatedUser,
      },
    });
    toast(`Status has been changed to ${status?.toUpperCase()}`);
  };

  onSelectChange = async ({ currentTarget }) => {
    let value = currentTarget.value;
    let name = currentTarget.getAttribute('name');
    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  };

  getProfilePic = (url = null) => {
    const isManager = this.isManagerView();
    return (
      <div style={{ display: 'flex' }}>
        {url ? (
          <img
            alt='img_profile'
            src={url?.replace(originUrl, distributionUrl)}
            style={{ width: '120px' }}
            onClick={() =>
              this.setState({
                avatarSrc: url?.replace(originUrl, distributionUrl),
              })
            }
            name='image'
          />
        ) : (
          <span className='profilePlaceholder' />
        )}
        <div style={{ display: 'grid', gridGap: '5px' }}>
          {url && (
            <div
              className='actionButton'
              onClick={() =>
                this.setState({
                  avatarSrc: url?.replace(originUrl, distributionUrl),
                })
              }
            >
              VIEW PHOTO
            </div>
          )}
          {isManager && (
            <>
              {url && (
                <div onClick={this.removeFile} className='actionButton' name='image'>
                  DELETE PHOTO
                </div>
              )}
              <Uploader onFileUpload={this.onFileUpload} name='image' label={'UPLOAD PHOTO'} />
            </>
          )}
        </div>
      </div>
    );
  };

  saveAvatar = async (e) => {
    const { crop, rotate, avatarSrc } = this.state;
    onSaveImage({
      rotate,
      name: 'image',
      onFileSave: (props) => {
        this.onFileUpload(props);
        this.setState({ avatarSrc: null, rotate: 0 });
      },
      cssSelector: '.ReactCrop__image',
      srcUrl: avatarSrc,
      crop: rotate ? null : crop,
    });
  };

  onCheckboxChange = ({ currentTarget }) => {
    let checked = currentTarget.checked;
    let name = currentTarget.getAttribute('name');
    let arr = [...(this.state.user[name] || [])];

    if (checked) {
      this.setState({
        user: {
          ...this.state.user,
          [name]: [...arr, currentTarget.value],
        },
      });
    } else {
      this.setState({
        user: {
          ...this.state.user,
          [name]: arr.filter((r) => r !== currentTarget.value),
        },
      });
    }
  };

  onPasswordChnange = () => {
    if (!pChange()) return;
    let p = document.querySelector('input[name="password"]').value;
    let cPassword = document.querySelector('input[name="cPassword"]').value;
    if (!cPassword) return toast('fill in current password');
    this.props
      .updateUser({ password: p, id: this.state.profile.id, cPassword })
      .then(() => {
        this.setState({ passwordModule: false });
        toast('Password changed');
      })
      .catch((e) => toast(e.error));
  };

  getCheckBoxes = (roles = [], name) => {
    let user = { ...this.state.profile, ...this.state.user };
    const isManager = this.isManagerView();
    return roles.map((r) => (
      <li key={r.id}>
        <Checkbox
          value={r.name}
          name={name}
          label={r.name}
          checked={(user[name] || []).includes(r.name)}
          onChange={this.onCheckboxChange}
          disabled={!isManager}
          readOnly={!isManager}
        />
      </li>
    ));
  };

  decline = () => {
    let user = {
      id: this.state.profile.id,
      status: 'declined',
      reason: this.declineComment.value,
    };
    this.props.updateUser(user, this.state.staff).catch(console.error);
    this.setState({ decline: null });
  };

  getAvailableJobs = () =>
    (this.state.jobApply || []).map((s) => (
      <option key={s.id} value={s.id}>
        {s.role} @ {s.venue} -{' '}
        {new Date(s.start).toLocaleDateString('en-ca', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </option>
    ));

  onJobApply = async () => {
    let value = document.querySelector('select[name="applyJob"]').value;
    if (!value) return;
    let shift = this.state.jobApply.filter((j) => j.id === value)[0];
    let staff = this.state.profile;
    shift.userId = staff.id;
    try {
      await this.props.approve(shift);
      this.setState({
        jobApply: this.state.jobApply.filter((j) => j.id !== value),
      });
      toast('Successfully added staff to job');
    } catch (error) {
      console.error(error);
      toast('There was a issue to assing this job to staff');
    }
  };

  addNote = () => {
    let title = document.querySelector('textarea[name="createNote"]').value.trim();
    if (!title.length) {
      toast(`Note can't be empty`);
      return;
    }
    let arr = [...(this.state.user.internalNotes || [])];
    let user = this.props.me;
    this.setState({
      user: {
        ...this.state.user,
        internalNotes: [
          ...arr,
          {
            title,
            createdBy: `${user.fName || ''} ${user.lName || ''}`,
            createdAt: new Date(),
            id: uuid(),
          },
        ],
      },
      createNote: null,
    });
  };

  deleteNote = (id) => {
    this.setState({
      user: {
        ...this.state.user,
        internalNotes: this.state.user.internalNotes.filter((n) => n.id !== id),
      },
    });
  };

  isManagerView = () => isManager(this.props.me.role);

  isAdminView = () => isAdmin(this.props.me.role);

  isMyself = () => this.props.me.id === this.state.profile.id;

  roleSorting = (roleA, roleB) => {
    const [firstA, secondA] = roleA?.refId?.split('');
    const [firstB, secondB] = roleB?.refId?.split('');
    if (firstA > firstB) return 1;
    if (firstA < firstB) return -1;

    if (secondA > secondB) return 1;
    return -1;
  };

  formatRolesByLetter = (roles) => {
    return roles.reduce((roleObj, role) => {
      const firstLetter = role.refId[0];
      if (!roleObj[firstLetter]) roleObj[firstLetter] = [role];
      else roleObj[firstLetter].push(role);
      return roleObj;
    }, {});
  };

  deleteProfile = async () => {
    let check = window.confirm(`Are you sure you want to delete this profile?`);
    if (!check) return;
    await this.props.deleteProfile(this.state.profile.id);
    toast('Profile is deleted');
    this.props?.action();
  };

  rotateRight = () => {
    if (this.state.rotate === 270) return this.setState({ rotate: 0 });
    this.setState({ rotate: this.state.rotate + 90 });
  };

  rotateLeft = () => {
    if (this.state.rotate === -270) return this.setState({ rotate: 0 });
    this.setState({ rotate: this.state.rotate - 90 });
  };

  onWorkflowClick = (slotId) => {
    window.open(`/events/${slotId}`);
  };

  onRequestChanges = () => this.setState({ requestChange: true });
  closeRequestChanges = () => this.setState({ requestChange: false });

  onShowHide = () => this.setState({ isPasswordHidden: !this.state.isPasswordHidden });

  onScheduledShifts = () => {
    this.setState({
      scheduledShifts: true,
    });
  };

  render() {
    const user = { ...this.state.profile, ...this.state.user };
    let userDoc = this.state.viewDoc;
    const rotate = this.state.rotate;
    const isAction = typeof this.props.action === 'function';
    if (userDoc && userDoc.split('.').pop().match('docs|docx|doc'))
      userDoc = `https://view.officeapps.live.com/op/embed.aspx?src=${userDoc}`;
    const formatedRoles = this.formatRolesByLetter(this.props.roles.sort(this.roleSorting));
    const formatedRolesKeys = Object.keys(formatedRoles);
    const isManager = this.isManagerView();
    const { requestChange, isPasswordHidden, loading } = this.state;
    const { clients } = this.props;
    return (
      <>
        {loading && <SpinnerOverlay />}
        <div className='page_heading'>{this.isMyself() ? 'MY' : 'STAFF'} PROFILE:</div>

        <div className='profile-staff_info'>
          <div className='label-med profile-staff_name'>
            {user.fName ?? ''} {user.lName ?? ''}
          </div>
          <div className='profile-registered_on'>Registered on: {uxDate(user.registerAt)}</div>
          <div className='profile-last_logon'>Last log in: {uxDate(user.lastLogOn)}</div>
        </div>
        <div className='profile_divider' />
        {isAction && (
          <span className='profile-back_button' onClick={this.props.action}>
            BACK TO MENU
          </span>
        )}
        <Form.Group as={Row} className='minWidth'>
          <Col className='inline'>
            <div className='profileSection'>
              <div>{this.getProfilePic(user.image)}</div>
              <div className='gridAction'>
                {this.isMyself() ? (
                  <span
                    className='actionButton'
                    onClick={() => this.setState({ passwordModule: true })}
                  >
                    CHANGE PASSWORD
                  </span>
                ) : null}
                {isManager ? (
                  <>
                    <span onClick={this.onSave} className='actionButton'>
                      SAVE CHANGES
                    </span>
                  </>
                ) : (
                  <span className='actionButton' onClick={this.onRequestChanges}>
                    REQUEST CHANGES
                  </span>
                )}
                {user?.employeeNumber &&
                  this.makeInput(
                    'Employee #',
                    'employeeNumber',
                    {
                      maxWidth: '250px',
                      width: 'auto',
                    },
                    true
                  )}
                {this.isAdminView() && (
                  <span onClick={this.deleteProfile} className='actionButton deleteColor'>
                    DELETE PROFILE
                  </span>
                )}
              </div>
            </div>
            <div>
              <div className='label label-med'>PERSONAL INFORMATION</div>
              <div className='flexDisplay'>
                {this.makeInput('First Name', 'fName')}
                {this.makeInput('Last Name', 'lName')}
              </div>
              <div className='flexDisplay'>
                {this.makeInput('Preferred Name', 'pName')}
                {this.makeInput('Phone', 'phone')}
              </div>
              <div className='flexDisplay'>
                <div className='gender'>
                  <label htmlFor='input_gender' className='label-small' style={{ float: 'left' }}>
                    I identify as:
                  </label>
                  <Form.Control
                    id='input_gender'
                    as='select'
                    defaultValue={user.gender}
                    name='gender'
                    onChange={this.onSelectChange}
                    disabled={!isManager}
                    readOnly={!isManager}
                  >
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                    <option value='other'>Rather not say</option>
                  </Form.Control>
                </div>
                {this.makeInput('Email', 'email')}
              </div>
              <div className='flexDisplay halfWidth'>
                <span className='flexText'>Birthday:</span>
                <DatePicker
                  onChange={(dob) => {
                    console.log(dob);
                    const initialDate = new Date(dob);
                    if (isNaN(initialDate.getTime())) return;
                    this.setState({
                      user: {
                        ...user,
                        dob,
                      },
                    });
                  }}
                  value={user.dob ? new Date(user.dob) : null}
                  defaultView={DEFAULT_VIEW}
                  calendarIcon={null}
                  clearIcon={null}
                  yearPlaceholder='YYYY'
                  dayPlaceholder='DD'
                  monthPlaceholder='MM'
                  defaultActiveStartDate={DEFAULT_ACTIVE_START_DAY}
                  showDoubleView
                  dayAriaLabel='Day'
                  monthAriaLabel='Month'
                  yearAriaLabel='Year'
                  nativeInputAriaLabel='Date'
                  disabled={!isManager}
                  readOnly={!isManager}
                  format='dd/MM/y'
                />

                <input
                  value={user.dob ? getAge(user.dob) + ' years' : ''}
                  style={{ maxWidth: '70px' }}
                  readOnly
                  disabled
                  className='inputFormatted'
                  aria-label='date of birth'
                  placeholder='age'
                />
              </div>
              <div>
                <label htmlFor='input_mailing_address'>Mailing Address:</label>
                <div className='locationType'>
                  <Checkbox
                    value='apartment'
                    label='Apartment'
                    checked={
                      this.state.locationType === 'apartment' ||
                      (!this.state.locationType && !!user.apt)
                    }
                    onChange={({ currentTarget }) =>
                      this.setState({ locationType: currentTarget.value })
                    }
                    disabled={!isManager}
                    readOnly={!isManager}
                  />
                  <Checkbox
                    value='house'
                    label='House'
                    checked={
                      this.state.locationType === 'house' || (!this.state.locationType && !user.apt)
                    }
                    onChange={({ currentTarget }) =>
                      this.setState({ locationType: currentTarget.value })
                    }
                    disabled={!isManager}
                    readOnly={!isManager}
                  />
                </div>
              </div>
              {this.state.locationType === 'apartment' || (!this.state.locationType && user.apt)
                ? this.makeInput('Apt/Unit Number', 'apt')
                : null}

              <div className='location'>
                <Autocomplete
                  id='input_mailing_address'
                  value={this.state.profile['location']}
                  setAddress={(addr, latLng) =>
                    this.setState({
                      user: {
                        ...this.state.user,
                        location: addr,
                        lat: latLng.lat,
                        lng: latLng.lng,
                      },
                    })
                  }
                  placeholder={
                    this.state.locationType === 'apartment' ? 'Address:' : 'House Address:'
                  }
                  disabled={!isManager}
                  readOnly={!isManager}
                />
              </div>
              <div className='whiteLine' style={{ minHeight: '6px', marginTop: '2%' }} />

              <div className='flexDisplay halfWidth'>
                <label htmlFor='input_driver_license' className='flexText'>
                  Drivers License:
                </label>
                <Form.Control
                  id='input_driver_license'
                  as='select'
                  defaultValue={user.dLicense === 'yes' ? 'yes' : 'no'}
                  name='dLicense'
                  onChange={this.onSelectChange}
                  disabled={!isManager}
                  readOnly={!isManager}
                >
                  <option value='yes'>Yes</option>
                  <option value='no'>No</option>
                </Form.Control>
              </div>

              <div className='flexDisplay halfWidth'>
                <label htmlFor='input_car_access' className='flexText'>
                  Car Access:
                </label>
                <Form.Control
                  id='input_car_access'
                  as='select'
                  defaultValue={user.carAccess === 'yes' ? 'yes' : 'no'}
                  name='carAccess'
                  onChange={this.onSelectChange}
                  disabled={!isManager}
                  readOnly={!isManager}
                >
                  <option value={'yes'}>Yes</option>
                  <option value={'no'}>No</option>
                </Form.Control>
              </div>

              <div className='flexDisplay'>
                <div className='flexDisplay halfWidth' style={{ display: 'inline-flex' }}>
                  <label htmlFor='input_secong_language' className='flexText'>
                    2nd Language:
                  </label>
                  <Form.Control
                    id='input_secong_language'
                    as='select'
                    value={
                      !user.secondLanguage || !languages.includes(user.secondLanguage)
                        ? ''
                        : user.secondLanguage
                    }
                    name='secondLanguage'
                    onChange={this.onSelectChange}
                    disabled={!isManager}
                    readOnly={!isManager}
                  >
                    {!user.secondLanguage || !languages.includes(user.secondLanguage) ? (
                      <option value=''>Please select</option>
                    ) : null}
                    {languages.map((l, i) => (
                      <option value={l} key={i}>
                        {l}
                      </option>
                    ))}
                  </Form.Control>
                </div>

                {this.makeInput('Fill in for other', 'secondLanguage')}
              </div>

              <div className='flexDisplay'>
                <div className='flexDisplay halfWidth' style={{ display: 'inline-flex' }}>
                  <label htmlFor='input_sign_language' className='flexText'>
                    Sign Language:
                  </label>
                  <Form.Control
                    id='input_sign_language'
                    as='select'
                    defaultValue={user.signLanguage === 'yes' ? 'yes' : 'no'}
                    name='signLanguage'
                    onChange={this.onSelectChange}
                    disabled={!isManager}
                    readOnly={!isManager}
                  >
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </Form.Control>
                </div>
              </div>
            </div>
            <div>
              <div className='label label-med'>UNIFORM SIZE</div>
              <div className='flexDisplay halfWidth'>
                <label htmlFor='input_height' className='flexText'>
                  Height:
                </label>
                <Form.Control
                  id='input_height'
                  as='select'
                  defaultValue={user.height || ''}
                  name='height'
                  onChange={this.onSelectChange}
                  disabled={!isManager}
                  readOnly={!isManager}
                >
                  <option key="4'11" value="4'11">
                    Under 5
                  </option>
                  <option key='5' value='5'>
                    5
                  </option>
                  {getHeight()}
                  <option key='6' value={'6'}>
                    6
                  </option>
                  <option key="6'1" value={"6'1"}>
                    Over 6
                  </option>
                </Form.Control>
              </div>
              <div className='flexDisplay halfWidth'>
                <label htmlFor='input_shoe_size' className='flexText'>
                  Shoe Size:
                </label>
                <Form.Control
                  id='input_shoe_size'
                  as='select'
                  defaultValue={user.shoeSize || ''}
                  name={'shoeSize'}
                  onChange={this.onSelectChange}
                  disabled={!isManager}
                  readOnly={!isManager}
                >
                  {getShoeSize()}
                </Form.Control>
              </div>
              <div className='flexDisplay halfWidth'>
                <label htmlFor='input_shirt_size' className='flexText'>
                  Shirt Size:
                </label>
                <Form.Control
                  id='input_shirt_size'
                  as='select'
                  defaultValue={user.shirtSize || ''}
                  name={'shirtSize'}
                  onChange={this.onSelectChange}
                  disabled={!isManager}
                  readOnly={!isManager}
                >
                  <option value={'xs'}>XS</option>
                  <option value={'s'}>S</option>
                  <option value={'m'}>M</option>
                  <option value={'l'}>L</option>
                  <option value={'xl'}>XL</option>
                  <option value={'xxl'}>XXL</option>
                </Form.Control>
              </div>
            </div>
            <div>
              <fieldset>
                <legend className='label label-med'>JOB ROLES INTERESTED IN:</legend>
                <ol className='roleColumns'>
                  {this.getCheckBoxes(this.props.roles, 'interestRoles')}
                </ol>
              </fieldset>
            </div>
            <div>
              <div className='label label-med'>EMERGENCY CONTACT:</div>
              <div className='flexDisplay'>
                {this.makeInput('Emergency Contact Person', 'emConPer')}
              </div>
              <div className='flexDisplay'>{this.makeInput('Emergency Relationship', 'emRel')}</div>
              <div className='flexDisplay'>
                {this.makeInput('Emergency Contact Number', 'emConNum')}
              </div>
            </div>
          </Col>
          <Col className='inline'>
            {isManager && (
              <div className='box-man' style={{ marginBottom: '1em' }}>
                <div className='label-med profile-staff_name'>MANAGEMENT USE ONLY</div>
                <div className='profile_divider' />
                <div>
                  <div className='profile_management'>
                    <div>Current status: {user?.status?.toUpperCase()}</div>
                    <div
                      className='actionButton pending_users'
                      onClick={() => this.onStatusChange('pending')}
                    >
                      PENDING
                    </div>
                    <div
                      className='actionButton active_users'
                      onClick={() => this.onStatusChange('active')}
                    >
                      ACTIVATE
                    </div>
                    <div
                      className='actionButton declined_users'
                      onClick={() => this.onStatusChange('declined')}
                    >
                      DECLINE
                    </div>
                    <div>{this.makeInput('REVIEWED BY:', 'reviewedBy')}</div>
                    <div>{this.makeInput('INTERVIEWED BY:', 'interviewedBy')}</div>
                    <div>{this.makeInput('APPLICATION METHOD:', 'appMethod')}</div>
                  </div>
                  <div style={{ display: 'inline', paddingLeft: '16px' }}>
                    <label htmlFor='input_hiredBy' className='flexText'>
                      Hired By:
                    </label>
                    <Form.Control
                      id='input_hiredBy'
                      as='select'
                      defaultValue={user.hiredBy || ''}
                      name={'hiredBy'}
                      onChange={this.onSelectChange}
                    >
                      <option value={'EPM'}>EPM</option>
                      <option value={'BONG'}>BONG</option>
                      <option value={'ABDUL'}>ABDUL</option>
                      <option value={'KIEN'}>KIEN</option>
                      <option value={'ALBERT'}>ALBERT</option>
                      <option value={'JASMINE'}>JASMINE</option>
                      <option value={'LUZ'}>LUZ</option>
                    </Form.Control>
                  </div>
                </div>
                <div className='profile_divider' />
                <div className='actionButton active_users' onClick={this.onScheduledShifts}>
                  SEE SCHEDULED SHIFTS
                </div>
                <div className='profile_divider' />
                <div>NOTES:</div>
                {user.internalNotes
                  ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((m) => (
                    <Memo
                      key={m.id}
                      m={m}
                      user={this.props.me}
                      deleteMemo={this.isAdminView() ? this.deleteNote : null}
                    />
                  ))}
                <span className='actionButton' onClick={() => this.setState({ createNote: true })}>
                  Add Note
                </span>
                <div className='profile_divider' />
                <div className='profile_manager_access'>
                  <div>
                    <div>ROLES QUALIFY FOR:</div>
                    <div style={{ listStyleType: 'none' }}>
                      {formatedRolesKeys?.map((key, index) => (
                        <div key={key}>
                          {this.getCheckBoxes(formatedRoles[key], 'internalRoles')}
                          {!!formatedRoles[key]?.length &&
                            formatedRolesKeys.length - 1 !== index && (
                              <div className='profile_divider' key={key} />
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div>VENUES NOT APPROVED FOR:</div>
                    <div style={{ listStyleType: 'none' }}>
                      {clients
                        ?.sort((clientA, clientB) => clientA.name.localeCompare(clientB.name))
                        ?.map((client) => (
                          <Checkbox
                            key={client.id}
                            id={client.id}
                            value={client.id}
                            name={'forbiddenVenues'}
                            label={client.name}
                            checked={!!user?.forbiddenVenues?.includes(client.id)}
                            onChange={this.onCheckboxChange}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className=''>
              <div className='flexDisplay'>
                <span>First Aid/CPR</span>
                {this.makeFileUploader('aid')}
              </div>

              <div className='profile_divider' />

              <div className='flexDisplay'>
                <span>Work Health & Safety</span>
                {this.makeFileUploader('whsa')}
              </div>

              <div className='flexDisplay'>
                <span>WHMIS/GHS</span>
                {this.makeFileUploader('whmis')}
              </div>

              <div className='profile_divider' />

              <div className='flexDisplay'>
                <span>Basic Food Handler</span>
                {this.makeFileUploader('foodHandler')}
              </div>
              <div className='flexDisplay'>
                <span>Food Safety Handler</span>
                {this.makeFileUploader('foodSafety')}
              </div>

              <div className='profile_divider' />

              <div className='flexDisplay'>
                <label htmlFor='input_ssn' style={{ width: '52%' }}>
                  Smart Serve #
                </label>
                {this.makeInput('put certificate # here', 'ssn', {
                  marginLeft: 'auto',
                  width: 150,
                })}
                <br />
              </div>

              <div className='flexDisplay'>
                <span>Smart Serve Card</span>
                {this.makeFileUploader('ssc')}
              </div>

              <div className='flexDisplay'>
                <span>Smart Serve Card Date Issued</span>
                <DatePicker
                  onChange={(sscIssued) => {
                    const initialDate = new Date(sscIssued);
                    if (isNaN(initialDate.getTime())) return;

                    this.setState({
                      user: {
                        ...this.state.user,
                        sscIssued,
                      },
                    });
                  }}
                  value={user.sscIssued ? new Date(user.sscIssued) : null}
                  calendarIcon={null}
                  clearIcon={null}
                  yearPlaceholder='YYYY'
                  dayPlaceholder='DD'
                  monthPlaceholder='MM'
                  defaultActiveStartDate={new Date()}
                  dayAriaLabel='Day'
                  monthAriaLabel='Month'
                  yearAriaLabel='Year'
                  nativeInputAriaLabel='Date'
                  disabled={!isManager}
                  readOnly={!isManager}
                  format='dd/MM/y'
                />
              </div>

              <div className='profile_divider' />

              <div className='flexDisplay'>
                <span>Resume</span>
                {this.makeFileUploader('resume')}
              </div>
              {isManager && (
                <>
                  <div className='flexDisplay'>
                    <span>Goverment Id</span>
                    {this.makeFileUploader('govId')}
                  </div>
                  <div className='flexDisplay'>
                    <span>TD1</span>
                    {this.makeFileUploader('td1')}
                  </div>
                </>
              )}
              <div className='profile_divider' />
              <div className='flexDisplay'>
                <span>Proof of vaccination</span>
                {this.makeFileUploader('vaccine')}
              </div>
              <div className='profile_divider' />
              <div className='flexDisplay'>
                <span>Void check</span>
                {this.makeFileUploader('voidCheck')}
              </div>
            </div>
          </Col>
        </Form.Group>
        <ViewDoc
          onHide={() => this.setState({ viewDoc: null })}
          viewDoc={this.state.viewDoc}
          userDoc={userDoc}
          onFileSave={this.onFileUpload}
          name={this.state.viewDocName}
        />
        <Modal
          show={!!this.state.avatarSrc}
          onHide={() => this.setState({ avatarSrc: null })}
          backdrop='static'
        >
          <Modal.Header closeButton></Modal.Header>
          <ReactCrop
            crossorigin={null} //Don't Remove!!!
            src={this.state.avatarSrc}
            crop={this.state.crop}
            onChange={(newCrop) => this.setState({ crop: newCrop })}
            keepSelection
            imageStyle={{
              color: 'red!important',
              transform: `rotate(
            ${rotate}deg)`,
            }}
            className={!!rotate ? 'disableCrop' : ''}
          />
          {isManager && (
            <>
              <div className='rotation'>
                <span onClick={this.rotateLeft}>Rotate Left</span>
                <span onClick={this.rotateRight}>Rotate Right</span>
              </div>
              <span
                onClick={this.saveAvatar}
                style={{ alignSelf: 'center' }}
                className='actionButton'
              >
                Save {!!rotate ? 'rotation' : 'resizing'}
              </span>
            </>
          )}
        </Modal>
        <Modal
          show={!!this.state.passwordModule}
          onHide={() => this.setState({ passwordModule: false })}
        >
          <div className='login'>
            <LogoWB />

            <label htmlFor='input_current_password' className='bold'>
              CURRENT PASSWORD:
            </label>
            <input
              id='input_current_password'
              type={isPasswordHidden ? 'password' : 'text'}
              placeholder='6 characters with uppercase, lowercase and number'
              name='cPassword'
              autoComplete='off'
            />

            <label htmlFor='input_new_password' className='bold'>
              NEW PASSWORD:
            </label>
            <input
              id='input_new_password'
              type={isPasswordHidden ? 'password' : 'text'}
              placeholder='6 characters with uppercase, lowercase and number'
              name='password'
              autoComplete='off'
            />

            <label htmlFor='input_confirm_new_password' className='bold'>
              CONFIRM NEW PASSWORD:
            </label>
            <input
              id='input_confirm_new_password'
              type={isPasswordHidden ? 'password' : 'text'}
              placeholder='6 characters with uppercase, lowercase and number'
              name='sPassword'
              autoComplete='off'
            />
            <div style={{ textAlign: 'center' }}>
              <span className='login__action' onClick={this.onPasswordChnange}>
                change
              </span>
              <span
                className={'login__action'}
                style={{ marginLeft: '16px' }}
                onClick={this.onShowHide}
              >
                {isPasswordHidden ? 'show all' : 'hide all'}
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          size='sm'
          show={!!this.state.decline}
          onHide={() => this.setState({ decline: null })}
        >
          <Modal.Header closeButton></Modal.Header>
          {
            //TODO change button
          }
          <Modal.Body>
            <Form.Control as='textarea' ref={(e) => (this.declineComment = e)} rows='3' />
            <Button
              onClick={this.decline}
              disabled={
                this.declineComment &&
                this.declineComment.value &&
                this.declineComment.value.length > 0
              }
            >
              Decline
            </Button>
          </Modal.Body>
        </Modal>
        <Modal
          size='lg'
          show={!!this.state.jobApply}
          onHide={() => this.setState({ jobApply: null })}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {this.state.jobApply && this.state.jobApply.length > 0 ? (
              <div>
                <Form.Control as='select' name={'applyJob'} onChange={this.onJobSelect}>
                  {this.getAvailableJobs()}
                </Form.Control>
                <br />
                <span className='actionButton' onClick={this.onJobApply}>
                  Apply
                </span>
              </div>
            ) : (
              <span>No Jobs to apply</span>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={!!this.state.createNote} onHide={() => this.setState({ createNote: null })}>
          <Modal.Header closeButton> Add new Note</Modal.Header>
          <Modal.Body>
            <Form.Control as='textarea' name='createNote'></Form.Control>
            <br />
            <span className='actionButton' onClick={this.addNote}>
              Add Note
            </span>
          </Modal.Body>
        </Modal>

        <Modal
          show={!!this.state.scheduledShifts}
          onHide={() => this.setState({ scheduledShifts: false })}
          size='lg'
        >
          <Modal.Header closeButton> Scheduled Shifts</Modal.Header>
          <div style={{ padding: 16 }}>
            <CalendarStaff userId={user.id} isManagerView={true} />
          </div>
        </Modal>

        {requestChange ? (
          <Msg
            action={this.closeRequestChanges}
            email={{ to: 'hr@eventspm.ca', subject: `Request changes ${user?.email ?? ''}` }}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.app.user,
    roles: state.app.roles,
    clients: state.app.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(appAction, dispatch),
    updateUser: (user, staff) => dispatch(appAction.updateUser(user, staff)),
    saveAttachment: (file, bucket, folder, asl) =>
      dispatch(appAction.saveAttachment(file, bucket, folder, asl)),
    approve: (shift) => dispatch(appAction.approve(null, shift)),
    deleteProfile: (id) => dispatch(appAction.deleteProfile(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
