import * as appAction from '../../actions/app.actions';
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isAdmin, isManager } from '../../util/auth';
import download from '../../images/download.jpg';
import imagePlaceholder from '../../images/placeholderNew.png';
import toast from '../Toast/Toast';
import { Uploader } from '../Uploader';

const isDocument = (url) => url.split('.').pop().match('pdf|docs|docx|doc|mp4');

const MyFiles = (props: any) => {
  const { fetchDashPics, saveDashPic, deleteDashPic, updateDashPic, pics, user } = props;
  const [viewDoc, setViewDoc] = useState('');

  useEffect(() => {
    fetchDashPics();
  }, [fetchDashPics]);

  const onFileUpload = ({ currentTarget }) => {
    saveDashPic({
      file: currentTarget.files[0],
      name: currentTarget.files[0].name,
    })
      .then(() => (currentTarget.value = ''))
      .catch(console.error);
  };

  const onFileUploadImportant = ({ currentTarget }) => {
    saveDashPic({
      file: currentTarget.files[0],
      name: currentTarget.files[0].name,
      important: true,
    })
      .then(() => (currentTarget.value = ''))
      .catch(console.error);
  };

  const onDeleteDashPic = (id) => {
    let check = window.confirm('Are you sure you want to delete this File?');
    if (!check) return;
    deleteDashPic(id);
    toast('Deleted!');
  };

  const onTitleChange = (e, id) => {
    if (e.currentTarget.innerText === (pics.filter((p) => p.id === id)[0].title || '')) return;
    updateDashPic({ id, title: e.currentTarget.innerText });
    toast('Saved!');
  };

  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  const onHidePic = () => setViewDoc('');

  const imagesSorted = useMemo(
    () =>
      pics.sort(
        (a, b) =>
          new Date(b.updated || new Date()).getTime() - new Date(a.updated || new Date()).getTime()
      ),
    [pics]
  );

  const importantImages = useMemo(
    () => imagesSorted.filter((image) => image.important),
    [imagesSorted]
  );
  const images = useMemo(() => imagesSorted.filter((image) => !image.important), [imagesSorted]);

  const isManagerRole = useMemo(() => isManager(user.role), [user]);

  const userDoc = useMemo(() => {
    if (viewDoc?.split('.')?.pop()?.match('docs|docx|doc')) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${viewDoc}`;
    }
    return viewDoc;
  }, [viewDoc]);

  return (
    <div>
      <div className='page_heading'>My Files</div>
      <div className='profile_divider' />
      <Row>
        <Col>
          <img alt='image_download' src={download} style={{ height: '200px' }} />
          <div className='label'>Information Corner:</div>
          <div className='info'>
            Access and download all the files in this section to get all the information you need as
            a team member.
          </div>
          <div className='images images_important'>
            {importantImages.map((image, i) => (
              <div key={i}>
                <img
                  alt={'image_' + image.title}
                  src={isDocument(image.url) ? imagePlaceholder : image.url}
                  onClick={() => setViewDoc(image.url)}
                />
                {isAdmin(user.role) ? (
                  <button
                    type='button'
                    className='close closeButton closeButton_card'
                    onClick={() => onDeleteDashPic(image.id)}
                    style={{ paddingTop: '0px!important' }}
                  >
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                  </button>
                ) : null}
                <br />
                <span
                  contentEditable={isManagerRole ? 'true' : 'false'}
                  onBlur={isManagerRole ? (e) => onTitleChange(e, image.id) : undefined}
                  className='editable'
                  suppressContentEditableWarning={true}
                  onKeyPress={onKeyPress}
                  onClick={isManagerRole ? undefined : () => setViewDoc(image.url)}
                >
                  {image.title}
                </span>
              </div>
            ))}
          </div>
          {isManagerRole ? (
            <>
              <Uploader onFileUpload={onFileUploadImportant} />
              <div className='divider'></div>
            </>
          ) : null}
          <div className='images'>
            {images.map((image, i) => (
              <div key={i}>
                <img
                  alt={'image_' + image.title}
                  src={isDocument(image.url) ? imagePlaceholder : image.url}
                  onClick={() => setViewDoc(image.url)}
                />
                {isAdmin(user.role) ? (
                  <button
                    type='button'
                    className='close closeButton closeButton_card'
                    onClick={() => deleteDashPic(image.id)}
                    style={{ paddingTop: '0px!important' }}
                  >
                    <span aria-hidden='true'>×</span>
                    <span className='sr-only'>Close</span>
                  </button>
                ) : null}
                <br />
                <span
                  contentEditable={isManagerRole ? 'true' : 'false'}
                  onBlur={isManagerRole ? (e) => onTitleChange(e, image.id) : undefined}
                  className='editable'
                  suppressContentEditableWarning={true}
                  onKeyPress={onKeyPress}
                  onClick={isManagerRole ? undefined : () => setViewDoc(image.url)}
                >
                  {image.title}
                </span>
              </div>
            ))}
          </div>

          {isManagerRole ? <Uploader onFileUpload={onFileUpload} /> : null}
        </Col>
      </Row>

      <Modal show={!!viewDoc} onHide={onHidePic}>
        <button type='button' className='close closeButton closeButton_card' onClick={onHidePic}>
          <span aria-hidden='true'>×</span>
          <span className='sr-only'>Close</span>
        </button>
        <iframe
          src={userDoc}
          style={{ minHeight: '80vh', maxHeight: '80vh' }}
          title='User Document'
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pics: state.app.dashpics,
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashPics: () => dispatch(appAction.fetchDashPics()),
    updateDashPic: (pic) => dispatch(appAction.updateDashPic(pic)),
    deleteDashPic: (id) => dispatch(appAction.deleteDashPic(id)),
    saveDashPic: (file) => dispatch(appAction.saveDashPic(file)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyFiles);
