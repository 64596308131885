import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as appAction from '../actions/app.actions';
import { toast } from 'react-toastify';
import { PrivacyPolicyInfo } from '../components/Modals/ProfileModals';
import { isManager } from '../util/auth';
import Cookies from 'js-cookie';
import { SpinnerOverlay } from '../components/Utils/Spinner';
import jwtDecode from 'jwt-decode';
import useRoutesHook from '../hooks/routes.hook';

toast.configure({
  hideProgressBar: true,
  draggable: false,
});

const expiredTime = 8 * 60 * 60;

const logo = 'https://eventplusapp.s3.ca-central-1.amazonaws.com/images/applogo.png';

const App = (props: any) => {
  const {
    user: currentUser = {},
    fetchClients,
    fetchRoles,
    history,
    me: fetchUserInfo,
    logout,
  } = props;

  const [loaded, setLoaded] = useState(false);

  const [showPolicy, setShowPolicy] = useState(false);

  const { sidebarLinks, componentsToRender, resetComponent, loginComponents } = useRoutesHook({
    user: currentUser,
  });

  const token = Cookies.get('eventsAccessToken');

  const isUserManager = useMemo(() => {
    return isManager(currentUser.role);
  }, [currentUser.role]);

  const isLoggedInCheck = useCallback(() => {
    if (!token) {
      setLoaded(true);
      return false;
    }
    const parsedToken: { iat: number } = jwtDecode(token);
    const exp = parsedToken.iat + expiredTime;
    if (!(Date.now() / 1000 < exp)) {
      setLoaded(true);
      return false;
    }
    return true;
  }, [token]);

  const isLoggedIn = useMemo(isLoggedInCheck, [isLoggedInCheck, currentUser.id]);

  const onLogout = useCallback(() => {
    Cookies.remove('eventsAccessToken');
    logout().catch();
    history.push('/');
  }, [history, logout]);

  useEffect(() => {
    if (props.location.pathname?.match('reset|register')) return setLoaded(true);

    if (isLoggedIn && currentUser?.id) {
      setLoaded(true);
      return;
    }
    if (!isLoggedIn && !currentUser?.id) {
      setLoaded(true);
      return history.push('/login');
    }

    if (!currentUser?.id) {
      fetchUserInfo()
        ?.then(() => {
          setLoaded(true);
        })
        .catch(onLogout);
    }
  }, [history, currentUser, fetchUserInfo, isLoggedIn, onLogout, props.location.pathname]);

  useEffect(() => {
    const status = currentUser.status;
    if (isUserManager || !currentUser.id) return;
    if (status === 'active') return;
    history.push('/');
  }, [currentUser.status, currentUser.id, history, isUserManager]);

  useEffect(() => {
    if (isLoggedIn && currentUser.id) {
      fetchClients();
      fetchRoles(isUserManager);
    }
  }, [fetchClients, fetchRoles, isLoggedIn, currentUser.id, isUserManager]);

  const onShowPolicy = useCallback(() => {
    setShowPolicy(true);
  }, []);

  const onHidePolicy = useCallback(() => {
    setShowPolicy(false);
  }, []);

  if (!loaded) {
    return <SpinnerOverlay />;
  }

  if (props.location.pathname?.match('reset')) {
    return resetComponent;
  }

  if (!isLoggedIn) {
    return loginComponents;
  }

  return (
    <div>
      <div className='sidebar'>
        <div className='sidebar_wrapper'>
          <div className='logo'>
            <img alt='image_logo' src={logo} />
          </div>

          {sidebarLinks}
          <Link to='/' onClick={onLogout} style={{ marginTop: '40px' }}>
            Log Out
          </Link>
          <div className='sidebar__bottom'>
            <div style={{ marginBottom: '5px', cursor: 'pointer' }} onClick={onShowPolicy}>
              Terms & Agreement / Privacy Policy
            </div>
            <div>© 2022. Events Plus Management. All rights reserved.</div>
          </div>
          <PrivacyPolicyInfo show={showPolicy} onHide={onHidePolicy} />
        </div>
      </div>
      <div className='main'>{componentsToRender}</div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    me: () => dispatch(appAction.me()),
    logout: () => dispatch(appAction.logout()),
    fetchRoles: (isManager) => dispatch(appAction.fetchRoles(isManager)),
    fetchClients: () => {
      dispatch(appAction.fetchClients());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
