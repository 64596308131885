import * as appAction from 'Actions/app.actions';
import Msg from 'Components/Msg/Msg';
import Users from 'Components/Settings/Users';
import Shift from 'Components/Shift/Shift';
import toast from 'Components/Toast/Toast';
import { SpinnerOverlay } from 'Components/Utils/Spinner';
import { ExportToCsv } from 'export-to-csv';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, FormControl, Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { isAdmin } from 'Utils/auth';
import { uxDate, uxDateTime } from 'Utils/dates';
import { formatPhone } from 'Utils/numbers';
import Profile from '../Profile/Profile';
import { ShiftUserCard, WorkflowUserCard, EventInfo } from './EventCards';
import { generateTimeSheet } from './utils.js';
import './Event.scss';

const SHIFT_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};
const Event = (props) => {
  const {
    fetchEvent,
    fetchUsers,
    shifts,
    workflows,
    user,
    clearEvent,
    match,
    history,
    fetchUser,
    notifyPosting: notifyPostingProps,
    updateShift,
    createWorkflow,
    createProfile,
    approve: approveProps,
    sendMessage,
    deleteShift,
    decline: declineProps,
    confirmShift,
    event,
    clients,
  } = props;

  const [usersInfo, setUsersInfo] = useState([]);
  const [shift, setShift] = useState(null);
  const [single, setSingle] = useState(false);
  const [shiftMode, setShiftMode] = useState(null);
  const [declineWorkflow, setDeclineWorkflow] = useState(null);
  const [addWorkflow, setAddWorkflow] = useState(false);
  const [declineComment, setDeclineComment] = useState('');
  const [findStaff, setFindStaff] = useState(false);
  const [loading, setloading] = useState(false);
  const [bookedShifts, setBookedShifts] = useState([]);
  const [timeSheetLoad, setTimeSheetLoad] = useState(false);
  const [addManualSub, setAddManualSub] = useState(false);
  const [userView, setUserView] = useState(null);
  const [addSubState, setAddSubState] = useState({});
  const [email, setEmail] = useState(null);
  const [workflow, setWorkflow] = useState(null);

  const refresh = useCallback(() => {
    fetchEvent(match.params.event);
  }, [fetchEvent, match]);

  useEffect(
    function componentMounted() {
      if (match.params.event) {
        refresh();
      } else {
        history.push('/events');
      }
      return () => {
        clearEvent();
      };
    },
    [clearEvent, history, match, refresh]
  );

  const usersIds = useMemo(() => {
    return [...shifts, ...workflows].reduce((ids, shiftWorkflow) => {
      if (shiftWorkflow?.userId) ids.add(shiftWorkflow.userId);
      return ids;
    }, new Set());
  }, [shifts, workflows]);

  useEffect(
    function onFetchUserInfo() {
      if (usersIds?.length === Object.keys(usersInfo).length) return;

      const fetchUsersTimeout = setTimeout(async () => {
        if (!usersIds?.size) return;
        const users = await fetchUsers([...usersIds]);
        const usersInfoResult = users?.reduce((allUsers, user) => {
          allUsers[user?.id] = user;
          return allUsers;
        }, {});

        setUsersInfo(usersInfoResult);
      }, 100);

      return () => {
        fetchUsersTimeout && clearTimeout(fetchUsersTimeout);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersIds, fetchUsers]
  );

  const editShifts = useCallback(
    (_shift) => {
      setShift(_shift ?? shifts[0]);
      setSingle(!!_shift);
      shiftMode !== SHIFT_MODE.EDIT && setShiftMode(SHIFT_MODE.EDIT);
    },
    [shifts, shiftMode]
  );

  const duplicateShift = useCallback(() => {
    const { slotId, id, ...shift } = shifts?.[0] ?? {};
    setShift(shift);
    shiftMode !== SHIFT_MODE.CREATE && setShiftMode(SHIFT_MODE.CREATE);
  }, [shifts, shiftMode]);

  const onHideModal = useCallback(
    (_props = {}) => {
      const { createdShifts } = _props;

      if (createdShifts && shiftMode === SHIFT_MODE.CREATE) {
        const check = window.confirm('Do you want to open newly created event in another tab?');
        if (check) {
          createdShifts?.forEach((shift) => {
            window.open(`/events/${shift?.slotId}`);
          });
        }
      }

      setShift(null);
      setShiftMode(null);
      setTimeout(refresh, 100);
    },
    [shiftMode, refresh]
  );

  const openShifts = useMemo(() => {
    return shifts.filter((s) => s.status === 'open');
  }, [shifts]);

  const filledShifts = useMemo(() => {
    return shifts.filter((s) => s.status !== 'open');
  }, [shifts]);

  const approve = useCallback(
    async (workflow, event) => {
      if (event.detail !== 1) return;
      workflow.shiftId = openShifts[0].id;
      await approveProps(workflow);
      toast('Shift has been approved');
      refresh();
    },
    [openShifts, approveProps, refresh]
  );

  const reject = useCallback((workflow, addWorkflow = false) => {
    setDeclineWorkflow(workflow);
    setAddWorkflow(addWorkflow);
  }, []);

  const decline = useCallback(async () => {
    let workflow = {
      ...declineWorkflow,
      comment: declineComment,
    };
    await declineProps(workflow, addWorkflow);
    setDeclineComment('');
    setDeclineWorkflow(null);
    setAddWorkflow(false);
    refresh();
  }, [declineProps, declineComment, declineWorkflow, addWorkflow, refresh]);

  const findStaffer = useCallback(() => setFindStaff(true), []);

  const deleteJob = useCallback(async () => {
    let check = window.confirm('Are you sure you want to delete this job?');
    if (!check) return;
    setloading(true);
    await deleteShift(null, shifts[0].slotId);
    setloading(false);
    history.push('/events');
  }, [deleteShift, history, shifts]);

  const downloadCsv = useCallback(() => {
    const _shifts = filledShifts.map((s) => {
      const user = usersInfo[s?.userId];

      const staffName = `${user?.pName ?? user?.fName ?? ''} ${user?.lName ?? ''}`;

      return {
        Name: staffName,
        Email: user?.email ?? '',
        SSN: user?.ssn ?? '',
        Phone: formatPhone(user?.phone) ?? '',
      };
    });

    if (!_shifts.length) return;

    const shift = shifts?.find((shift) => clients[shift?.clientId]?.name && shift?.start);

    const options = {
      fieldSeparator: ',',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Jobs-${clients[shift?.clientId]?.name ?? ''}@${uxDate(shift?.start)}`,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(_shifts);
  }, [filledShifts, shifts, clients, usersInfo]);

  const invite = useCallback(async () => {
    const shift = shifts[0];
    const msg = {
      message: `
Hello EPM member
We are inviting to apply for a job for ${clients[shift?.clientId]?.name} at ${uxDate(shift.start)}`,
      subject: 'EMP Job Invite',
    };
    for (let i = 0, j = bookedShifts.length; i < j; i++) {
      if (bookedShifts[i]?.email) {
        msg.to = [bookedShifts[i].email];
        await sendMessage(msg);
      }
    }
    toast('invites has been sent');
    setFindStaff(false);
    setBookedShifts([]);
  }, [sendMessage, bookedShifts, shifts, clients]);

  const book = useCallback(async () => {
    let _shifts = [...openShifts];
    const usersToAdd = [];
    for (let i = 0, j = bookedShifts.length; i < j; i++) {
      const user = bookedShifts[i];
      const shift = _shifts.pop();
      if (!shift) {
        toast(`no more open shifts left`);
        return;
      }
      shift.userId = user.id;
      const isWorkflow = workflows.find((w) => w.userId === user.id);
      if (isWorkflow) await approveProps(isWorkflow);
      else await approveProps(null, shift);
      toast(
        `${usersInfo[user.id]?.fName ?? ''} - ${
          usersInfo[user.id]?.lName ?? ''
        } has been added to shift`
      );
      if (!usersInfo[user.id]) {
        usersToAdd.push(user);
      }
    }
    if (usersToAdd?.length) {
      setUsersInfo((_usersInfo) => {
        usersToAdd?.forEach((user) => {
          _usersInfo[user?.id] = user;
        });
        return _usersInfo;
      });
    }
    setFindStaff(null);
    setBookedShifts([]);
    refresh();
  }, [approveProps, bookedShifts, openShifts, refresh, usersInfo, workflows]);

  const addStaff = useCallback((user) => {
    setBookedShifts((_bookedShifts) => [..._bookedShifts, user]);
  }, []);

  const removeStaff = useCallback((user) => {
    setBookedShifts((_bookedShifts) => _bookedShifts?.filter((u) => u.id !== user.id));
  }, []);

  const getTimeSheet = useCallback(async () => {
    setTimeSheetLoad(true);
    await generateTimeSheet(filledShifts, clients[filledShifts[0]?.clientId], usersInfo);
    setTimeSheetLoad(false);
  }, [filledShifts, usersInfo, clients]);

  const addManualSubCallback = useCallback(() => {
    setAddManualSub(true);
  }, []);

  const onUserModalClose = useCallback(() => setUserView(null), []);

  const addSub = useCallback(async () => {
    setloading(true);
    if (!openShifts?.length) {
      setAddSubState({});
      setAddManualSub(false);
      setloading(false);
      return;
    }

    let { fName, lName, phone = '', email } = addSubState;
    if (!email) email = `${fName}.${lName}@eventspm.ca`.replace(/ /g, '_');

    const shiftToUpdate = {
      ...openShifts[0],
      status: 'confirmed',
    };

    try {
      const user = await createProfile({
        fName: fName?.toUpperCase(),
        lName: lName?.toUpperCase(),
        phone,
        email: email?.toUpperCase(),
        role: 'sub',
        status: 'declined',
      });

      shiftToUpdate.userId = user.id;

      const workflow = await createWorkflow({
        userId: user.id,
        slotId: shiftToUpdate.slotId,
        shiftId: shiftToUpdate.id,
        date: shiftToUpdate.start,
        approvedAt: new Date(),
      });

      if (!workflow?.id) throw new Error('Workflow is not created');
      shiftToUpdate.workflowId = workflow.id;

      await updateShift(shiftToUpdate, [shiftToUpdate.id]);
      toast('Profile created and assigned');
    } catch (error) {
      toast(error?.error || error?.message);
      setloading(false);
      return;
    }
    setloading(false);
    if (!(openShifts?.length - 1)) setAddManualSub(false);
    setAddSubState({});
  }, [addSubState, openShifts, createWorkflow, updateShift, createProfile]);

  const notifyPosting = useCallback(
    async (changes) => {
      setloading(true);
      try {
        const slotId = shifts[0]?.slotId;
        await notifyPostingProps(slotId, changes);
      } catch (error) {
        setloading(false);
        return toast(error?.message || 'Failed to Notify Staff');
      }
      setloading(false);
      toast('Staff has been notified');
    },
    [notifyPostingProps, shifts]
  );

  const notifyPostingNew = useCallback(() => {
    const changes = 'new';
    notifyPosting(changes);
  }, [notifyPosting]);

  const notifyChanges = useCallback(() => {
    const changes = 'updated';
    notifyPosting(changes);
  }, [notifyPosting]);

  const onUserClick = useCallback(
    (email) => {
      fetchUser(email).then((user) => {
        if (!user?.id) return;
        setUserView(user);
      });
    },
    [fetchUser]
  );

  const isOpen = useMemo(() => openShifts?.length > 0, [openShifts]);

  const cancelFindStaff = useCallback(() => {
    setFindStaff(null);
    setBookedShifts([]);
  }, []);

  const onShiftConfirm = useCallback(
    async (id) => {
      await confirmShift(id);
    },
    [confirmShift]
  );

  const sortedShiftsCards = useMemo(() => {
    let count = 1;
    return shifts
      ?.sort((a, b) => {
        const userA = usersInfo[a?.userId];
        const userB = usersInfo[b?.userId];

        if (!userA) return 1;
        if (!userB) return -1;

        return `${userA.fName}-${userB.lName}`.localeCompare(`${userB.fName}-${userB.lName}`);
      })
      .map((shift) => (
        <ShiftUserCard
          key={shift?.id}
          shift={shift}
          count={shift?.status === 'open' ? count++ : count}
          openShifts={openShifts?.length}
          onRejectCallback={reject}
          onUserClickCallback={onUserClick}
          onConfirmCallback={onShiftConfirm}
          user={usersInfo[shift?.userId]}
          onEmailClickCallback={setEmail}
          onEditShiftCallback={editShifts}
          client={clients[shift?.clientId]}
        />
      ));
  }, [usersInfo, shifts, editShifts, onUserClick, openShifts, reject, onShiftConfirm, clients]);

  const sortedWorkflowCards = useMemo(() => {
    return workflows
      .sort((a, b) => {
        const userA = usersInfo[a?.userId];
        const userB = usersInfo[b?.userId];
        if (!userA) return 1;
        if (!userB) return -1;
        return `${userA.fName}-${userB.lName}`.localeCompare(`${userB.fName}-${userB.lName}`);
      })
      .map((workflow) => (
        <WorkflowUserCard
          key={workflow.id}
          workflow={workflow}
          user={usersInfo[workflow?.userId]}
          onWorkflowClickCallback={setWorkflow}
          onUserClickCallback={onUserClick}
          onEmailClickCallback={setEmail}
          onApproveCallback={approve}
          onRejectCallback={reject}
          isAvailable={isOpen}
        />
      ));
  }, [approve, isOpen, onUserClick, reject, usersInfo, workflows]);

  return userView ? (
    <Profile staff={userView} action={onUserModalClose} />
  ) : (
    <>
      {loading && <SpinnerOverlay />}
      <div style={{ marginBottom: '2em', display: 'flex' }}>
        <EventInfo shifts={shifts} event={event} clients={clients} />
        <div className='event_action-wrapper'>
          {findStaff ? (
            <>
              <span onClick={invite} className='actionButton'>
                INVITE
              </span>
              <span onClick={book} className='actionButton'>
                BOOK
              </span>
              <span onClick={cancelFindStaff} className='actionButton'>
                CANCEL
              </span>
              <strong>
                Selected {bookedShifts.length} out of {openShifts?.length}
              </strong>
            </>
          ) : (
            <>
              <span onClick={() => editShifts()} className='actionButton'>
                EDIT SHIFT
              </span>
              {isAdmin(user.role) && (
                <span onClick={deleteJob} className='actionButton'>
                  DELETE SHIFT
                </span>
              )}
              <span onClick={duplicateShift} className='actionButton'>
                DUPLICATE SHIFT
              </span>
              {isOpen && (
                <>
                  <span onClick={findStaffer} className='actionButton'>
                    FIND STAFF
                  </span>
                  <span onClick={addManualSubCallback} className='actionButton'>
                    ADD MANUAL
                  </span>
                </>
              )}
              <span onClick={notifyPostingNew} className='actionButton'>
                NOTIFY NEW
              </span>
              <span onClick={notifyChanges} className='actionButton'>
                NOTIFY CHANGES
              </span>
              {props.shifts.find((s) => s.status !== 'open') ? (
                <span onClick={downloadCsv} className='actionButton'>
                  DOWNLOAD
                </span>
              ) : null}
              <span onClick={getTimeSheet} className='actionButton'>
                TIMESHEET{' '}
                {timeSheetLoad ? (
                  <Spinner as='span' animation='grow' size='sm' role='status' />
                ) : null}
              </span>
              <strong>
                <div>
                  Filled {filledShifts.length} out of {props.shifts.length}
                </div>
              </strong>
            </>
          )}
        </div>
      </div>

      {findStaff ? (
        <Users
          findStaff
          addStaff={addStaff}
          removeStaff={removeStaff}
          available={openShifts?.length}
          clientId={shifts?.[0]?.clientId}
        />
      ) : (
        <div className='users'>{sortedShiftsCards}</div>
      )}

      {!!workflows.length && !findStaff && (
        <>
          <div>{workflows.length} staffers applied or shortlisted</div>
          <div className='users'>{sortedWorkflowCards}</div>
        </>
      )}

      {!!declineWorkflow && (
        <Modal size='sm' show={true} onHide={() => setDeclineWorkflow(null)}>
          <Modal.Header closeButton>Reason:</Modal.Header>
          <Modal.Body>
            <Form.Control
              as='textarea'
              style={{ marginBottom: '1em' }}
              rows='3'
              onChange={({ currentTarget: { value } }) => {
                setDeclineComment(value);
              }}
            />
            <Button
              onClick={decline}
              disabled={!declineComment?.length}
              className='actionButton deleteColor'
            >
              Decline
            </Button>
          </Modal.Body>
        </Modal>
      )}

      {!!shift && (
        <Modal
          show={true}
          onHide={() => setShift(null)}
          enforceFocus={false}
          dialogClassName='shift_wrapper'
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Shift
              shift={shift}
              shifts={shifts ?? []}
              mode={shiftMode}
              action={onHideModal}
              single={single}
              users={usersInfo}
            />
          </Modal.Body>
        </Modal>
      )}
      {workflow && workflow.id ? (
        <Modal show={true} onHide={() => setWorkflow(null)}>
          <Modal.Header closeButton>{workflow.staffName || ''}</Modal.Header>
          <Modal.Body>
            {!!workflow.appliedAt && <div>Applied - {uxDateTime(workflow.appliedAt)}</div>}
            {!!workflow.approvedAt && <div>Approved at {uxDateTime(workflow.approvedAt)}</div>}
            {!!workflow.declinedAt && <div>Declined at {uxDateTime(workflow.declinedAt)}</div>}
            {!!workflow.declinedBy && (
              <div>Declined by {usersInfo[workflow.declinedBy]?.email ?? workflow.declinedBy}</div>
            )}
            {!!workflow.comment && (
              <div>
                Reason: <span dangerouslySetInnerHTML={{ __html: workflow.comment }}></span>
              </div>
            )}
            {!!workflow.rejectedAt && <div>Rejected at {uxDateTime(workflow.rejectedAt)}</div>}
          </Modal.Body>
        </Modal>
      ) : null}
      {addManualSub && (
        <Modal show={true} onHide={() => setAddManualSub(false)} centered={true}>
          <Modal.Header closeButton>Add and Assing Subcontractor</Modal.Header>
          <Modal.Body>
            <div className='addsub__wrapper'>
              <FormControl
                placeholder='First Name'
                onChange={({ currentTarget: { value: fName } }) =>
                  setAddSubState({ ...addSubState, fName })
                }
                value={addSubState?.fName || ''}
              />
              <FormControl
                placeholder='Last Name'
                onChange={({ currentTarget: { value: lName } }) =>
                  setAddSubState({ ...addSubState, lName })
                }
                value={addSubState?.lName || ''}
              />
              <FormControl
                placeholder='Email'
                onChange={({ currentTarget: { value: email } }) =>
                  setAddSubState({ ...addSubState, email })
                }
                value={addSubState?.email || ''}
              />
              <FormControl
                placeholder='Phone Number'
                onChange={({ currentTarget: { value: phone } }) =>
                  setAddSubState({ ...addSubState, phone })
                }
                value={addSubState?.phone || ''}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={addSub}
              disabled={!addSubState.fName || !addSubState.lName}
              className='actionButton'
            >
              Add & Assign
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!!email ? <Msg action={() => setEmail(null)} email={email} /> : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
    shifts: state.app.eventShifts ?? [],
    workflows: state.app.eventWorkflows ?? [],
    event: state.app.event ?? {},
    clients: state.app.clients?.reduce((allClients, client) => {
      allClients[client?.id] = client;
      return allClients;
    }, {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apply: (shift) => dispatch(appAction.apply(shift)),
    decline: (shift, addWorkflow) => dispatch(appAction.decline(shift, addWorkflow)),
    approve: (workflow, shift) => dispatch(appAction.approve(workflow, shift)),
    confirmShift: (workflowId) => dispatch(appAction.confirmShift(workflowId)),
    fetchEvent: (slotId) => dispatch(appAction.fetchEvent(slotId)),
    deleteShift: (id, slotId) => dispatch(appAction.deleteShift(id, slotId)),
    sendMessage: (msg) => dispatch(appAction.sendMessage(msg)),
    clearEvent: () => dispatch(appAction.clearEvent()),
    createProfile: (user) => dispatch(appAction.createProfile(user)),
    updateShift: (shift, shiftIds) => dispatch(appAction.updateShift(shift, shiftIds)),
    notifyPosting: (slotId, changes) => dispatch(appAction.notifyPosting(slotId, changes)),
    createWorkflow: (workflow) => dispatch(appAction.createWorkflow(workflow)),
    fetchUser: (id) => dispatch(appAction.fetchUser(id)),
    fetchUsers: (ids) => dispatch(appAction.fetchUsers(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
