import React, { memo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import { Modal, Form } from 'react-bootstrap';
import toast from 'Components/Toast/Toast';
import { Uploader } from '../Uploader';

const Message = (props) => {
  const { email, sendMessage, action } = props;

  const [recipientEmail, setRecipientEmail] = useState(email.to || '');
  const [subject, setSubject] = useState(email.subject || '');
  const [message, setMessage] = useState(email.message || '');
  const [files, setFiles] = useState([]);

  const onSendMessage = useCallback(() => {
    if (!message || !recipientEmail) return toast('Add message');
    let msg = {
      message,
      to: recipientEmail?.split(','),
      subject,
      files,
    };
    sendMessage(msg);
    action();
    toast('message sent');
  }, [message, recipientEmail, subject, files, action, sendMessage]);

  const onFileUploadImportant = useCallback(
    ({ currentTarget }) => {
      const file = currentTarget.files[0];
      setFiles([file, ...files]);
    },
    [files]
  );

  return (
    <Modal size='lg' show={true} onHide={action}>
      <Modal.Header closeButton />

      <Modal.Body>
        To:{' '}
        <Form.Control
          value={recipientEmail}
          onChange={(event) => setRecipientEmail(event?.currentTarget?.value)}
          style={{ marginBottom: '1em' }}
        />
        Subject:{' '}
        <Form.Control
          value={subject}
          onChange={(event) => setSubject(event?.currentTarget?.value)}
          style={{ marginBottom: '1em' }}
        />
        Message:{' '}
        <Form.Control
          as='textarea'
          value={message}
          onChange={(event) => {
            setMessage(event?.currentTarget?.value);
          }}
          style={{ marginBottom: '1em' }}
          rows='3'
        />
        <div>
          <Uploader onFileUpload={onFileUploadImportant} />
          <div>
            {files?.map((file) => (
              <div key={file?.name + file?.size}>{file?.name}</div>
            ))}
          </div>
        </div>
        <span className='actionButton' onClick={onSendMessage}>
          Send
        </span>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (msg) => dispatch(appAction.sendMessage(msg)),
  };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Message));
