import React from "react";
import "./Checkbox.scss";

const Checkbox = (props) => {
  let className = "custom-css-checkbox";
  if (props.hasOwnProperty("className")) {
    className += ` ${props.className}`;
  }

  const labelId = (props.label + "custom-css-checkbox").replace(/ /g, "_");
  return (
    <div className={className}>
      <input id={labelId} type="checkbox" className={className} {...props} />
      <label htmlFor={labelId}>{props.label}</label>
    </div>
  );
};

export default Checkbox;
