import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getTime, shortDateWithYear } from 'Utils/dates';
import { loadImage } from 'Utils/image.js';

const logo = 'https://eventplusapp.s3.ca-central-1.amazonaws.com/images/applogo.png';

const rowsPerPage = {
  page1: 18,
  allPages: 22,
};

export const generateTimeSheet = async (filledShifts = [], client, users) => {
  const sortedShifts = filledShifts.sort((a, b) => {
    const userAName = users[a?.userId]?.fName ?? '' + users[a?.userId]?.lName ?? '';
    const userBName = users[b?.userId]?.fName ?? '' + users[b?.userId]?.lName ?? '';

    if ((userAName ?? users[a?.userId]?.email) < (userBName || users[b?.userId]?.email)) {
      return -1;
    }
    if ((userAName || users[a?.userId]?.email) > (userBName || users[b?.userId]?.email)) {
      return 1;
    }
    return 0;
  });

  const populatedShifts = sortedShifts?.map((shift, i) => {
    const staff = `${users[shift.userId]?.pName ?? users[shift.userId]?.fName ?? ''} ${users[shift.userId]?.lName ?? ''
      }`;

    return {
      ...shift,
      start: getTime(shift.start, true),
      number: i + 1,
      signature: staff,
      staffName: staff,
      break15: "15' BREAK",
      break30: "30' BREAK",
    };
  });

  let shiftsToAdd = populatedShifts?.length - rowsPerPage.page1;

  while (shiftsToAdd >= 0) {
    shiftsToAdd -= rowsPerPage.allPages;
  }

  //Add extra
  const sortedArray = [...populatedShifts, ...Array(Math.abs(shiftsToAdd)).fill({})];

  const doc = new jsPDF({
    orientation: 'l',
  });

  const img = await loadImage(logo + '?timesheet');

  const shift = filledShifts[0];

  doc.autoTable({
    margin: { top: 30 },
    didDrawPage: () => {
      if (img) doc.addImage(img, 'png', 10, 10, 22, 20, null, 'FAST');
      doc.setTextColor('#367C7D');
      doc.setFontSize(15).setFont(undefined, 'bold');
      doc.text('EVENTS PLUS', 35, 19);

      doc.setFontSize(13).setFont(undefined, 'normal').setTextColor("#7EB1B1");
      doc.text(' MANAGEMENT', 35, 25);
      doc.setFillColor('#dbe8f6');
      doc.rect(90, 13, 60, 12, 'FD');

      doc.rect(155, 13, 60, 12, 'FD');

      doc.rect(220, 13, 60, 12, 'FD');

      doc.setTextColor('#ec7623');
      doc.setFontSize(9).setFont(undefined, undefined, 100);
      doc.text('Date', 92, 28);
      doc.text('Venue', 157, 28);
      doc.text('Invoice #', 222, 28);

      doc.setLineDash([0.3], 0.2);
      doc.setDrawColor('#30a8ab');
      doc.line(35, 20.5, 70, 20.5);
      doc.setLineDash(0, 0);


      doc.setTextColor('#367C7D').setFont(undefined, 'bold');
      doc.setFontSize(12)
      if (shift?.start) {
        doc.text(`DATE: ${shortDateWithYear(shift.start)}`, 93, 20);
      }
      if (client) {
        if (client?.name?.length > 20) {
          doc.text(client?.name.substring(0, 20).concat('...'), 158, 20);
        } else {
          doc.text(client?.name, 158, 20);
        }
      }

    },
  });
  doc.autoTable({
    body: sortedArray,
    columns: [
      { header: '#', dataKey: 'number' },
      {
        header: 'NAME',
        dataKey: 'staffName',
        styles: {
          fontStyle: 'bold',
        },
      },
      { header: 'JOB ROLE', dataKey: 'role' },
      { header: 'START TIME', dataKey: 'start' },
      { header: 'IN' },
      { header: '15 MIN BREAK', dataKey: 'break15' },
      { header: '30 MIN BREAK', dataKey: 'break30' },
      { header: 'OUT' },
      { header: 'SIGNATURE', dataKey: 'signature' },
    ],
    styles: {
      lineWidth: 0.2,
      lineColor: 1,
      halign: 'left',
      textColor: 1,
      valign: 'middle',
      minCellWidth: 20,
    },
    columnStyles: {
      0: { cellWidth: 10, textColor: [218, 134, 68] },
      1: { textColor: [218, 134, 68] },
      2: { textColor: [218, 134, 68] },
      3: { textColor: [218, 134, 68], fontStyle: 'bold' },
      5: { textColor: [218, 134, 68] },
      6: { textColor: [218, 134, 68] },
      8: { textColor: 220 },
    },
    headStyles: {
      textColor: '#ec7623',
      fillColor: 'white',
    },
    theme: 'striped',
  });
  const pageCount = doc.internal.getNumberOfPages();
  doc.setTextColor('#30a8ab');
  doc.setFontSize(7);
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(`PAGE ${i} OF ${pageCount}`, 268, 203);
  }
  doc.save('timesheet.pdf');
};
