const policy = `Events Plus Management Privacy Policy

This Privacy Policy describes how your personal information is collected, used, and shared when you visit https://app.eventspm.ca/ (the “Site”).

PERSONAL INFORMATION WE COLLECT

When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, some of the cookies that are installed on your device and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”

We collect Device Information using the following technologies:

    - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
    - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
    - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.

Additionally when you register on the Site, we collect certain information from you, including your name, address, email address, phone number, sin number and documents which qualify you to work for desired roles .  We refer to this information as “User Information.”

When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and User Information.

HOW DO WE USE YOUR PERSONAL INFORMATION?

We use the User Information that we collect generally to fulfill requirements for specific shifts.  Additionally, we use this User Information to:
Communicate with you;
Propose shifts which match with your qualification;
Send some announcements.

We use the Device Information that we collect to help us improve and optimize our Site.

Finally, we may also share your Personal Information to comply with applicable laws and regulations.


DO NOT TRACK
Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.

YOUR RIGHTS
You have the right to access personal information we hold about you, modify it or remove from the Site. 

MINORS
The Site is not intended for individuals under the age of 18.

CHANGES
We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.

CONTACT US
For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info@eventspm.ca or by mail using the details provided below:

  75 Carl Hall Road, 2nd floor, Toronto, ON, M3K 2B9, Canada
`

export default policy;