import "./utils.scss";
import { createPortal } from "react-dom";

export const SpinnerOverlay = () =>
  createPortal(
    <div className="overlayView">
      <div className="overlayView_center">
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="overlayView_msg">Loading...</div>
      </div>
    </div>,
    document.body
  );
