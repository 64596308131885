import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, FormControl } from 'react-bootstrap';
import * as appAction from 'Actions/app.actions';
import { AgGridReact } from 'ag-grid-react';
import Autocomplete from 'Components/Autocomplete/Autocomplete';
import { Uploader } from '../Uploader';
import { isAdmin } from 'Utils/auth';
import { SpinnerOverlay } from 'Components/Utils/Spinner';

// @TODO Make table longer

const defaultColumns = [
  {
    field: 'refId',
    headerName: 'Id',
    sort: 'asc',
  },
  {
    field: 'name',
    headerName: 'Name',
    cellStyle: { 'font-weight': 'bold' },
  },
  {
    field: 'location',
    headerName: 'Location',
  },
];

class Clients extends Component {
  state = {
    client: null,
    search: '',
    loading: false,
  };

  defaultColDef = {
    sortable: true,
    resizable: true,
    sortingOrder: ['asc', 'desc'],
  };

  columns = isAdmin(this.props.user.role)
    ? [
        ...defaultColumns,
        {
          colId: 'action',
          headerName: 'Action',
          cellRenderer: 'DeleteFormatter',
        },
      ]
    : defaultColumns;

  DeleteFormatter = ({ data }) => {
    return isAdmin(this.props.user.role) ? (
      <div
        onClick={() =>
          window.confirm('Are you sure you want to delete this role?') &&
          this.props.deleteClient(data.id)
        }
      >
        Delete
      </div>
    ) : null;
  };

  frameworkComponents = {
    DeleteFormatter: this.DeleteFormatter,
  };

  closeModal = () => {
    this.setState({ client: null });
  };

  handleAddShow = () => {
    this.setState({ isNew: true, client: {} });
  };

  updateClient = () => {
    this.props.updateClient(this.state.client);
    this.closeModal();
  };

  addClient = () => {
    this.props.addClient(this.state.client);
    this.closeModal();
  };

  deleteClient = () => {
    this.props.deleteClient(this.state.client.id);
    this.closeModal();
  };

  onGridReady = ({ columnApi, api }) => {
    api.sizeColumnsToFit();
  };

  onCellClicked = (params) => {
    if (params.colDef.colId !== 'action') {
      this.setState({ client: params.data, isNew: false });
    }
  };

  onFileUpload = async ({ currentTarget }) => {
    try {
      this.setState({ loading: true });
      let location = await this.props.saveAttachment({
        file: currentTarget.files[0],
        name: currentTarget.files[0].name,
      });
      let name = currentTarget.getAttribute('name');
      let images = Array.isArray(this.state.client.images) ? this.state.client.images : [];
      images = [location, ...images];
      this.setState({
        client: {
          ...this.state.client,
          [name]: [...images],
        },
      });
      currentTarget.value = '';
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  onImageDelete = (url) =>
    this.setState({
      client: {
        ...this.state.client,
        images: this.state.client.images.filter((i) => i !== url),
      },
    });

  gridProps = {
    columnDefs: this.columns,
    defaultColDef: this.defaultColDef,
    onGridReady: this.onGridReady,
    frameworkComponents: this.frameworkComponents,
    onCellClicked: this.onCellClicked,
  };

  render() {
    const { client, isNew, loading } = this.state;
    return (
      <>
        {loading && <SpinnerOverlay />}

        <span className='actionButton' onClick={this.handleAddShow} style={{ margin: '1em' }}>
          Add Client
        </span>
        <div className='w-100 h-100 ag-theme-balham'>
          <AgGridReact {...this.gridProps} rowData={this.props.clients} />
        </div>

        {client && (
          <Modal show={true} onHide={this.closeModal}>
            <Modal.Header closeButton>
              {!isNew ? <Modal.Title>{client.name}</Modal.Title> : 'Add Client'}
              <br />
            </Modal.Header>
            <Modal.Body>
              <FormControl
                placeholder='Id'
                style={{ marginBottom: '1rem' }}
                value={client.refId || ''}
                onChange={({ currentTarget: { value: refId } }) =>
                  this.setState({ client: { ...client, refId } })
                }
                aria-label='Client Id'
              />
              <FormControl
                placeholder='Title'
                style={{ marginBottom: '1rem' }}
                value={client.name || ''}
                onChange={({ currentTarget: { value: name } }) =>
                  this.setState({ client: { ...client, name } })
                }
                aria-label='Client Name'
              />
              <FormControl
                placeholder='Note'
                value={client.note || ''}
                onChange={({ currentTarget: { value: note } }) =>
                  this.setState({
                    client: { ...client, note },
                  })
                }
                aria-label='Note'
                as='textarea'
                rows='3'
                style={{ marginBottom: '1rem' }}
              />
              <Autocomplete
                style={{ width: '100%', marginBottom: '10px' }}
                value={client.location}
                setAddress={(location, { lat, lng }) =>
                  this.setState({
                    client: {
                      ...client,
                      location: location?.toUpperCase(),
                      lat,
                      lng,
                    },
                  })
                }
              />
              <div className='clientImages'>
                {client?.images?.map((i) => (
                  <div key={i}>
                    <img alt={i} src={i} />
                    {isAdmin(this.props.user.role) && (
                      <button
                        type='button'
                        className='close closeButton'
                        onClick={() => this.onImageDelete(i)}
                      >
                        <span aria-hidden='true'>×</span>
                        <span className='sr-only'>Close</span>
                      </button>
                    )}
                  </div>
                )) || null}
              </div>
              <Uploader onFileUpload={this.onFileUpload} name='images' />
            </Modal.Body>
            <Modal.Footer>
              {!isNew ? (
                <span className='actionButton' onClick={this.updateClient}>
                  Update
                </span>
              ) : (
                <span className='actionButton' onClick={this.addClient}>
                  Save
                </span>
              )}
              <span className='actionButton-nopad' onClick={this.closeModal}>
                Close
              </span>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.app.clients,
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClient: (client) => dispatch(appAction.addClient(client)),
    updateClient: (client) => dispatch(appAction.updateClient(client)),
    deleteClient: (id) => dispatch(appAction.deleteClient(id)),
    saveAttachment: (file) => dispatch(appAction.saveAttachment(file)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
