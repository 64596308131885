import { useMemo } from 'react';
import { isLead, isManager } from '../util/auth';
import { Route, Link, Switch } from 'react-router-dom';
import Profile from '../components/Profile/Profile';
import Event from '../components/Event/Event';
import Calendar from '../components/Calendar/Calendar';
import Settings from '../components/Settings/Settings';
import Staff from '../components/Settings/Users';
import SignOffsTable from '../components/SignOffs/signOffsTable';
import SignOffs from '../components/SignOffs/SignOffs';
import Birthday from '../components/Birthday/Birthday';
import Dashboard from '../components/Dashboard/Dashboard';
import CalendarStaff from '../components/CalendarStaff/Calendar';
import LogSheet from '../components/LogSheet/LogSheet';
import Register from '../components/Register/Register';
import Login from '../components/Login/Login';
import ResetPassword from '../components/Reset/Reset';
import MyFiles from '../components/MyFiles/MyFiles';
import Payroll from '../components/Payroll';
import { useLocation } from 'react-router-dom';

const useRoutesHook = (props: any) => {
  const { user } = props;

  const { role, status, email } = user || {};

  const location = useLocation();

  const pathname = useMemo(() => location?.pathname?.replace(/\//g, ''), [location]);

  const sidebarLinks = useMemo(() => {
    if (isManager(role))
      return (
        <>
          <Link to='/' className={pathname === '' ? 'activeLink' : ''}>
            Dashboard
          </Link>
          <Link to='/profile' className={pathname === 'profile' ? 'activeLink' : ''}>
            My Profile
          </Link>
          <Link to='/events' className={pathname === 'events' ? 'activeLink' : ''}>
            Events
          </Link>
          <Link to='/birthdays' className={pathname === 'birthdays' ? 'activeLink' : ''}>
            Birthdays
          </Link>
          <Link to='/myfiles' className={pathname === 'myfiles' ? 'activeLink' : ''}>
            My Files
          </Link>
          <Link to='/staff' className={pathname === 'staff' ? 'activeLink' : ''}>
            Staff
          </Link>
          <Link to='/settings' className={pathname === 'settings' ? 'activeLink' : ''}>
            Settings
          </Link>
          <Link to='/signoffs' className={pathname === 'signoffs' ? 'activeLink' : ''}>
            Sign Offs
          </Link>
          {email === 'DMYTRO.KUKHARENKO@GMAIL.COM' && (
            <Link to='/payroll' className={pathname === 'payroll' ? 'activeLink' : ''}>
              Payroll
            </Link>
          )}
        </>
      );

    if (status !== 'active') {
      return (
        <Link to='/profile' className={pathname === 'profile' ? 'activeLink' : ''}>
          My Profile
        </Link>
      );
    }
    return (
      <>
        <Link to='/' className={pathname === '' ? 'activeLink' : ''}>
          Dashboard
        </Link>
        <Link to='/profile' className={pathname === 'profile' ? 'activeLink' : ''}>
          My Profile
        </Link>
        <Link to='/myfiles' className={pathname === 'myfiles' ? 'activeLink' : ''}>
          My Files
        </Link>
        <Link to='/calendar' className={pathname === 'calendar' ? 'activeLink' : ''}>
          My Shifts
        </Link>
        <Link to='/logsheet' className={pathname === 'logsheet' ? 'activeLink' : ''}>
          My Log Sheet
        </Link>
        {isLead(role) && (
          <>
            <Link to='/signoffs' className={pathname === 'signoffs' ? 'activeLink' : ''}>
              Sign Offs
            </Link>
            <Link to='/staff' className={pathname === 'staff' ? 'activeLink' : ''}>
              Staff
            </Link>
          </>
        )}
      </>
    );
  }, [role, status, pathname, email]);

  const componentsToRender = useMemo(() => {
    if (isManager(role))
      return (
        <Switch>
          <Route path='/profile/' component={Profile} />
          <Route path='/events/:event' component={Event} />
          <Route path='/events/' component={Calendar} />
          <Route path='/settings/' component={Settings} />
          <Route path='/staff/' component={Staff} />
          <Route path='/signoffs/:event' component={SignOffsTable} />
          <Route path='/signoffs/' component={SignOffs} />
          <Route path='/birthdays/' component={Birthday} />
          <Route path='/myfiles/' component={MyFiles} />
          {email === 'DMYTRO.KUKHARENKO@GMAIL.COM' && (
            <Route path='/payroll/' component={Payroll} />
          )}
          <Route path='/' component={Dashboard} />
        </Switch>
      );

    if (status !== 'active') {
      return <Route path='/' component={Profile} />;
    }

    return (
      <Switch>
        <Route path='/profile/' component={Profile} />
        <Route path='/calendar/event/' component={Event} />
        <Route path='/calendar/' component={CalendarStaff} />
        <Route path='/logsheet/' component={LogSheet} />
        <Route path='/myfiles/' component={MyFiles} />
        {isLead(role) && [
          <Route path='/signoffs/:event' component={SignOffsTable} key='SignOffsTable' />,
          <Route path='/signoffs/' component={SignOffs} key='SignOffs' />,
          <Route path='/staff/' component={Staff} key='Staff' />,
        ]}
        <Route path='/' component={Dashboard} />
      </Switch>
    );
  }, [role, status, email]);

  const loginComponents = useMemo(() => {
    return (
      <Switch>
        <Route path='/register/' component={Register} />
        <Route path='/reset/' component={ResetPassword} />
        <Route path='/' component={Login} />
      </Switch>
    );
  }, []);

  const resetComponent = useMemo(() => {
    return <Route path='/reset/' component={ResetPassword} />;
  }, []);

  return {
    sidebarLinks,
    componentsToRender,
    loginComponents,
    resetComponent,
  };
};

export default useRoutesHook;
