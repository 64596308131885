import React from "react";

const logo =
  "https://eventplusapp.s3.ca-central-1.amazonaws.com/images/applogo.png";

const Logo = () => (
  <div className="logo" style={{ paddingTop: "25px" }}>
    <img src={logo} alt="appLogo" />
  </div>
);

export const LogoWB = () => (
  <div className="logo">
    <img src={logo} className="whiteBG" alt="appLogo" />
  </div>
);

export default Logo;
