import GoogleMapReact from 'google-map-react';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as appAction from '../../actions/app.actions';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import { distributionUrl, originUrl } from '../../components/Modals/ProfileModals';
import Msg from '../../components/Msg/Msg';
import Profile from '../../components/Profile/Profile';
import userEmpty from '../../images/userEmpty.png';
import { isManager } from '../../util/auth';
import { shortDate } from '../../util/dates';
import Select from 'react-select';
import { User } from './settings.definitions';
import noteIcon from '../../images/note.png';
import ssnIcon from '../../images/smartServeIcon.png';

const AnyReactComponent = ({ lat, lng }) => <div className='googleMarker'></div>;

const dateForNew = new Date();
dateForNew.setDate(dateForNew.getDate() - 60);

const colors = {
  pending: 'rgb(103,121,147)',
  active: 'rgb(242, 185, 84)',
  new: 'rgb(117, 159, 160)',
  declined: 'rgb(213,136,128)',
};

// @ts-ignore
const Marker = ({ id, image, status, lat, lng, onUserClick }) => {
  if (!lat || !lng) return null;
  return (
    <img
      key={id}
      id={id}
      alt={'Map pointer'}
      src={image}
      onClick={() => onUserClick({ id })}
      style={{
        width: '25px',
        // @ts-ignore
        outline: `2px ${colors[status]} solid`,
      }}
    />
  );
};

const isNewUser = (user: User) => new Date(user.registerAt) > dateForNew;

const filterSelection = [
  {
    value: 'hiredBy:epm',
    label: '1 - EPM',
  },
  {
    value: 'hiredBy:bong',
    label: '4 - BONG',
  },
  {
    value: 'hiredBy:albert',
    label: '5 - ALBERT',
  },
  {
    value: 'hiredBy:abdul',
    label: '6 - ABDUL',
  },
  {
    value: 'hiredBy:jasmine',
    label: '7 - JASMINE',
  },
  {
    value: 'hiredBy:kien',
    label: '8 - KIEN',
  },
  {
    value: 'hiredBy:luz',
    label: '9 - LUZ',
  },
  {
    value: 'vaccine:vaccinated',
    label: 'VACCINATED',
  },
  {
    value: 'vaccine:unvaccinated',
    label: 'UN-VACCINATED',
  },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '26px',
    height: '26px',
    fontSize: '15px',
    backgroundColor: '#dbe8f6',
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: '26px',
    fontSize: '15px',
    textAlign: 'center',
  }),

  input: (provided) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '26px',
  }),
};

const getUserStatus = (user: User, isNewUser: boolean) => {
  if (user?.status === 'pending' && isNewUser) return 'new';
  return user?.status || 'pending';
};

const userStatusBadge = {
  new: <div className='new_users user-status-badge'>NEW</div>,
  active: <div className='active_users user-status-badge'>ACTIVE</div>,
  pending: <div className='pending_users user-status-badge'>PENDING</div>,
  declined: <div className='declined_users user-status-badge'>DECLINED</div>,
};

const DeclinedX = () => <div className='declined_cross'>X</div>;

interface LocationCoords {
  minLat: number;
  minLng: number;
  maxLat: number;
  maxLng: number;
}

const Users = (props: any) => {
  const {
    user: currentUser,
    fetchAllUsers,
    fetchUser,
    removeStaff,
    available: availableUsers,
    addStaff: onAddStaff,
    users: allUsers,
    findStaff: isFindStaff,
    clientId,
  } = props;

  const [filteredUsersArray, setFilteredUsersArray] = useState<User[]>([]);
  const [search, setSearch] = useState('');
  const [coords, setCoords] = useState<LocationCoords | null>(null);
  const [filter, setFilter] = useState('active');
  const [findStaff, setFindStaff] = useState<Array<User>>([]);
  const [userView, setUserView] = useState<User | null>();
  const [location, setLocation] = useState({
    name: '',
    lat: 0,
    lng: 0,
  });

  const [email, setEmail] = useState('');

  const [dropDownSelection, setDropDownSelection] = useState<null | {
    value: string;
    label: string;
  }>(null);

  const isUserManager = useMemo(() => isManager(currentUser?.role), [currentUser]);

  const searchTimeoutRef = useRef<any>(null);

  const onUserModalClose = useCallback(() => {
    setUserView(null);
  }, []);

  const onUserClick = useCallback(
    ({ id }: { id: string }) => {
      fetchUser?.(id).then((user: User) => {
        if (!user?.id) return;
        setUserView(user);
      });
    },
    [fetchUser]
  );

  useEffect(() => {
    const sideBarUser = document.querySelector('.sidebar > div > a[href="/staff"]');
    sideBarUser?.addEventListener('click', onUserModalClose);

    return () => {
      sideBarUser?.removeEventListener('click', onUserModalClose);
    };
  }, [onUserModalClose]);

  const onAllUsers = useCallback(() => {
    setFilter('');
  }, []);

  const onActive = useCallback(() => {
    setFilter('active');
  }, []);

  const onPending = useCallback(() => {
    setFilter('pending');
  }, []);

  const onDecline = useCallback(() => {
    setFilter('declined');
  }, []);

  const onNewUsers = useCallback(() => {
    setFilter('new');
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const onSearch = useCallback(({ currentTarget }: { currentTarget: { value: string } }) => {
    setSearch((currentTarget.value || '').toUpperCase());
  }, []);

  // @ts-ignore
  const onLocation = useCallback((location, { lat, lng }) => {
    setLocation({
      name: location,
      lat,
      lng,
    });
    if (!location) setCoords(null);
  }, []);

  const addStaff = useCallback(
    (addUser: User) => {
      const isInFindStaff = findStaff?.find((user) => user.id === addUser.id);
      if (isInFindStaff) {
        removeStaff(addUser);
        setFindStaff(findStaff?.filter((user) => user.id !== addUser.id));
      } else if (findStaff.length < availableUsers) {
        onAddStaff(addUser);
        setFindStaff([...findStaff, addUser]);
      }
    },
    [findStaff, availableUsers, onAddStaff, removeStaff]
  );
  const getUserStyle = useCallback(
    (user: User) => {
      let style = { outline: '' };
      if (findStaff.some((u) => u.id === user.id)) style.outline = '3px green solid';
      return style;
    },
    [findStaff]
  );

  const onUserClickFunction = useCallback(
    (user) => (isFindStaff ? addStaff(user) : onUserClick(user)),
    [addStaff, onUserClick, isFindStaff]
  );

  const onUserClickMemo = useMemo(
    () => (isUserManager ? onUserClickFunction : undefined),
    [onUserClickFunction, isUserManager]
  );

  const UserCell = useCallback(
    // @ts-ignore
    (user) => {
      const isUserNew = isNewUser(user);
      const status = getUserStatus(user, isUserNew);
      return (
        <div key={user.id} style={getUserStyle(user)} className={`users__${status}`}>
          {userStatusBadge[status]}
          <img
            alt={'user_' + user?.email}
            decoding='async'
            src={user?.image?.replace(originUrl, distributionUrl) ?? userEmpty}
            className={['userList__img', onUserClickMemo ? 'userList__img-pointer' : ''].join(' ')}
            onClick={() => onUserClickMemo?.(user)}
            loading='lazy'
          />
          {isUserNew ? (
            <div className='registerAt'>Registered on: {shortDate(user.registerAt)}</div>
          ) : null}
          <div style={{ position: 'relative' }}>
            {status === 'declined' && <DeclinedX />}
            <strong className='fName'>{user?.fName}</strong>
            <div>{user?.lName}</div>

            {isUserManager && (
              <>
                <div className='emailProfile' onClick={() => setEmail(user?.email)}>
                  {user?.email}
                </div>
                <strong>{user?.employeeNumber ?? ''}</strong>
              </>
            )}
            <div className='users_icons'>
              {user?.internalNotes?.length && <img src={noteIcon} alt='Internal notes icon' />}
              {user?.ssn?.length && <img src={ssnIcon} alt='Smart serve icon' />}
            </div>
          </div>
        </div>
      );
    },
    [isUserManager, getUserStyle, onUserClickMemo]
  );
  // @ts-ignore
  const onRadiusChange = async ({ bounds }) => {
    const coords = {
      minLat: bounds.se.lat,
      minLng: bounds.nw.lng,
      maxLat: bounds.nw.lat,
      maxLng: bounds.se.lng,
    };
    // @ts-ignore
    setCoords(coords);
  };

  const filterUsers = useCallback(() => {
    let fUsers = [...allUsers];

    if (filter) {
      if (filter === 'new') {
        fUsers = allUsers.filter((u: User) => u.status === 'pending' && isNewUser(u));
      } else if (filter === 'pending') {
        fUsers = allUsers.filter((u: User) => u.status === 'pending');
      } else if (filter === 'active') {
        fUsers = allUsers.filter((u: User) => u.status === filter && u.lastLogOn);
      } else if (filter === 'declined') {
        fUsers = allUsers.filter((user: User) => user.status === 'declined');
      }
    }

    if (dropDownSelection?.value) {
      const [dropDownFilter, dropdownValue] = dropDownSelection.value?.split(':');
      if (dropDownFilter === 'hiredBy') {
        fUsers = fUsers.filter(
          (user: User) => (user.hiredBy?.toLowerCase() ?? 'epm') === dropdownValue
        );
      } else if (dropDownFilter === 'vaccine') {
        const isVaccinated = dropdownValue === 'vaccinated';
        fUsers = fUsers.filter((user: User) => (isVaccinated ? !!user.vaccine : !user.vaccine));
      }
    }

    if (search) {
      fUsers = fUsers.filter(
        ({ fName, lName, pName, email, employeeNumber, appMethod, internalRoles, phone }: User) =>
          [fName, lName, pName, email, employeeNumber, appMethod, internalRoles, phone].some(
            (name) => name?.includes(search)
          )
      );
    }

    if (location && coords) {
      fUsers = fUsers.filter(
        ({ lat, lng }: User) =>
          lat > coords.minLat && lat < coords.maxLat && lng > coords.minLng && lng < coords.maxLng
      );
    }

    if (clientId) {
      fUsers = fUsers.filter(({ forbiddenVenues }: User) => !forbiddenVenues?.includes(clientId));
    }

    setFilteredUsersArray(fUsers);
  }, [allUsers, filter, dropDownSelection, search, location, coords, clientId]);

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(filterUsers, 500);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [filterUsers]);

  const filteredUsers = useMemo(() => {
    if (filter === 'new') {
      return filteredUsersArray
        .filter(Boolean)
        .sort(
          (a: User, b: User) => new Date(b.registerAt).getTime() - new Date(a.registerAt).getTime()
        )
        .map(UserCell);
    }
    return filteredUsersArray
      .filter(Boolean)
      .sort((a: User, b: User) => (a.fName || '').localeCompare(b.fName || ''))
      .map(UserCell);
  }, [filter, filteredUsersArray, UserCell]);

  const googleMap = useMemo(
    () =>
      !!location?.name && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_API_KEY as string,
          }}
          center={{ lat: location?.lat, lng: location?.lng }}
          zoom={14}
          onChange={onRadiusChange}
        >
          {filteredUsersArray.map(
            // @ts-ignore
            ({ lat, lng, image, id, status }) => {
              return (
                <Marker
                  lat={lat}
                  lng={lng}
                  image={image}
                  id={id}
                  key={id}
                  status={status}
                  onUserClick={onUserClick}
                />
              );
            }
          )}
          {<AnyReactComponent lat={location?.lat} lng={location?.lng} />}
        </GoogleMapReact>
      ),
    [filteredUsersArray, location.lat, location.lng, onUserClick, location?.name]
  );

  const onSelectFilter = useCallback((selection) => {
    setDropDownSelection(selection);
  }, []);

  return userView ? (
    <Profile staff={userView} action={onUserModalClose} />
  ) : (
    <>
      <div className='page_heading'>STAFF</div>
      <div className='profile_divider' />
      <div className='staff_settings'>
        <div className='staff_settings-buttons_wrapper'>
          <div onClick={onAllUsers} className={'all_users' + (!filter ? ' activeFilter' : '')}>
            ALL
          </div>
          <div
            onClick={onNewUsers}
            className={'new_users' + (filter === 'new' ? ' activeFilter' : '')}
          >
            NEW
          </div>
          <div
            onClick={onPending}
            className={'pending_users' + (filter === 'pending' ? ' activeFilter' : '')}
          >
            PENDING
          </div>
          <div
            onClick={onActive}
            className={'active_users' + (filter === 'active' ? ' activeFilter' : '')}
          >
            ACTIVE
          </div>
          <div
            onClick={onDecline}
            className={'declined_users' + (filter === 'declined' ? ' activeFilter' : '')}
          >
            DECLINED
          </div>
        </div>
        <div className='staff_settings-search'>
          <input
            placeholder='SEARCH STAFF'
            type='search'
            name='search'
            onChange={onSearch}
            value={search}
            aria-label='search'
          />
          <Autocomplete
            id='address_search'
            placeholder='SEARCH ADDRESS'
            type='search'
            name='searchLocation'
            value={location?.name || ''}
            setAddress={onLocation}
          />
          <Select
            onChange={onSelectFilter}
            value={dropDownSelection}
            placeholder='DROP DOWN MENU'
            options={filterSelection}
            aria-label='DROP DOWN MENU'
            styles={customStyles}
            isClearable={true}
          />
        </div>
      </div>
      {!!location?.name && <div style={{ height: '400px', marginBottom: 16 }}>{googleMap}</div>}
      <div className='profile_divider' />
      <div className='users'>{filteredUsers}</div>
      {!!email ? <Msg action={() => setEmail('')} email={{ to: email }} /> : null}
    </>
  );
};

// @ts-ignore
const mapStateToProps = (state) => {
  return {
    users: state.app.users,
    user: state.app.user,
  };
};
const mapDispatchToProps = (dispatch: (dispatch: any) => void) => {
  return {
    fetchAllUsers: (search: string, notSave: boolean) =>
      dispatch(appAction.fetchAllUsers(search, notSave)),
    fetchUser: (id: string) => dispatch(appAction.fetchUser(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
