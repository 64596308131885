import React, { memo, useCallback, useMemo } from 'react';
import { uxDate, getTime, shortDate } from '../../util/dates';
import { formatPhone } from '../../util/numbers';
import userEmpty from '../../images/userEmpty.png';

const getBorder = (shift, workflow?) => {
  if (workflow) {
    if (workflow.declinedAt) return { border: '3px rgb(242, 98, 39) solid' };
    return { border: '3px rgb(253, 182, 57) solid' };
  } else if (shift.status === 'confirmed') return { border: '3px rgb(98, 121, 149) solid' };
  else if (shift.status === 'declined') return { border: '3px rgb(242, 98, 39) solid' };
  else if (shift.status === 'booked') return { border: '3px rgb(253, 182, 57) solid' };
};

const getStyle = (shift, isWorkflow = false) => {
  let style: any = {
    // height: "100%",
    minHeight: '160px',
    cursor: 'pointer',
  };

  const staffImage = shift?.staffImage ?? userEmpty;

  // shift.status = shift.status || 'open'
  if (isWorkflow) {
    style.backgroundImage = `url("${staffImage}")`;
    style.backgroundRepeat = 'no-repeat';
    style.backgroundSize = '100%';
  } else if (shift.status.match('booked|confirmed')) {
    style.backgroundImage = `url("${staffImage}")`;
    style.backgroundRepeat = 'no-repeat';
    style.backgroundSize = '100%';
  } else if (shift.status === 'declined') {
    style.backgroundImage = `url("${staffImage}")`;
    style.backgroundRepeat = 'no-repeat';
    style.backgroundSize = '100%';
  } else {
    style.backgroundColor = '#cccccc';
    style.height = '100%';
  }
  return style;
};

const isForApproval = (workflow) => {
  if (workflow.declinedAt) return true;
  if (workflow.rejectedAt) return true;
  if (workflow.approvedAt) return false;
  if (workflow.confirmedAt) return false;
  return true;
};

const isForReject = (workflow) => {
  if (workflow.declinedAt) return false;
  if (workflow.rejectedAt) return false;
  return true;
};

export const ShiftUserCard = memo((props: any) => {
  const {
    shift = {},
    count,
    openShifts,
    onRejectCallback,
    onUserClickCallback,
    onConfirmCallback,
    user = {},
    onEmailClickCallback,
    onEditShiftCallback,
    client = {},
  } = props;

  const staffEmail = user.email ?? shift.staffEmail;

  const staffName = useMemo(() => {
    if (user.fName || user.lName) {
      return `${user.fName ?? ''} ${user.lName ?? ''}`?.trim();
    }
    return shift.staffName;
  }, [user, shift]);

  const staffImage = user.image ?? shift.staffImage;

  const staffPhone = user?.phone ?? shift?.staffPhone;

  const onReject = useCallback(() => {
    onRejectCallback({ id: shift.workflowId }, true);
  }, [onRejectCallback, shift.workflowId]);

  const onConfirm = useCallback(() => {
    onConfirmCallback({ id: shift.workflowId }, true);
  }, [onConfirmCallback, shift.workflowId]);

  const onUserClick = useCallback(
    () => onUserClickCallback(staffEmail),
    [onUserClickCallback, staffEmail]
  );

  const onEmailClick = useCallback(
    () =>
      onEmailClickCallback({
        to: staffEmail,
        subject: `${client?.name ?? ''} @ ${uxDate(shift.start)} - Role: ${shift.role}`,
      }),
    [onEmailClickCallback, staffEmail, client, shift.start, shift.role]
  );

  const onEditShiftClick = useCallback(
    () => onEditShiftCallback(shift),
    [shift, onEditShiftCallback]
  );

  return (
    <div key={shift.id} style={getBorder(shift)}>
      <div style={getStyle({ staffImage, status: shift.status })} onClick={onEditShiftClick}>
        {shift.status === 'open' && (
          <div style={{ paddingTop: '30px', textAlign: 'center' }}>
            <div>{shift.role}</div>
            <div>${shift.rate}/hr</div>
            <div>{shortDate(shift.date)}</div>
            <div>
              {getTime(shift.start)} - {getTime(shift.end)}
            </div>
            <br />
            <strong>
              Staff {count} of {openShifts}
            </strong>
          </div>
        )}
      </div>
      {shift.status !== 'open' ? (
        <div style={{ fontSize: '11px', textAlign: 'center' }}>
          <div
            className='emailProfile'
            onClick={onUserClick}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-delay={0}
            title={staffName}
          >
            {staffName}
          </div>
          <div>{user?.employeeNumber ?? formatPhone(staffPhone)}</div>
          <div className='emailProfile' onClick={onEmailClick}>
            {staffEmail}
          </div>

          <div
            style={{
              justifyContent: shift?.status !== 'confirmed' ? 'space-between' : 'center',
              display: 'flex',
            }}
          >
            {shift?.status !== 'confirmed' && shift?.userId ? (
              <div
                className='actionButton'
                style={{ background: 'rgb(117, 159, 160)' }}
                onClick={onConfirm}
              >
                Confirm
              </div>
            ) : null}
            <div onClick={onReject} className='actionButton'>
              Decline
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export const WorkflowUserCard = memo((props: any) => {
  const {
    workflow = {},
    user = {},
    onWorkflowClickCallback,
    onUserClickCallback,
    onEmailClickCallback,
    onApproveCallback,
    onRejectCallback,
    isAvailable,
  } = props;

  const staffEmail = user.email ?? workflow.staffEmail;

  const staffName = useMemo(() => {
    if (user.fName || user.lName) {
      return `${user.fName ?? ''} ${user.lName ?? ''}`?.trim();
    }
    return workflow.staffName;
  }, [user, workflow]);

  const staffImage = user.image ?? workflow.staffImage;

  const onWorkflowClick = useCallback(
    () => onWorkflowClickCallback(workflow),
    [onWorkflowClickCallback, workflow]
  );

  const onUserClick = useCallback(
    () => onUserClickCallback(staffEmail),
    [onUserClickCallback, staffEmail]
  );

  const onEmailClick = useCallback(
    () =>
      onEmailClickCallback({
        to: staffEmail,
      }),
    [onEmailClickCallback, staffEmail]
  );

  const onApprove = useCallback(
    (e) => {
      onApproveCallback(workflow, e);
    },
    [onApproveCallback, workflow]
  );

  const onReject = useCallback(() => {
    onRejectCallback(workflow);
  }, [onRejectCallback, workflow]);

  return (
    <div key={workflow.id} style={{ ...getBorder(null, workflow), textAlign: 'center' }}>
      <div onClick={onWorkflowClick} style={getStyle({ staffImage }, true)} />

      <div style={{ fontSize: '11px' }}>
        <div className='emailProfile' onClick={onUserClick}>
          {staffName}
        </div>
        <div className='emailProfile' onClick={onEmailClick}>
          {staffEmail}
        </div>
      </div>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        {isForApproval(workflow) && isAvailable ? (
          <div
            className='actionButton'
            style={{ background: 'rgb(117, 159, 160)' }}
            onClick={onApprove}
          >
            Approve
          </div>
        ) : null}
        {isForReject(workflow) ? (
          <div className='actionButton' onClick={onReject}>
            Decline
          </div>
        ) : null}
      </div>
    </div>
  );
});

interface EventInfoProps {
  shifts: any;
  event: any;
  clients: any;
}

export const EventInfo = memo((props: EventInfoProps) => {
  const { shifts, event, clients } = props;
  const { clientId, start, end, role, rate } = shifts[0] ?? {};

  const eventId = event?.meetingId ? ('00000' + event?.meetingId).slice(-6) : 0;

  return (
    <div style={{ minWidth: 330 }}>
      <div className='page_heading' style={{ marginBottom: '8px' }}>
        EVENT
      </div>
      {clients[clientId]?.name && (
        <>
          {event?.meetingId && <div>Event ID: {eventId}</div>}
          <div>{clients[clientId]?.name}</div>
          <div>{shortDate(start)}</div>
          <div>
            {getTime(start)} - {getTime(end)}
          </div>
          <div>{role}</div>
          <div>${rate}/hr</div>
        </>
      )}
    </div>
  );
});
