import React, { useCallback, useMemo } from 'react';
import { isManager } from 'Utils/auth';
import { uxDate } from 'Utils/dates';
import { convertLinksToAnchor } from 'Utils/strings';

const date = new Date();
date.setDate(date.getDate() - 7);

const Memo = (props) => {
  const { deleteMemo, m: memo } = props;

  const canDelete = useMemo(() => typeof deleteMemo === 'function', [deleteMemo]);

  const onMemoDelete = useCallback(() => deleteMemo(memo.id), [memo, deleteMemo]);

  return (
    <div
      key={props.m.id}
      className={`memos${new Date(props.m.createdAt) > date ? ' memos__new' : ''}`}
    >
      <div className='memos__title'>
        <span>{props.m.title} </span>
        {canDelete && (
          <button
            type='button'
            className='close closeButton closeButton_card'
            onClick={onMemoDelete}
          >
            <span aria-hidden='true'>×</span>
            <span className='sr-only'>Close</span>
          </button>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: convertLinksToAnchor(props.m.memo, 'CLICK FOR QUIZ'),
        }}
        style={{ whiteSpace: 'break-spaces' }}
      />
      <span className='memos__date'>
        Posted: {uxDate(props.m.createdAt)} {props.m.createdBy ? `by: ${props.m.createdBy}` : null}
      </span>
      <div className='memos__date'>
        {isManager(props.user.role) && props.m.by ? `Posted by: ${props.m.by}` : null}
      </div>
    </div>
  );
};
export default Memo;
