import React from 'react';
import { v1 as uuid } from 'uuid';

export const Uploader = (props) => {
  let id = uuid();
  return (
    <span style={{ alignSelf: 'center' }}>
      <input type='file' id={id} onChange={props.onFileUpload} name={props.name} />
      <label htmlFor={id} className='actionButton' style={{ width: '150px' }}>
        {props.label ?? 'Upload File'}
      </label>
    </span>
  );
};
