import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import { Modal } from 'react-bootstrap';
import './Login.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { LogoWB } from '../Logo';
import toast from '../Toast/Toast';
import eyeVisible from '../../images/eye_visible.svg';
import eyeHidden from '../../images/eye_hidden.svg';

class Login extends Component {
  state = {
    forget: false,
    email: '',
    isCaptcha: false,
    isPasswordHidden: true,
  };

  onLogin = async () => {
    if (!this.email.value || !this.password.value || !this.state.isCaptcha) {
      toast('please fill all fields');
      return;
    }

    try {
      await this.props.login(this.email.value.toUpperCase(), this.password.value);
      this.props.history.push('/');
    } catch (error) {
      toast("email or password don't match, please try again");
      console.error(error);
    }
  };

  onKeyPress = (event) => {
    if (event.key === 'Enter') this.onLogin();
  };

  onRegister = () => {
    this.props.history.push('/register');
  };

  onForget = () => {
    this.setState({ forget: true });
  };

  onPasswordForget = () => {
    let email = document.querySelector('input[name="emailForget"]');
    if (!(email && email.value.length > 0 && email.validity.valid)) {
      toast('email is not valid');
      return;
    }
    this.props.onPasswordForget(email.value.toUpperCase());
    toast('check your email to reset');
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ forget: false });
  };

  onCaptchaChange = (value) => this.setState({ isCaptcha: !!value });

  onShowHide = () => this.setState({ isPasswordHidden: !this.state.isPasswordHidden });

  render() {
    const { isPasswordHidden } = this.state;
    return (
      <React.Fragment>
        <div className='login__form'>
          <div className='login'>
            <LogoWB />
            <label htmlFor='login_email' className='bold'>
              USERNAME:
            </label>
            <input
              id='login_email'
              type='email'
              placeholder='enter email address'
              ref={(e) => (this.email = e)}
              onKeyPress={this.onKeyPress}
            />

            <label htmlFor='login_password' className='bold'>
              PASSWORD:
            </label>
            <div>
              <input
                id='login_password'
                type={isPasswordHidden ? 'password' : 'text'}
                placeholder='enter password'
                ref={(e) => (this.password = e)}
                onKeyPress={this.onKeyPress}
              />
              <img
                src={isPasswordHidden ? eyeVisible : eyeHidden}
                className={'showHidePassword'}
                alt='show or hide password switch'
                onClick={this.onShowHide}
              />
            </div>

            <ReCAPTCHA
              sitekey='6Le65LkUAAAAAFAM7JS0AEDJeiLS4KAve11_EkO4'
              onChange={this.onCaptchaChange}
              style={{ height: '110px' }}
            />
            <div style={{ textAlign: 'center', margin: '0' }}>
              <span className='login__action' onClick={this.onLogin}>
                log in
              </span>
            </div>

            <div className='whiteLine' />
            <div>
              <span>Don’t have an account?</span>
              <span className='login__action' onClick={this.onRegister} style={{ float: 'right' }}>
                sign up
              </span>
            </div>
            <div>
              <span>Trouble logging in?</span>
              <span className='login__action' onClick={this.onForget} style={{ float: 'right' }}>
                reset
              </span>
            </div>
          </div>
        </div>
        <Modal show={this.state.forget} onHide={this.closeModal}>
          <div className='login' style={{ textAlign: 'center' }}>
            <LogoWB />
            <div className='bold'>Trouble loggin in?</div>
            <label htmlFor='forget_email'>Verify your email address to reset your account.</label>
            <input
              id='forget_email'
              type='email'
              placeholder='enter email address'
              name='emailForget'
            />
            <div style={{ textAlign: 'right' }}>
              <span className='login__action' onClick={this.onPasswordForget}>
                reset
              </span>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(appAction.login(email, password)),
    onPasswordForget: (email) => dispatch(appAction.onPasswordForget(email)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
