import { AP_ROLE } from '../constants/roles';

export const isManager = (role = '') => role === AP_ROLE.MANAGER || role === AP_ROLE.COORDINATOR;

export const isAdmin = (role = '') => role === AP_ROLE.MANAGER;

export const isStaff = (role = '') => role === AP_ROLE.STAFF;

export const isSub = (role = '', hiredBy = '') => role === AP_ROLE.SUB || hiredBy !== 'EPM';

export const isLead = (role = '') => role === AP_ROLE.LEAD;
