import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import * as appAction from 'Actions/app.actions';
import { AgGridReact } from 'ag-grid-react';
import { uxDate, getTime } from 'Utils/dates';
import { isSub } from '../../util/auth';
import { calcHrs } from '../../util/numbers';
import DatePicker from 'react-date-picker';

const defaultColDef = {
  sortable: true,
  resizable: true,
  width: 120,
  sortingOrder: ['asc', 'desc'],
};

const firstJanuary2023 = new Date(1672549200000);


const LogSheet = (props) => {
  const { fetchLogSheet, user } = props;

  const [shifts, setShifts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    fetchLogSheet({ startDate, endDate }).then(setShifts);
  }, [fetchLogSheet, startDate, endDate]);

  const isUserSub = useMemo(() => {
    return isSub(user?.role, user?.hiredBy);
  }, [user]);

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Date',
        field: 'start',
        valueFormatter: (props) => uxDate(props.value),
        sort: 'asc',
      },
      {
        field: 'venue',
        headerName: 'Venue',
      },
      {
        field: 'role',
        headerName: 'Role',
      },
      {
        headerName: 'In',
        field: 'start',
        valueFormatter: (props) => getTime(props.value),
      },
      {
        headerName: 'Out',
        field: 'end',
        valueFormatter: (props) => getTime(props.value),
      },
      {
        field: 'break',
        headerName: 'Break',
      },
      {
        field: 'hrs',
        headerName: 'Hrs',
        valueGetter: ({ data }) => calcHrs(data.start, data.end, data.break) || 0,
      },
      !isUserSub && {
        field: 'rate',
        headerName: 'Rate',
      },
      !isUserSub && {
        field: 'amount',
        headerName: 'Amount',
        valueGetter: ({ data }) => {
          const { rate = 0, tips, deduction, start, end, break: breakData } = data;
          const hrs = calcHrs(start, end, breakData) ?? 0;
          const amount = rate * hrs + (tips ?? 0) - (deduction ?? 0);
          return amount;
        },
      },
    ].filter(Boolean);
  }, [isUserSub]);

  const onGridReady = useCallback(({ columnApi, api }) => {
    api.sizeColumnsToFit();
  }, []);

  return (
    <>
      <div className='label'>Log Sheet</div>
      <span>From: </span>
      <DatePicker
        onChange={(date) => {
          if (!date) return;
          const _date = new Date(date?.[0] ?? date);
          setStartDate(_date ?? new Date());
        }}
        value={startDate}
        view='month'
        calendarIcon={null}
        clearIcon={null}
        minDate={firstJanuary2023}
      />
      <span>To: </span>
      <DatePicker
        onChange={(date) => {
          if (!date) return;
          const _date = new Date(date?.[0] ?? date);
          setEndDate(_date ?? new Date());
        }}
        value={endDate}
        view='month'
        calendarIcon={null}
        clearIcon={null}
      />
      <div className='ag-theme-balham'>
        <AgGridReact
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowData={shifts}
          onGridReady={onGridReady}
          domLayout='autoHeight'
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLogSheet: (range) => dispatch(appAction.fetchLogSheet(range)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogSheet);
